import React, { Component } from 'react';
import './App.scss';
import { DesktopLayout } from './components/DesktopLayout';

export default class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        if (!localStorage.getItem('session_id') || localStorage.getItem('session_id') === 'null') {
            localStorage.clear();
        }
    }

    render() {
        return <DesktopLayout />;
    }
}
