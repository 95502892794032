export const initial_signature = {
    App_id: 0,
    File_Bit_Array: '',
    Date_Create: '',
    File_Name: '',
    Signature_Type: '',
};

export const initial_application = {
    Country: '',
    Date_Reviewed: '',
    Date_Started: '',
    Email: '',
    Full_Name: '',
    Hired: 0,
    Hired_By: '',
    Id: 0,
    New_Password: '',
    Password: '',
    Reviewed_By: '',
    Status_id: '',
    Date_Submitted: '',
    Application_Open: '',
    Position: '',
    First_Name: '',
    Middle_Name: '',
    Last_Name: '',
    Employment_Completed: 'N',
    Has_Accidents: '',
    Has_Violations: '',
    Cell_Phone: '',
};
export const initial_session = {
    App_id: '',
    Country: '',
    Date_Reviewed: '',
    Date_Started: '',
    Email: '',
    Full_Name: '',
    Hired: 0,
    Hired_By: '',
    Id: '',
    Reviewed_By: '',
    Status_id: '',
    Date_Submitted: '',
};

export const initial_mailgun_user = {
    Name: '',
    Email: '',
    Issue: '',
    Country: '',
};

export const initial_general = {
    App_id: 0,
    Position_Applying_For: '',
    License_Copy_id: '',
    License_Copy_id_Back: '',
    Border_Crossing_id: '',
    Referred_Employee: '',
    Driver_Abstract: '',
    CVOR: '',
    Hear_About: '',
    Other_Details: '',
    License_Number: '',
    License_State: '',
    Id: 0,
};

export const initial_truck = {
    App_id: 0,
    Make: '',
    Model: '',
    Year: '',
    Transmission_Capability: '',
    Daycab_Bunk: '',
    Front_Image_id: '',
    Back_Image_id: '',
    Left_Image_id: '',
    Right_Image_id: '',
    Id: 0,
};

export const initial_accident = {
    App_id: 0,
    Has_Accidents: '',
    Accident_Date: '',
    Fatalities: '',
    Preventable: '',
    Injuries: '',
    Id: 0,
};

export const initial_personal = (app_id, full_name) => {
    return {
        App_id: app_id,
        Street_Address: '',
        City: '',
        Postal_Code: '',
        Primary_Phone: '',
        Cell_Phone: '',
        US_Cell_Phone: '',
        Date_of_Birth: '',
        SSN: '',
        Full_Name: full_name,
        Province: '',
        Marital_Status: '',
        Id: 0,
    };
};

export const initial_emergency = {
    App_id: 0,
    Full_Name: '',
    Phone: '',
    Relationship: '',
    Id: 0,
};

export const initial_education = {
    App_id: 0,
    Highest_Grade_Completed: '',
    High_School_Name: '',
    College_Name: '',
    Last_School_Attended: '',
    Id: 0,
};

export const initial_physical = {
    App_id: 0,
    Handicaps: '',
    Manual_Work: '',
    Manual_Work_Reason: '',
    Injured_On_Job: '',
    Injured_Reason: '',
    Time_Lost: '',
    Other_Issues: '',
    Id: 0,
};

export const initial_employer = (app_id) => {
    return {
        App_id: app_id,
        Employer_Name: '',
        Employer_Address: '',
        Contact_Name: '',
        Phone: '',
        Position: '',
        Hire_Date: '',
        Terminate_Date: '',
        Reason_For_Leaving: '',
        Current_Employer: '',
        Id: 0,
    };
};

export const initial_gap = (app_id) => {
    return {
        App_id: app_id,
        Start_Date: '',
        End_Date: '',
        Reason: '',
        Id: 0,
    };
};

export const initial_license = {
    App_id: 0,
    Number: '',
    Expiration: '',
    License_Type: '',
    Denied_License: '',
    Suspended: '',
    Reason_For_Yes: '',
    Province: '',
    Id: 0,
};

export const initial_experience = {
    App_id: 0,
    Cross_US_CA: '',
    Reefer: '',
    Months_Years_Reefer: '',
    Other_Equipment: '',
    States_Provs: '',
    Auto_Manual: '',
    Regions: [],
    Id: 0,
};

export const initial_violation = {
    App_id: 0,
    Has_Violations: '',
    Violation_Date: '',
    Location: '',
    Charge: '',
    Penalty: '',
    Id: 0,
};

export const initial_drug_screening = {
    App_id: 0,
    Failed_Drug_Test: '',
    Signed: 'N',
    Signed_Date_Time: '',
    Recruiter_Signed_Date: '',
    Recruiter_Name: '',
    Id: 0,
};

export const initial_agreement = {
    App_id: 0,
    Signed_Date_Time: '',
    Signed: 'N',
    IP_Address: '',
    Id: 0,
};

export const initial_reference = {
    App_id: 0,
    Signed_Date_Time: '',
    Signed: 'N',
    IP_Address: '',
    Id: 0,
};

export const initial_fair_credit = {
    App_id: 0,
    Checkbox_1: '',
    Checkbox_2: '',
    Checkbox_3: '',
    Checkbox_4: '',
    Checkbox_5: '',
    Signed_Date_Time: '',
    Signed: 'N',
    Id: 0,
};

export const initial_driver_check = {
    App_id: 0,
    Previous_Company: '',
    Previous_Phone: '',
    Previous_Fax: '',
    Previous_Address: '',
    Previous_Contact: '',
    Signed_Date_Time: '',
    Signed: 'N',
    Exempted: 'N',
    Id: 0,
};

export const initial_background = {
    App_id: 0,
    Signed_Date_Time: '',
    Signed: 'N',
    IP_Address: '',
    Id: 0,
};

export const initial_status = {
    App_id: 0,
    Email: '',
    Status_id: 0,
    View_Page: '',
};

export const initial_consent = {
    App_id: 0,
    Gave_Consent: '',
    Consent_Date: '',
    IP_Address: '',
    Full_Name: '',
};

export const initial_recruiter = {
    Id: 0,
    First_Name: '',
    Last_Name: '',
    Country: '',
    Email: '',
    Phone: '',
    Is_Active: '',
};
