import { FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import '../.././App.scss';

/**
 * TextareaInput
 * params {label:string, controlid:string,maxLength:number, data:any, field_name:string, handleInputChange:function, disabled:(Y/N), height:string, bgColor:string }
 * returns Button
 */

function TextareaInput({ ...props }) {
    return (
        <InputGroup className="mb-2 opacity70">
            <FloatingLabel controlId={props.controlid} label={props.label}>
                <Form.Control
                    controlId={props.controlid}
                    variant="outline-secondary"
                    class="form-control"
                    maxLength={props.maxLength}
                    value={props.data[props.field_name]}
                    name={props.field_name}
                    onChange={props.handleInputChange}
                    onKeyPress={props.handleKeyPress}
                    placeholder="DBA/CO"
                    readOnly={props.disabled === 'Y'}
                    disabled={props.disabled === 'Y'}
                    as="textarea"
                    className="textarea"
                    style={{ backgroundColor: props.bgColor }}
                />
            </FloatingLabel>
        </InputGroup>
    );
}

export default TextareaInput;
