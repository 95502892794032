import React, { Component } from 'react';
import { Accordion, Button, FloatingLabel, Form, InputGroup, Stack } from 'react-bootstrap';
import { sendDeleteFetch, sendPostFetch, sendPutFetch } from '../application/FetchFunctions';
import { initial_violation } from '../dtos/InitialValues';

export class TrafficViolations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_traffic: initial_violation,
            edit_traffic: initial_violation,
            new_validated: false,
            edit_validated: false,
            has_traffic_violations: this.props.current_application ? this.props.current_application.Has_Violations : '',
        };

        this.handleNewViolation = this.handleNewViolation.bind(this);
        this.handleEditViolation = this.handleEditViolation.bind(this);
        this.addViolation = this.addViolation.bind(this);
        this.editViolation = this.editViolation.bind(this);
        this.validateNewViolation = this.validateNewViolation.bind(this);
        this.validationEditViolation = this.validationEditViolation.bind(this);
        this.deleteViolation = this.deleteViolation.bind(this);
    }

    //#region New Violation
    handleNewViolation(e) {
        const current_info = this.state.new_traffic;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
        }
        current_info[e.target.name] = e.target.value;
        this.setState({ new_traffic: current_info });
        this.validateNewViolation();
    }

    validateNewViolation() {
        const c_violation = this.state.new_traffic;
        let is_valid = false;

        if (c_violation.Violation_Date !== '') {
            if (c_violation.Location !== '') {
                if (c_violation.Charge !== '') {
                    if (c_violation.Penalty !== '') {
                        is_valid = true;
                    } else {
                        is_valid = false;
                    }
                } else {
                    is_valid = false;
                }
            } else {
                is_valid = false;
            }
        } else {
            is_valid = false;
        }

        this.setState({ new_validated: is_valid });
    }

    async addViolation() {
        this.props.setMultiButton('add violation');
        await sendPostFetch(JSON.stringify(this.state.new_traffic), 'trafficviolations').then((res) => {
            if (res === 'open issue') {
                this.props.setIssue('Could not add Traffic Violation');
            } else {
                const violations_list = this.props.traffic_violations;
                violations_list.push(res);
                this.props.updateViolations(violations_list);
                this.props.setMultiButton('');
                this.props.onStatusUpdate();
                this.updateHasViolations();
                this.setState({
                    new_traffic: {
                        App_id: 0,
                        Has_Violations: '',
                        Violation_Date: '',
                        Location: '',
                        Charge: '',
                        Penalty: '',
                        Id: 0,
                    },
                    new_validated: false,
                });
            }
        });
    }
    //#endregion New Violation

    //#region Edit Violation
    handleEditViolation(e) {
        const current_info = this.state.edit_traffic;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
        }
        current_info[e.target.name] = e.target.value;
        this.setState({ edit_traffic: current_info });
        this.validationEditViolation();
    }

    validationEditViolation() {
        const c_violation = this.state.edit_traffic;
        let is_valid = false;

        if (c_violation.Violation_Date !== '') {
            if (c_violation.Location !== '') {
                if (c_violation.Charge !== '') {
                    if (c_violation.Penalty !== '') {
                        is_valid = true;
                    } else {
                        is_valid = false;
                    }
                } else {
                    is_valid = false;
                }
            } else {
                is_valid = false;
            }
        } else {
            is_valid = false;
        }

        this.setState({ edit_validated: is_valid });
    }

    async editViolation() {
        this.props.setMultiButton('edit violation');
        await sendPutFetch(JSON.stringify(this.state.edit_traffic), 'trafficviolations').then((res) => {
            if (res === 'open issue') {
                this.props.setIssue('Could not update Traffic Violation');
            } else {
                const violations_list = this.props.traffic_violations.filter(
                    (s) => s.Id !== this.state.edit_traffic.Id,
                );
                violations_list.push(this.state.edit_traffic);
                this.props.updateViolations(violations_list);
                this.props.setMultiButton('');
                this.setState({
                    edit_traffic: {
                        App_id: 0,
                        Has_Violations: '',
                        Violation_Date: '',
                        Location: '',
                        Charge: '',
                        Penalty: '',
                        Id: 0,
                    },
                    edit_validated: false,
                });
            }
        });
    }
    //#endregion Edit Violation

    async deleteViolation(d) {
        this.props.setMultiButton('delete violation');
        if (window.confirm('Are you sure you want to delete this Traffic Violation?')) {
            await sendDeleteFetch(JSON.stringify(d), 'trafficviolations').then((res) => {
                const violations_list = this.props.traffic_violations.filter((s) => s.Id !== d.Id);
                this.props.updateViolations(violations_list);
                this.props.setMultiButton('');
            });
        } else {
            this.props.setMultiButton('');
        }
    }

    async updateHasViolations() {
        const current_app = this.props.current_application;
        current_app.Has_Violations = this.state.has_traffic_violations;
        const putResponse = await fetch('driverapp/UpdateViolations', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(current_app),
        });
        const data = await putResponse.json();
        console.log(data);
        this.props.update_application(current_app);
    }

    async setHasViolations(dir) {
        await this.updateHasViolations();
        if (dir === 'back') {
            this.props.onBack();
        } else {
            this.props.onNext();
        }
    }

    render() {
        const { new_traffic, edit_traffic, new_validated, edit_validated, has_traffic_violations } = this.state;

        //#region Violations Accordion
        const violations_accordion = (
            <div className="d-block mb-5">
                <Accordion defaultActiveKey="0">
                    {this.props.traffic_violations
                        .sort((a, b) => new Date(a.Violation_Date) - new Date(b.Violation_Date))
                        .map((d, index) => (
                            <Accordion.Item eventKey={d.Id} key={d.Id}>
                                <Accordion.Header as="h6" className="text-color">
                                    {'Violation Date: ' + d.Violation_Date}
                                </Accordion.Header>
                                <Accordion.Body className="d-flex flex-column text-color" style={{ textAlign: 'left' }}>
                                    <label className="p-2">
                                        <strong>Location:</strong>
                                        <bdi className="ms-2">{d.Location}</bdi>
                                    </label>
                                    <label className="p-2">
                                        <strong>Charge:</strong>
                                        <bdi className="ms-2">{d.Charge}</bdi>
                                    </label>
                                    <label className="p-2">
                                        <strong>Penalty:</strong>
                                        <bdi className="ms-2">{d.Penalty}</bdi>
                                    </label>
                                    <div className="d-flex flex-row">
                                        <Button
                                            variant={
                                                this.props.multi_button === 'delete violation' ? 'light' : 'danger'
                                            }
                                            style={{ width: '80px' }}
                                            onClick={() => this.deleteViolation(d)}
                                            className="btn-op70"
                                            size="sm"
                                            disabled={this.props.multi_button === 'delete violation'}
                                        >
                                            {this.props.multi_button === 'delete violation' ? 'Deleting...' : 'Delete'}
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            className="btn-op70 ms-1"
                                            style={{ width: '80px' }}
                                            onClick={() => this.setState({ edit_traffic: d })}
                                            size="sm"
                                        >
                                            Edit
                                        </Button>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                </Accordion>
            </div>
        );
        //#endregion Violations Accordion

        //#region New Violation Form
        const new_violation_form = (
            <div className="d-block">
                <h6>Add Traffic Violation</h6>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="violation-date" label="Violation Date">
                        <Form.Control
                            type="date"
                            variant="outline-secondary"
                            value={new_traffic.Violation_Date}
                            name="Violation_Date"
                            onChange={this.handleNewViolation}
                            isValid={new_traffic.Violation_Date !== ''}
                            isInvalid={new_traffic.Violation_Date === ''}
                            controlId="violation-date"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="violation-location" label="Violation Location">
                        <Form.Select
                            type="text"
                            variant="outline-secondary"
                            value={new_traffic.Location}
                            name="Location"
                            onChange={this.handleNewViolation}
                            isValid={new_traffic.Location !== ''}
                            isInvalid={new_traffic.Location === ''}
                            controlId="violation-location"
                        >
                            <option value="">Select State/Province</option>
                            {this.props.traffic_states.map((d, index) => (
                                <option value={d.Pst_id} key={index}>
                                    {d.Pst_Name}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="violation-charge" label="Charge">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={new_traffic.Charge}
                            name="Charge"
                            onChange={this.handleNewViolation}
                            isValid={new_traffic.Charge !== ''}
                            isInvalid={new_traffic.Charge === ''}
                            controlId="violation-charge"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="violation-penalty" label="Penalty">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={new_traffic.Penalty}
                            name="Penalty"
                            onChange={this.handleNewViolation}
                            isValid={new_traffic.Penalty !== ''}
                            isInvalid={new_traffic.Penalty === ''}
                            controlId="violation-penalty"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>

                <Button
                    variant={this.props.multi_button === 'add violation' ? 'light' : 'success'}
                    onClick={() => this.addViolation()}
                    className="btn-op70 submit-buttons mt-2"
                    disabled={this.props.multi_button === 'add violation' || !new_validated}
                >
                    {this.props.multi_button === 'add violation' ? 'Adding...' : 'Add'}
                </Button>
            </div>
        );
        //#endregion New Violation Form

        //#region Edit Violation Form
        const edit_violation_form = (
            <div className="d-block">
                <h6>Edit Traffic Violation</h6>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="violation-date" label="Violation Date">
                        <Form.Control
                            type="date"
                            variant="outline-secondary"
                            value={edit_traffic.Violation_Date}
                            name="Violation_Date"
                            onChange={this.handlEditViolation}
                            isValid={edit_traffic.Violation_Date !== ''}
                            isInvalid={edit_traffic.Violation_Date === ''}
                            controlId="violation-date"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="violation-location" label="Violation Location">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={edit_traffic.Location}
                            name="Location"
                            onChange={this.handleEditViolation}
                            isValid={edit_traffic.Location !== ''}
                            isInvalid={edit_traffic.Location === ''}
                            controlId="violation-location"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="violation-charge" label="Charge">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={edit_traffic.Charge}
                            name="Charge"
                            onChange={this.handleEditViolation}
                            isValid={edit_traffic.Charge !== ''}
                            isInvalid={edit_traffic.Charge === ''}
                            controlId="violation-charge"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="violation-penalty" label="Penalty">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={edit_traffic.Penalty}
                            name="Penalty"
                            onChange={this.handleEditViolation}
                            isValid={edit_traffic.Penalty !== ''}
                            isInvalid={edit_traffic.Penalty === ''}
                            controlId="violation-penalty"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 d-flex flex-row" style={{ justifyContent: 'center' }}>
                    <Button
                        variant={this.props.multi_button === 'edit violation' ? 'light' : 'success'}
                        onClick={() => this.editViolation()}
                        className="btn-op70 submit-buttons mt-2"
                        disabled={this.props.multi_button === 'edit violation' || !edit_validated}
                    >
                        {this.props.multi_button === 'edit violation' ? 'Saving...' : 'Save'}
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => this.setState({ edit_traffic: initial_violation })}
                        className="btn-op70 submit-buttons mt-2"
                    >
                        Cancel
                    </Button>
                </InputGroup>
            </div>
        );
        //#endregion Edit Violation Form

        let decide_accidents = has_traffic_violations;
        if (this.props.traffic_violations.length > 0) {
            decide_accidents = 'Y';
        }

        const has_violations =
            decide_accidents === 'Y' ? (
                <div className="d-flex flex-column">
                    <InputGroup className="mb-3 opacity70 w-75 align-self-center">
                        <FloatingLabel label="Do you have any traffic violations?">
                            <Form.Select
                                variant="outline-secondary"
                                value={has_traffic_violations}
                                name="has_traffic_violations"
                                onChange={(e) => this.setState({ has_traffic_violations: e.target.value })}
                                isValid={has_traffic_violations !== ''}
                                isInvalid={has_traffic_violations === ''}
                            >
                                <option value="">Select</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </Form.Select>
                        </FloatingLabel>
                    </InputGroup>

                    {violations_accordion}
                    {edit_traffic.Id === 0 ? new_violation_form : edit_violation_form}
                    <Stack direction="horizontal" gap={3} className="mt-5" style={{ alignSelf: 'center' }} fluid="xs">
                        <Button
                            variant="outline-secondary"
                            onClick={() => this.setHasViolations('back')}
                            className="btn-op70 submit-buttons"
                        >
                            Back
                        </Button>
                        <Button
                            variant={this.props.multi_button === 'next' ? 'light' : 'secondary'}
                            onClick={() => this.setHasViolations('next')}
                            className="btn-op70 submit-buttons"
                        >
                            Next
                        </Button>
                    </Stack>
                </div>
            ) : (
                <div className="d-flex flex-column">
                    <InputGroup className="mb-3 opacity70 w-75 align-self-center">
                        <FloatingLabel label="Do you have any traffic violations?">
                            <Form.Select
                                variant="outline-secondary"
                                value={has_traffic_violations}
                                name="has_traffic_violations"
                                onChange={(e) => this.setState({ has_traffic_violations: e.target.value })}
                                isValid={has_traffic_violations !== ''}
                                isInvalid={has_traffic_violations === ''}
                            >
                                <option value="">Select</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </Form.Select>
                        </FloatingLabel>
                    </InputGroup>

                    <Stack direction="horizontal" gap={3} className="mt-5" style={{ alignSelf: 'center' }} fluid="xs">
                        <Button
                            variant="outline-secondary"
                            onClick={() => this.setHasViolations('back')}
                            className="btn-op70 submit-buttons"
                        >
                            Back
                        </Button>
                        <Button
                            variant={this.props.multi_button === 'next' ? 'light' : 'secondary'}
                            onClick={() => this.setHasViolations('next')}
                            className="btn-op70 submit-buttons"
                        >
                            Next
                        </Button>
                    </Stack>
                </div>
            );

        return has_violations;
    }
}
