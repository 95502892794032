import React, { useState } from 'react';
import { Alert, Button, FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { sendGetFetch } from '../application/FetchFunctions';

export const ForgotPassword = ({ ...props }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [buttonClicked, setButtonClicked] = useState(false);

    function handleEmailChange(e) {
        setEmail(e.target.value);
    }

    function handleKeyPress(e) {
        if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
            if (e.key === 'Enter') {
                sendEmail();
            }
        }
    }

    async function sendEmail() {
        setButtonClicked(true);
        await sendGetFetch('mailgun/RetrievePassword/' + email).then((res) => {
            if (res === 'Password request sent') {
                setError('');
                setEmail('');
                props.onHide();
                setButtonClicked(false);
            } else {
                setError('No application exists with that email.');
                setButtonClicked(false);
            }
        });
    }

    let disable_login = true;

    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
        disable_login = false;
    } else {
        disable_login = true;
    }

    return (
        <div className="m-0 d-flex flex-column">
            <div className="d-flex flex-row justify-content-start mb-4">
                <Button variant="secondary" className="btn-op70" onClick={() => props.clearApp()}>
                    Go Back
                </Button>
            </div>
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel controlId="email-address-input" label="Email Address">
                    <Form.Control
                        controlId="email-address-input"
                        variant="outline-secondary"
                        type="email"
                        maxLength={30}
                        value={email}
                        name="email"
                        onChange={handleEmailChange}
                        onKeyPress={handleKeyPress}
                        disabled={buttonClicked}
                        isInvalid={!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)}
                        isValid={/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)}
                    />
                </FloatingLabel>
                <Button
                    variant="success"
                    size="med"
                    type="button"
                    style={{ alignSelf: 'stretch' }}
                    className="btn-op70"
                    onClick={() => sendEmail()}
                    disabled={buttonClicked || disable_login}
                >
                    {buttonClicked ? 'Sending...' : 'Retrieve'}
                </Button>
            </InputGroup>

            {error !== '' ? (
                <Alert variant="danger" show={true}>
                    <Alert.Heading as="h6">Password Retrieval Issue</Alert.Heading>
                    <p>{error}</p>
                </Alert>
            ) : null}
            <Button variant="link" className="btn-op70" onClick={() => props.onHide()}>
                Hide Forgot Password
            </Button>
        </div>
    );
};
