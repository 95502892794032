import { format } from 'date-fns';
import React, { Component } from 'react';
import { Alert, Form, Image, InputGroup } from 'react-bootstrap';
import { sendGetFetch } from '../application/FetchFunctions';
import ApplicationButtonBar from '../buttons/ApplicationButtonBar';

export class BackgroundCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
        };

        this.deleteDocument = this.deleteDocument.bind(this);
        this.signDocument = this.signDocument.bind(this);
        this.submitInfo = this.submitInfo.bind(this);
    }

    deleteDocument() {
        this.props.onDelete();
    }

    async signDocument(signed) {
        const current_document = this.props.background_check;
        if (current_document.App_id === 0) {
            current_document.App_id = this.props.app_id;
        }
        if (signed === 'Y') {
            await sendGetFetch('https://ipapi.co/json/').then((res) => {
                current_document.Signed = 'Y';
                current_document.Signed_Date_Time = format(new Date(), 'MM/dd/yyyy HH:mm:ss');
                current_document.IP_Address = res.ip;
                this.props.onUpdate(current_document);
            });
        } else {
            current_document.Signed = 'N';
            current_document.Signed_Date_Time = '';
            current_document.IP_Address = '';
            this.props.onUpdate(current_document);
        }

        this.setState({ validated: true });
    }

    async submitInfo(button) {
        this.props.setMultiButton(button);
        let next_view = 'background';
        if (button === 'back') {
            next_view = 'reference';
        } else {
            next_view = 'submit';
        }
        const v_background = await sendGetFetch('backgroundreport/' + this.props.app_id);
        if (v_background.Id === 0) {
            this.props.onPost();
        } else {
            if (v_background !== this.props.background_check) {
                this.props.onPut();
            }
        }

        this.props.setMultiButton('');
        this.props.setView(next_view);
    }

    render() {
        const { validated } = this.state;

        const application_agreement_form =
            this.props.background_check !== undefined ? (
                <div className="application-form">
                    <h5 className="text-start">IMPORTANT DISCLOSURE</h5>
                    <h6 className="mt-3 mb-4 text-start">REGARDING BACKGROUND REPORTS FROM THE PSP Online Service</h6>
                    <p className="text-start mb-3">
                        In connection with your application for employment with Scotlynn (“Prospective Employer”),
                        Prospective Employer, its employees, agents or contractors may obtain one or more reports
                        regarding your driving, and safety inspection history from the Federal Motor Carrier Safety
                        Administration (FMCSA)
                    </p>

                    <p className="text-start mb-3">
                        When the application for employment is submitted in person, if the Prospective Employer uses any
                        information it obtains from FMCSA in a decision to not hire you or to make any other adverse
                        employment decision regarding you, the Prospective Employer will provide you with a copy of the
                        report upon which its decision was based and a written summary of your rights under the Fair
                        Credit Reporting Act before taking any final adverse action. If any final adverse action is
                        taken against you based upon your driving history or safety report, the Prospective Employer
                        will notify you that the action has been taken and that the action was based in part or in whole
                        on this report.
                    </p>
                    <p className="text-start mb-3">
                        When the application for employment is submitted by mail, telephone, computer, or other similar
                        means, if the Prospective Employer uses any information it obtains from FMCSA in a decision to
                        not hire you or to make any other adverse employment decision regarding you, the Prospective
                        Employer must provide you within three business days of taking adverse action oral, written or
                        electronic notification: that adverse action has been taken based in whole or in part on
                        information obtained from FMCSA; the name, address, and the toll free telephone number of FMCSA;
                        that the FMCSA did not make the decision to take the adverse action and is unable to provide you
                        the specific reasons why the adverse action was taken; and that you may, upon providing proper
                        identification, request a free copy of the report and may dispute with the FMCSA the accuracy or
                        completeness of any information or report. If you request a copy of a driver record from the
                        Prospective Employer who procured the report, then, within 3 business days of receiving your
                        request, together with proper identification, the Prospective Employer must send or provide to
                        you a copy of your report and a summary of your rights under the Fair Credit Reporting Act.
                    </p>

                    <p className="text-start mb-3">
                        Neither the Prospective Employer nor the FMCSA contractor supplying the crash and safety
                        information has the capability to correct any safety data that appears to be incorrect. You may
                        challenge the accuracy of the data by submitting a request to{' '}
                        <a href="https://dataqs.fmcsa.dot.gov" target="_blank" rel="noreferrer">
                            https://dataqs.fmcsa.dot.gov
                        </a>{' '}
                        . If you challenge crash or inspection information reported by a State, FMCSA cannot change or
                        correct this data. Your request will be forwarded by the DataQs system to the appropriate State
                        for adjudication.
                    </p>

                    <p className="text-start mb-3">
                        Any crash or inspection in which you were involved will display on your PSP report. Since the
                        PSP report does not report, or assign, or imply fault, it will include all Commercial Motor
                        Vehicle (CMV) crashes where you were a driver or co-driver and where those crashes were reported
                        to FMCSA, regardless of fault. Similarly, all inspections, with or without violations, appear on
                        the PSP report. State citations associated with Federal Motor Carrier Safety Regulations (FMCSR)
                        violations that have been adjudicated by a court of law will also appear, and remain, on a PSP
                        report.
                    </p>

                    <p className="text-start mb-3">
                        The Prospective Employer cannot obtain background reports from FMCSA without your authorization.
                    </p>

                    <h5 className="mt-3 mb-3 text-start">AUTHORIZATION</h5>

                    <p className="text-start mb-3">
                        If you agree that the Prospective Employer may obtain such background reports, please read the
                        following and sign below:
                    </p>

                    <p className="text-start mb-3">
                        I authorize (“Prospective Employer”) Scotlynn to access the FMCSA Pre-Employment Screening
                        Program (PSP) system to seek information regarding my commercial driving safety record and
                        information regarding my safety inspection history. I understand that I am authorizing the
                        release of safety performance information including crash data from the previous five (5) years
                        and inspection history from the previous three (3) years. I understand and acknowledge that this
                        release of information may assist the Prospective Employer to make a determination regarding my
                        suitability as an employee.
                    </p>
                    <p className="text-start mb-3">
                        I further understand that neither the Prospective Employer nor the FMCSA contractor supplying
                        the crash and safety information has the capability to correct any safety data that appears to
                        be incorrect. I understand I may challenge the accuracy of the data by submitting a request to{' '}
                        <a href="https://dataqs.fmcsa.dot.gov" target="_blank" rel="noreferrer">
                            {' '}
                            https://dataqs.fmcsa.dot.gov
                        </a>
                        . If I challenge crash or inspection information reported by a State, FMCSA cannot change or
                        correct this data. I understand my request will be forwarded by the DataQs system to the
                        appropriate State for adjudication.
                    </p>

                    <p className="text-start mb-3">
                        I understand that any crash or inspection in which I was involved will display on my PSP report.
                        Since the PSP report does not report, or assign, or imply fault, I acknowledge it will include
                        all CMV crashes where I was a driver or codriver and where those crashes were reported to FMCSA,
                        regardless of fault. Similarly, I understand all inspections, with or without violations, will
                        appear on my PSP report, and State citations associated with FMCSR violations that have been
                        adjudicated by a court of law will also appear, and remain, on my PSP report.
                    </p>

                    <p className="text-start mb-3">
                        I have read the above Disclosure Regarding Background Reports provided to me by Prospective
                        Employer and I understand that if I sign this Disclosure and Authorization, Prospective Employer
                        may obtain a report of my crash and inspection history. I hereby authorize Prospective Employer
                        and its employees, authorized agents, and/or affiliates to obtain the information authorized
                        above.
                    </p>
                    <InputGroup className="mb-3 op-70 mt-5">
                        <InputGroup.Text>Full Name</InputGroup.Text>
                        <Form.Control
                            readOnly
                            value={
                                this.props.personal_history !== undefined ? this.props.personal_history.Full_Name : ''
                            }
                            variant="outline-secondary"
                        ></Form.Control>
                    </InputGroup>

                    {this.props.background_check.Signed === 'Y' ? (
                        <div className="d-flex flex-column">
                            <Image
                                src={this.props.signature}
                                style={{ maxWidth: '60vw', alignSelf: 'center' }}
                                className="rounded mb-2"
                                fluid
                                onClick={() => this.signDocument('N')}
                            />
                            <small className="font-italic">
                                Signed:{' '}
                                {format(new Date(this.props.background_check.Signed_Date_Time), 'MM/dd/yyyy HH:mm:ss')}
                            </small>
                        </div>
                    ) : (
                        <Alert variant="danger" onClick={() => this.signDocument('Y')}>
                            Click here to sign
                        </Alert>
                    )}

                    <p className="mt-5 text-start">
                        <strong>
                            {' '}
                            NOTICE: This form is made available to monthly account holders by NIC on behalf of the U.S.
                            Department of Transportation, Federal Motor Carrier Safety Administration (FMCSA). Account
                            holders are required by federal law to obtain an Applicant’s written or electronic consent
                            prior to accessing the Applicant’s PSP report. Further, account holders are required by
                            FMCSA to use the language contained in this Disclosure and Authorization form to obtain an
                            Applicant’s consent. The language must be used in whole, exactly as provided. Further, the
                            language on this form must exist as one stand-alone document. The language may NOT be
                            included with other consent forms or any other language.
                        </strong>
                    </p>

                    <p className="mt-2 text-start">
                        <strong>
                            NOTICE: The prospective employment concept referenced in this form contemplates the
                            definition of “employee” contained at 49 C.F.R. 383.5.
                        </strong>
                    </p>
                </div>
            ) : null;

        return (
            <div className="d-flex flex-column">
                {application_agreement_form}
                <ApplicationButtonBar
                    multi_button={this.props.multi_button}
                    submitInfo={(prop) => this.submitInfo(prop)}
                    validated={validated}
                    with_signature={this.props.background_check.Signed}
                />
            </div>
        );
    }
}
