import { format } from 'date-fns';
import React, { Component } from 'react';
import { Alert, Form, Image, InputGroup } from 'react-bootstrap';
import { sendGetFetch } from '../application/FetchFunctions';
import ApplicationButtonBar from '../buttons/ApplicationButtonBar';

export class ApplicationAgreement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
        };

        this.addDocument = this.addDocument.bind(this);
        this.editDocument = this.editDocument.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.signDocument = this.signDocument.bind(this);
        this.submitInfo = this.submitInfo.bind(this);
    }

    async addDocument() {
        this.props.setMultiButton('save');

        await this.props.onPost();
        this.props.setMultiButton('');
    }
    editDocument() {
        this.props.onPut();
    }
    deleteDocument() {
        this.props.onDelete();
    }

    async submitInfo(button) {
        this.props.setMultiButton(button);
        let next_view = 'agreement';
        if (button === 'back') {
            next_view = 'traffic';
        } else {
            next_view = 'reference';
        }
        const v_application_agreement = await sendGetFetch('applicationagreement/' + this.props.app_id);
        if (v_application_agreement.Id === 0) {
            this.props.onPost();
        } else {
            if (v_application_agreement !== this.props.application_agreement) {
                this.props.onPut();
            }
        }

        this.props.setMultiButton('');
        this.props.setView(next_view);
    }

    async signDocument(signed) {
        const current_document = this.props.application_agreement;
        if (current_document.App_id === 0) {
            current_document.App_id = this.props.app_id;
        }
        if (signed === 'Y') {
            await sendGetFetch('https://ipapi.co/json/').then((res) => {
                current_document.Signed = 'Y';
                current_document.Signed_Date_Time = format(new Date(), 'MM/dd/yyyy HH:mm:ss');
                current_document.IP_Address = res.ip;
                this.props.onUpdate(current_document);
            });
        } else {
            current_document.Signed = 'N';
            current_document.Signed_Date_Time = '';
            current_document.IP_Address = '';
            this.props.onUpdate(current_document);
        }

        this.setState({ validated: true });
    }

    render() {
        const { validated } = this.state;

        const agreement_paragraph = (
            <p className="text-color mb-3">
                This certifies that this application was completed by me <strong>{this.props.Full_Name}</strong> and
                that all information is true and complete to the best of my knowledge. I authorize you to make such
                investigations and inquires of my personal employment, financial or medical his and other related
                matters, as may employers, schools, or persons from all liability in responding to inquire in connection
                with application. In the event of employment, understand that false or misleading information given in
                my application may result in discharge. I understand also that I am required to be abide by all rules
                and regualtions of the company as permitted by law.
            </p>
        );

        const applicant_name = (
            <InputGroup className="mb-3 op-70">
                <InputGroup.Text>Applicant's Full Name</InputGroup.Text>
                <Form.Control readOnly value={this.props.Full_Name} variant="outline-secondary"></Form.Control>
            </InputGroup>
        );

        const applicant_signed =
            this.props.application_agreement.Signed === 'Y' ? (
                <div className="d-flex flex-column">
                    <Image
                        src={this.props.signature}
                        style={{ maxWidth: '60vw', alignSelf: 'center', overflow:'auto' }}
                        className="rounded mb-2"
                        fluid
                        onClick={() => this.signDocument('N')}
                    />
                    <small className="font-italic">
                        Signed:{' '}
                        {format(new Date(this.props.application_agreement.Signed_Date_Time), 'MM/dd/yyyy HH:mm:ss')}
                    </small>
                </div>
            ) : (
                <Alert variant="danger" onClick={() => this.signDocument('Y')} className="mb-4 mt-2">
                    Click here to sign
                </Alert>
            );

        return (
            <div className="d-flex flex-column">
                {agreement_paragraph}
                {applicant_name}
                {applicant_signed}
                <ApplicationButtonBar
                    multi_button={this.props.multi_button}
                    submitInfo={(prop) => this.submitInfo(prop)}
                    validated={validated}
                    with_signature={this.props.application_agreement.Signed}
                />
            </div>
        );
    }
}
