import { Button, Col, Container, Row } from 'react-bootstrap';

//props: multi_button, submitInfo('')

function ApplicationButtonBar({ ...props }) {
    const hide_save = props.hide_save ? props.hide_save : 'N';
    const with_signature = props.with_signature ? props.with_signature : 'X';

    return (
        <Container fluid="xs">
            {with_signature === 'N' ? (
                <Row className="d-flex flex-row justify-content-around">
                    <Col lg={4} sm={5} className="mt-2">
                        <Button
                            variant={props.multi_button === 'next' ? 'light' : 'outline-secondary'}
                            onClick={() => props.submitInfo('back')}
                            disabled={props.multi_button !== ''}
                            className="btn-op70 w-100"
                        >
                            {props.multi_button === 'back' ? 'Saving...' : 'Back'}
                        </Button>
                    </Col>
                    <Col lg={4} sm={5} className="mt-2">
                        {hide_save === 'Y' ? null : (
                            <Button
                                variant={props.multi_button === 'next' ? 'outline-danger' : 'danger'}
                                onClick={() => props.submitInfo('next')}
                                disabled={props.multi_button !== ''}
                                className="btn-op70 w-100"
                            >
                                {props.multi_button === 'next' ? 'Saving...' : 'Save & Continue Without Signing'}
                            </Button>
                        )}
                    </Col>
                </Row>
            ) : (
                <Row className="d-flex flex-row justify-content-around">
                    <Col lg={2} sm={5} className="mt-2">
                        <Button
                            variant={props.multi_button === 'back' ? 'light' : 'outline-secondary'}
                            onClick={() => props.submitInfo('back')}
                            disabled={props.multi_button !== ''}
                            className="btn-op70 w-100"
                        >
                            {props.multi_button === 'back' ? 'Saving...' : 'Back'}
                        </Button>
                    </Col>
                    {props.form_validated === 'N' ? (
                        <Col lg={2} sm={5} className="mt-2"></Col>
                    ) : (
                        <Col lg={2} sm={5} className="mt-2">
                            {hide_save === 'Y' ? null : (
                                <Button
                                    variant={props.multi_button === 'next' ? 'outline-success' : 'success'}
                                    onClick={() => props.submitInfo('next')}
                                    disabled={props.multi_button !== ''}
                                    className="btn-op70 w-100"
                                >
                                    {props.multi_button === 'next' ? 'Saving...' : 'Save & Continue'}
                                </Button>
                            )}
                        </Col>
                    )}
                </Row>
            )}
        </Container>
    );
}

export default ApplicationButtonBar;
