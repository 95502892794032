import React, { useEffect } from 'react';

function TextSignature({ ...props }) {
    let signatureText = null;

    const writeText = (info, style = {}) => {
        const { text, x, y } = info;
        const {
             fontSize = window.innerHeight * 0.075,
            fontFamily = props.font,
            color = 'black',
            textAlign = 'center',
              textBaseline = 'top',
        } = style;
        try {
            signatureText.beginPath();
            signatureText.font = fontSize + 'px ' + fontFamily;
            signatureText.textAlign = textAlign;

            signatureText.textBaseline = textBaseline;
            signatureText.fillStyle = color;
            signatureText.fillText(text, x, y);
            signatureText.stroke();
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        try {
            const canvas_sig_text = props.textCanvas.current;
            canvas_sig_text.width = window.innerWidth ;
            canvas_sig_text.height = window.innerHeight * 0.3;
            signatureText = props.textCanvas.current.getContext('2d');
        } catch (e) {
            console.log(e);
        }
    }, [props.textCanvas.current]);

    useEffect(() => {
        writeText({ text: props.full_name, x: window.innerWidth * 0.5, y: window.innerHeight * 0.15 });
    }, []);

    return <canvas ref={props.textCanvas} className="generated-signature"></canvas>;
}

export default TextSignature;
