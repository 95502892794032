import React, { Component } from 'react';
import { Figure, FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { sendDeleteFetch, sendGetFetch, sendPutFetch } from '../application/FetchFunctions';
import ApplicationButtonBar from '../buttons/ApplicationButtonBar';
import { initial_truck } from '../dtos/InitialValues';
import * as utilityFunctions from '../utilities/UtilityFunctions';

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export class GeneralInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            info_changed: false,
            truck_changed: false,
            recruiter_changed: false,
            updated_recruiter: false,
            truck_images_changed: false,
            general_imaged_changed: false,
        };
        this.handleApplicationChange = this.handleApplicationChange.bind(this);
        this.handleTruckChange = this.handleTruckChange.bind(this);
        this.updateGeneralInfo = this.updateGeneralInfo.bind(this);
        this.handleRecruiterChange = this.handleRecruiterChange.bind(this);
        this.deleteLicense = this.deleteLicense.bind(this);
        this.deleteTruck = this.deleteTruck.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.submitInfo = this.submitInfo.bind(this);
    }

    handleRecruiterChange(e) {
        this.setState({ recruiter_changed: true, updated_recruiter: false });
        this.props.updateRecruiter(e.target.value);
        this.validateForm();
    }

    componentDidUpdate() {
        if (this.state.recruiter_changed && !this.state.updated_recruiter) {
            this.validateForm();
            this.setState({ updated_recruiter: true });
        }
    }

    async handleTruckChange(e) {
        if (e.target.files) {
            this.setState({ truck_images_changed: true });
            const file = e.target.files[0];
            const new_file = await utilityFunctions.Compress_Image(file);

            const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
            if (e.target.name === 'Front_Image_id') {
                await convertBase64(new_file).then((res) => {
                    this.props.update_truck_front_file(res);
                    const body = {
                        base64string: res,
                        uploadType: 'front_truck.' + ext,
                    };
                    this.props.uploadattachment(body);
                });
            } else if (e.target.name === 'Back_Image_id') {
                await convertBase64(new_file).then((res) => {
                    this.props.update_truck_back_file(res);
                    const body = {
                        base64string: res,
                        uploadType: 'back_truck.' + ext,
                    };
                    this.props.uploadattachment(body);
                });
            } else if (e.target.name === 'Left_Image_id') {

                await convertBase64(new_file).then((res) => {
                    this.props.update_truck_left_file(res);
                    const body = {
                        base64string: res,
                        uploadType: 'left_truck.' + ext,
                    };
                    this.props.uploadattachment(body);
                });
            } else if (e.target.name === 'Right_Image_id') {

                await convertBase64(new_file).then((res) => {
                    this.props.update_truck_right_file(res);
                    const body = {
                        base64string: res,
                        uploadType: 'right_truck.' + ext,
                    };
                    this.props.uploadattachment(body);
                });
            } else {
                const current_info = this.props.truck_info;
                if (current_info.App_id === 0) {
                    current_info.App_id = this.props.app_id;
                }
                current_info[e.target.name] = e.target.value;
                this.props.onupdateTruck(current_info);
            }

            this.validateForm();
        }
        
    }

    async handleApplicationChange(e) {
        this.setState({ info_changed: true, general_images_changed:true });
        try {
            if (e.target.files) {
                const file = e.target.files[0];
                const new_file = await utilityFunctions.Compress_Image(file);
                const ext = file ? file.name.substr(file.name.lastIndexOf('.') + 1) : '';
                if (e.target.name === 'License_Copy_id') {
                    await convertBase64(new_file).then((res) => {
                        this.props.update_driver_license_file(res);
                        const body = {
                            base64string: res,
                            uploadType: 'license_copy.' + ext,
                        };
                        this.props.uploadattachment(body);
                        this.validateForm();
                    });
                } else if (e.target.name === 'License_Copy_id_Back') {
                    await convertBase64(new_file).then((res) => {
                        this.props.update_driver_license_file_back(res);
                        const body = {
                            base64string: res,
                            uploadType: 'license_back.' + ext,
                        };

                        this.props.uploadattachment(body);
                        this.validateForm();
                    });
                } else {
                    const updatedInfo = this.props.general_info;
                    if (updatedInfo.App_id === 0) {
                        updatedInfo.App_id = this.props.app_id;
                    }
                    updatedInfo[e.target.name] = e.target.value;
                    this.props.onupdate(updatedInfo);
                    this.validateForm();
                }
            } else {
                const updatedInfo = this.props.general_info;
                if (updatedInfo.App_id === 0) {
                    updatedInfo.App_id = this.props.app_id;
                }
                updatedInfo[e.target.name] = e.target.value;
                this.props.onupdate(updatedInfo);
                this.validateForm();
            }
          
          
        } catch (e) {
            console.log(e);
        }
    }

    async deleteTruck() {
        await sendDeleteFetch(JSON.stringify(this.props.truck_info), 'truckinfo').then((res) => {
            if (res === 'open issue') {
                this.props.setIssue('Could not delete Truck Information.');
            } else {
                this.props.onupdateTruck(initial_truck);
            }
        });
    }

    async deleteLicense(name, field) {
        await sendGetFetch('attachment/DeleteAttachment/' + name + '/' + this.props.app_id).then((res) => {
            if (res === 'open issue') {
                this.props.setIssue('Could not delete License Copy Back.');
            } else {
                this.props.update_driver_license_file(undefined);
                var generalInfo = this.props.general_info;
                generalInfo[field] = '';
                sendPutFetch(JSON.stringify(generalInfo), 'generalinfo').then((res) => {
                    if (res === 'open issue') {
                        this.props.setIssue('Could not update General Information after deleting License Copy Back.');
                    } else {
                        this.props.onupdate(generalInfo);
                    }
                });
            }
        });
    }

    async updateGeneralInfo() {
        if (this.props.general_info.Position_Applying_For === 'Owner Operator') {
            await this.props.onPutTruck();
            await this.props.onPut();
            await this.props.onPutRecruiter();
        } else {
            await this.props.onPut();
            await this.props.onPutRecruiter();
        }
    }

    async submitInfo(button) {
        this.props.setMultiButton(button);
        let next_view = 'general';
        if (button === 'back') {
            next_view = 'signature';
        } else {
            next_view = 'personal';
        }
        const v_general_info = await sendGetFetch('generalinfo/' + this.props.app_id);
        const v_truck_info = await sendGetFetch('truckinfo/' + this.props.app_id);
        if (v_general_info.Id === 0) {
            await this.props.onPost();
            await this.props.onPostTruck();
        } else {
            if (v_general_info !== this.props.general_info) {
                await this.props.onPut();
            }
            if (v_truck_info.Id === 0) {
                await this.props.onPostTruck();
            } else {
                if (v_truck_info !== this.props.truck_info) {
                    await this.props.onPutTruck();
                }
            }
        }
        if (this.state.recruiter_changed) {
            await this.props.putRecruiter();
            this.setState({ recruiter_changed: false });
        }
        this.props.setMultiButton('');
        this.props.setView(next_view);
    }

    validateForm() {
        const generalInfo = this.props.general_info;
        const truckInfo = this.props.truck_info;

        const v_recruiter = this.props.current_recruiter === '' ? false : true;

        let v_hear_about = false;
        let v_position = false;
        let v_truck_info = false;

        if (generalInfo.Hear_About !== '') {
            if (this.props.general_info.Hear_About.includes('Other' && this.props.general_info.Other_Details !== '')) {
                v_hear_about = true;
            } else if (
                this.props.general_info.Hear_About.includes(
                    'Referral' && this.props.general_info.Referred_Employee !== '',
                )
            ) {
                v_hear_about = true;
            } else {
                v_hear_about = true;
            }
        } else {
            v_hear_about = false;
        }

        if (truckInfo.Make !== '') {
            if (truckInfo.Model !== '') {
                if (truckInfo.Year !== '') {
                    if (truckInfo.Transmission_Capability !== '') {
                        if (truckInfo.Daycab_Bunk !== '') {
                            if (truckInfo.Front_Image_id !== '') {
                                if (truckInfo.Back_Image_id !== '') {
                                    if (truckInfo.Left_Image_id !== '') {
                                        if (truckInfo.Right_Image_id !== '') {
                                            v_truck_info = true;
                                        } else {
                                            v_truck_info = false;
                                        }
                                    } else {
                                        v_truck_info = false;
                                    }
                                } else {
                                    v_truck_info = false;
                                }
                            } else {
                                v_truck_info = false;
                            }
                        } else {
                            v_truck_info = false;
                        }
                    } else {
                        v_truck_info = false;
                    }
                } else {
                    v_truck_info = false;
                }
            } else {
                v_truck_info = false;
            }
        }

        if (generalInfo.Position_Applying_For !== '') {
            if (generalInfo.Position_Applying_For === 'Owner Operator') {
                if (v_truck_info) {
                    v_position = true;
                } else {
                    v_position = false;
                }
            } else {
                v_position = true;
            }
        } else {
            v_position = false;
        }

        if (v_recruiter && v_hear_about && v_position) {
            this.setState({ validated: true });
        } else {
            this.setState({ validated: false });
        }
    }

    render() {
        const { validated } = this.state;

        //#region Truck Form
        let end_year = new Date().getFullYear() + 2;
        const year_list = [];
        for (let i = 0; i < 70; i++) {
            end_year = end_year - 1;
            year_list.push({ val: end_year });
        }

        const truck_form =
            this.props.general_info.Position_Applying_For === 'Owner Operator' ? (
                <div className="d-block">
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="truck-make" label="Truck Make">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                value={this.props.truck_info.Make}
                                name="Make"
                                onChange={this.handleTruckChange}
                                isValid={this.props.truck_info.Make !== ''}
                                isInvalid={this.props.truck_info.Make === ''}
                                controlId="truck-make"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="truck-model" label="Truck Model">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                value={this.props.truck_info.Model}
                                name="Model"
                                onChange={this.handleTruckChange}
                                isValid={this.props.truck_info.Model !== ''}
                                isInvalid={this.props.truck_info.Model === ''}
                                controlId="truck-model"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="truck-year" label="Truck Year">
                            <Form.Select
                                controlId="truck-year"
                                variant="outline-secondary"
                                value={this.props.truck_info.Year}
                                onChange={this.handleTruckChange}
                                name="Year"
                                isValid={this.props.truck_info.Year !== ''}
                                isInvalid={this.props.truck_info.Year === ''}
                            >
                                {year_list.map((d, index) => (
                                    <option value={d.val} key={index}>
                                        {d.val}
                                    </option>
                                ))}
                                <option value="">Select</option>
                            </Form.Select>
                        </FloatingLabel>
                    </InputGroup>
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="transmission-capability" label="Transmission Capability">
                            <Form.Select
                                controlId="transmission-capability"
                                variant="outline-secondary"
                                value={this.props.truck_info.Transmission_Capability}
                                onChange={this.handleTruckChange}
                                name="Transmission_Capability"
                                isValid={this.props.truck_info.Transmission_Capability !== ''}
                                isInvalid={this.props.truck_info.Transmission_Capability === ''}
                            >
                                <option value="Manual">Manual</option>
                                <option value="Automatic">Automatic</option>
                                <option value="Both">Both</option>
                                <option value="">Select</option>
                            </Form.Select>
                        </FloatingLabel>
                    </InputGroup>
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="daybed-bunk" label="Daycab/Bunk">
                            <Form.Select
                                controlId="daybed-bunk"
                                variant="outline-secondary"
                                value={this.props.truck_info.Daycab_Bunk}
                                onChange={this.handleTruckChange}
                                name="Daycab_Bunk"
                                isValid={this.props.truck_info.Daycab_Bunk !== ''}
                                isInvalid={this.props.truck_info.Daycab_Bunk === ''}
                            >
                                <option value="Daycab">Daycab</option>
                                <option value="Bunk">Bunk</option>
                                <option value="">Select</option>
                            </Form.Select>
                        </FloatingLabel>
                    </InputGroup>
                </div>
            ) : null;
        //#endregion Truck Form

        //#region Truck Images Form
        const truck_images_form =
            this.props.general_info.Position_Applying_For === 'Owner Operator' ? (
                <div className="d-block">
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="front-truck-id" label="Truck Image (Front)">
                            <Form.Control
                                type="file"
                                variant="outline-secondary"
                                onChange={this.handleTruckChange}
                                name="Front_Image_id"
                                isValid={
                                    this.props.truck_front_file !== undefined && this.props.truck_front_file !== null
                                }
                                isInvalid={
                                    this.props.truck_front_file === undefined || this.props.truck_front_file === null
                                }
                                accept=".png,.PNG,.jpg, .jpeg"
                                controlId="front-truck-id"
                            />
                        </FloatingLabel>
                    </InputGroup>
                    {this.props.truck_front_file === undefined || this.props.truck_front_file === null ? null : (
                        <Figure className="d-flex flex-column">
                            <Figure.Image rounded fluid src={this.props.truck_front_file} className="license-image" />
                            <Figure.Caption>
                                <small>Truck Image (Front)</small>
                            </Figure.Caption>
                        </Figure>
                    )}
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel label="Truck Image (Back)">
                            <Form.Control
                                type="file"
                                variant="outline-secondary"
                                onChange={this.handleTruckChange}
                                name="Back_Image_id"
                                isValid={
                                    this.props.truck_back_file !== undefined && this.props.truck_back_file !== null
                                }
                                isInvalid={
                                    this.props.truck_back_file === undefined || this.props.truck_back_file === null
                                }
                                accept=".png,.PNG,.jpg, .jpeg"
                            />
                        </FloatingLabel>
                    </InputGroup>
                    {this.props.truck_back_file === undefined || this.props.truck_back_file === null ? null : (
                        <Figure className="d-flex flex-column">
                            <Figure.Image rounded fluid src={this.props.truck_back_file} className="license-image" />
                            <Figure.Caption>
                                <small>Truck Image (Back)</small>
                            </Figure.Caption>
                        </Figure>
                    )}
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="right-truck-id" label="Truck Image (Right-Side)">
                            <Form.Control
                                type="file"
                                variant="outline-secondary"
                                onChange={this.handleTruckChange}
                                name="Right_Image_id"
                                isValid={
                                    this.props.truck_right_file !== undefined && this.props.truck_right_file !== null
                                }
                                isInvalid={
                                    this.props.truck_right_file === undefined || this.props.truck_right_file === null
                                }
                                accept=".png,.PNG,.jpg, .jpeg"
                                controlId="right-truck-id"
                            />
                        </FloatingLabel>
                    </InputGroup>
                    {this.props.truck_right_file === undefined || this.props.truck_right_file === null ? null : (
                        <Figure className="d-flex flex-column">
                            <Figure.Image rounded fluid src={this.props.truck_right_file} className="license-image" />
                            <Figure.Caption>
                                <small>Truck Image (Right-Side)</small>
                            </Figure.Caption>
                        </Figure>
                    )}
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="left-truck-id" label="Truck Image (Left-Side)">
                            <Form.Control
                                type="file"
                                variant="outline-secondary"
                                onChange={this.handleTruckChange}
                                name="Left_Image_id"
                                isValid={
                                    this.props.truck_left_file !== undefined && this.props.truck_left_file !== null
                                }
                                isInvalid={
                                    this.props.truck_left_file === undefined || this.props.truck_left_file === null
                                }
                                accept=".png,.PNG,.jpg, .jpeg"
                                controlId="left-truck-id"
                            />
                        </FloatingLabel>
                    </InputGroup>
                    {this.props.truck_left_file === undefined || this.props.truck_left_file === null ? null : (
                        <Figure className="d-flex flex-column">
                            <Figure.Image rounded fluid src={this.props.truck_left_file} className="license-image" />
                            <Figure.Caption>
                                <small>Truck Image (Left-Side)</small>
                            </Figure.Caption>
                        </Figure>
                    )}
                </div>
            ) : null;
        //#endregion Truck Images Form

        //#region Recruiter Form
        const recruiter_form = (
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel controlId="new-applicant-recruiter" label="Recruiter">
                    <Form.Select
                        controlId="new-applicant-recruiter"
                        variant="outline-secondary"
                        value={this.props.current_recruiter}
                        onChange={this.handleRecruiterChange}
                        isValid={this.props.current_recruiter !== ''}
                        isInvalid={this.props.current_recruiter === ''}
                    >
                        <option value="">Select</option>
                        {this.props.recruiters
                            .filter((s) => s.Is_Active === 'Y')
                            .map((d, index) => (
                                <option value={d.Email} key={index}>
                                    {d.First_Name}
                                </option>
                            ))}
                        <option value="recruiting@scotlynn.com">No Recruiter</option>
                    </Form.Select>
                </FloatingLabel>
            </InputGroup>
        );
        //#endregion Recruiter Form

        //#region Hear About Form
        const hear_about_form = (
            <div className="d-block">
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="general-hear-about" label="How did you hear about Scotlynn?">
                        <Form.Select
                            controlId="general-hear-about"
                            variant="outline-secondary"
                            value={this.props.general_info.Hear_About}
                            onChange={this.handleApplicationChange}
                            name="Hear_About"
                            isValid={this.props.general_info.Hear_About !== ''}
                            isInvalid={this.props.general_info.Hear_About === ''}
                        >
                            <option value="Other">Other</option>
                            <option value="Indeed">Indeed</option>
                            <option value="Kijiji">Kijiji</option>
                            <option value="Referral">Referral</option>
                            <option value="Job Fair">Job Fair</option>
                            <option value="" label="Select Option" />
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>

                {this.props.general_info.Hear_About.includes('Other') ? (
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="hear-about-other" label="Please Specify">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                name="Other_Details"
                                value={this.props.general_info.Other_Details}
                                onChange={this.handleApplicationChange}
                                isValid={this.props.general_info.Other_Details !== ''}
                                isInvalid={this.props.general_info.Other_Details === ''}
                                controlId="hear-about-other"
                                maxLength={249}
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>
                ) : null}

                {this.props.general_info.Hear_About.includes('Referral') ? (
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="referred-employee" label="Referred Employee">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                name="Referred_Employee"
                                value={this.props.general_info.Referred_Employee}
                                onChange={this.handleApplicationChange}
                                isValid={this.props.general_info.Referred_Employee !== ''}
                                isInvalid={this.props.general_info.Referred_Employee === ''}
                                controlId="referred-employee"
                                maxLength={100}
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>
                ) : null}
            </div>
        );

        //#endregion Hear About Form

        //#region Position Applying For Form
        const position_applying_for_form = (
            <div className="d-block">
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="position-applying-for" label="Position Applying For">
                        <Form.Select
                            controlId="position-applying-for"
                            variant="outline-secondary"
                            value={this.props.general_info.Position_Applying_For}
                            onChange={this.handleApplicationChange}
                            name="Position_Applying_For"
                            isValid={this.props.general_info.Position_Applying_For !== ''}
                            isInvalid={this.props.general_info.Position_Applying_For === ''}
                        >
                            <option value="Company Driver">Company Driver</option>
                            <option value="Lease Operator">Lease Operator</option>
                            <option value="Owner Operator">Owner Operator</option>
                            <option value="" label="Select Option" />
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>
            </div>
        );
        //#endregion  Position Applying For Form

        //#region Driver License Number
        const license_number_form = (
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel label="Driver's License Number">
                    <Form.Control
                        type="text"
                        variant="outline-secondary"
                        name="License_Number"
                        value={this.props.general_info.License_Number}
                        onChange={this.handleApplicationChange}
                        isValid={this.props.general_info.License_Number !== ''}
                        isInvalid={this.props.general_info.License_Number === ''}
                        maxLength={50}
                    ></Form.Control>
                </FloatingLabel>
            </InputGroup>
        );
        //#endregion Driver License Number

        //#region Driver License State/Province
        const license_state_form = this.props.state_provs ? (
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel label="Driver's License State/Province">
                    <Form.Select
                        type="text"
                        variant="outline-secondary"
                        value={this.props.general_info.License_State}
                        name="License_State"
                        onChange={this.handleApplicationChange}
                        isValid={this.props.general_info.License_State !== ''}
                        isInvalid={this.props.general_info.License_State === ''}
                        controlId="violation-location"
                    >
                        <option value="">Select State/Province</option>
                        {this.props.state_provs.map((d, index) => (
                            <option value={d.Pst_id} key={index}>
                                {d.Pst_Name}
                            </option>
                        ))}
                    </Form.Select>
                </FloatingLabel>
            </InputGroup>
        ) : null;
        //#endregion Driver License State/Province

        //#region Driver License Form
        const license_form = (
            <div className="d-block">
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="license-copy-id" label="Driver's License (Front)">
                        <Form.Control
                            type="file"
                            variant="outline-secondary"
                            onChange={this.handleApplicationChange}
                            name="License_Copy_id"
                            isValid={
                                this.props.driver_license_file !== undefined && this.props.driver_license_file !== null
                            }
                            isInvalid={
                                this.props.driver_license_file === undefined || this.props.driver_license_file === null
                            }
                            accept=".png,.PNG,.jpg, .jpeg"
                            controlId="license-copy-id"
                        />
                    </FloatingLabel>
                </InputGroup>
                {this.props.driver_license_file === undefined || this.props.driver_license_file === null ? null : (
                    <Figure className="d-flex flex-column">
                        <Figure.Image rounded fluid src={this.props.driver_license_file} className="license-image" />
                        <Figure.Caption>
                            <small>Driver's License (Front)</small>
                        </Figure.Caption>
                    </Figure>
                )}
            </div>
        );
        //#endregion Driver License Form

        //#region Driver License Back Form
        const license_form_back = (
            <div className="d-block">
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="license-copy-id-back" label="Driver's License (Back)">
                        <Form.Control
                            type="file"
                            controlId="license-copy-id-back"
                            variant="outline-secondary"
                            onChange={this.handleApplicationChange}
                            name="License_Copy_id_Back"
                            isValid={
                                this.props.driver_license_file_back !== undefined &&
                                this.props.driver_license_file_back !== null
                            }
                            isInvalid={
                                this.props.driver_license_file_back === undefined ||
                                this.props.driver_license_file_back === null
                            }
                            accept=".png,.PNG,.jpg, .jpeg"
                        />
                    </FloatingLabel>
                </InputGroup>
                {this.props.driver_license_file_back === undefined ||
                this.props.driver_license_file_back === null ? null : (
                    <Figure className="d-flex flex-column">
                        <Figure.Image
                            rounded
                            fluid
                            src={this.props.driver_license_file_back}
                            className="license-image"
                        />
                        <Figure.Caption>
                            <small>Driver's License (Back)</small>
                        </Figure.Caption>
                    </Figure>
                )}
            </div>
        );
        //#endregion Driver License Back Form

        return (
            <div className="d-flex flex-column">
                {recruiter_form}
                {hear_about_form}
                {position_applying_for_form}
                {truck_form}
                {truck_images_form}
                {license_number_form}
                {license_state_form}
                {license_form}
                {license_form_back}

                <ApplicationButtonBar
                    multi_button={this.props.multi_button}
                    submitInfo={(prop) => this.submitInfo(prop)}
                    validated={validated}
                />
            </div>
        );
    }
}
