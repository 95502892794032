import React, { Component } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { sendGetFetch } from '../application/FetchFunctions';
import ApplicationButtonBar from '../buttons/ApplicationButtonBar';

export class LicenseInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            changed: false,
        };
        this.handleApplicationChange = this.handleApplicationChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.submitInfo = this.submitInfo.bind(this);
    }

    handleApplicationChange(e) {
        var current_info = this.props.license_info;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
        }
        current_info[e.target.name] = e.target.value;
        this.props.onupdate(current_info);
        this.validateForm();
    }

    validateForm() {
        const licenseInfo = this.props.license_info;
        let is_valid = false;

        if (licenseInfo.Denied_License !== '') {
            if (licenseInfo.Suspended !== '') {
                if (licenseInfo.Suspended === 'N') {
                    is_valid = true;
                } else {
                    if (licenseInfo.Reason_For_Yes !== '') {
                        is_valid = true;
                    } else {
                        is_valid = false;
                    }
                }
            } else {
                is_valid = false;
            }
        } else {
            is_valid = false;
        }
        this.setState({ validated: is_valid });
    }

    async submitInfo(button) {
        this.props.setMultiButton(button);
        let next_view = 'license';
        if (button === 'back') {
            next_view = 'employers';
        } else {
            next_view = 'experience';
        }
        const v_license_info = await sendGetFetch('licenseinfo/' + this.props.app_id);
        if (v_license_info.Id === 0) {
            this.props.onPost();
        } else {
            if (v_license_info !== this.props.license_info) {
                this.props.onPut();
            }
        }

        this.props.setMultiButton('');
        this.props.setView(next_view);
    }

    render() {
        const { validated } = this.state;

        //#region Denied License
        const denied_license = (
            <div className="mb-3 d-flex flex-column long-field-title">
                <label
                    style={{
                        wordWrap: 'break-word',
                        alignSelf: 'stretch',
                        whiteSpace: 'normal',
                        paddingBottom: '1rem',
                    }}
                >
                    Have you ever been denied a license, permit or privilege to operate a motor vehicle?
                </label>

                <Form.Select
                    variant="outline-secondary"
                    value={this.props.license_info.Denied_License}
                    name="Denied_License"
                    onChange={this.handleApplicationChange}
                    isValid={this.props.license_info.Denied_License !== ''}
                    isInvalid={this.props.license_info.Denied_License === ''}
                    controlId="denied-license"
                >
                    <option value="">Select</option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                </Form.Select>
            </div>
        );
        //#endregion Denied License

        //#region Suspended License
        const suspended_license = (
            <div className="mb-3 d-flex flex-column long-field-title">
                <label
                    style={{
                        wordWrap: 'break-word',
                        alignSelf: 'stretch',
                        whiteSpace: 'normal',
                        paddingBottom: '1rem',
                    }}
                >
                    Has a license, permit or privilege been suspended or revoked?
                </label>
                <Form.Select
                    variant="outline-secondary"
                    value={this.props.license_info.Suspended}
                    name="Suspended"
                    onChange={this.handleApplicationChange}
                    isValid={this.props.license_info.Suspended !== ''}
                    isInvalid={this.props.license_info.Suspended === ''}
                    controlId="suspended-license"
                >
                    <option value="">Select</option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                </Form.Select>
                {this.props.license_info.Suspended === 'Y' ? (
                    <FloatingLabel controlId="reason-for-yes" label="Please explain:" className="mt-3">
                        <Form.Control
                            as="textarea"
                            style={{ height: '100px' }}
                            variant="outline-secondary"
                            value={this.props.license_info.Reason_For_Yes}
                            name="Reason_For_Yes"
                            onChange={this.handleApplicationChange}
                            controlId="reason-for-yes"
                            isValid={this.props.license_info.Reason_For_Yes !== ''}
                            isInvalid={this.props.license_info.Reason_For_Yes === ''}
                        />
                    </FloatingLabel>
                ) : null}
            </div>
        );
        //#endregion Suspended License

        return (
            <div className="d-flex flex-column">
                {denied_license}
                {suspended_license}
                <ApplicationButtonBar
                    multi_button={this.props.multi_button}
                    submitInfo={(prop) => this.submitInfo(prop)}
                    validated={validated}
                />
            </div>
        );
    }
}
