import Button from 'react-bootstrap/Button';

function CustomButton({ ...props }) {
    return (
        <>
            <Button
                variant={props.variant}
                size="med"
                style={{ alignSelf: props.alignment, color: props.text_color }}
                onClick={() => props.button_action()}
                className="text-small btn-op70"
                disabled={props.disabled}
            >
                {props.button_title}
            </Button>
        </>
    );
}

export default CustomButton;
