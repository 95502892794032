import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import header_image from '../media/S_logo.png';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
        };
    }

    logoutApplicant() {
        localStorage.clear();
        window.location.pathname = '/';
    }

    render() {
        const header_icon = (
            <div className="d-flex flex-row">
                <img
                    src={header_image}
                    className="img-fluid rounded"
                    alt="Scotlynn Logo"
                    style={{ height: '2rem', alignSelf: 'center' }}
                />
                <label
                    style={{
                        fontSize: '1.25rem',
                        color: 'white',
                        lineHeight: '1.75rem',
                        fontWeight: '600',
                        alignSelf: 'center',
                        paddingLeft: '1rem',
                    }}
                >
                    Driver Application
                </label>
            </div>
        );

        return (
            <div className="bg-dark d-flex flex-row p-3" style={{ justifyContent: 'space-between' }}>
                {header_icon}
                {this.props.app_id > 0 ? (
                    <Button
                        variant="warning"
                        size="sm"
                        style={{ alignSelf: 'center', color: 'black' }}
                        href="/"
                        className="btn-op70"
                    >
                        Logout
                    </Button>
                ) : null}
            </div>
        );
    }
}
