import React, { useState } from 'react';
import {
    Alert,
    Button,
    Col,
    Container,
    FloatingLabel,
    Form,
    InputGroup,
    OverlayTrigger,
    Row,
    Tooltip,
} from 'react-bootstrap';
import hide_password_icon from '../../media/hide_password.png';
import { ForgotPassword } from './ForgotPassword';

export const ReturnApplication = ({ ...props }) => {
    const [hide_password, setHidePassword] = useState(true);
    const [showForgot, setShowForgot] = useState(false);
    const currentApplication = props.current_application;

    function handleApplicationChange(e) {
        var updatedApplication = currentApplication;
        updatedApplication[e.target.name] = e.target.value;
        props.onupdate(updatedApplication);
    }

    function handleKeyPress(e) {
        if (currentApplication.Email !== '' && currentApplication.Password !== '' && props.login_finished) {
            if (e.key === 'Enter') {
                props.onsave();
            }
        }
    }

    let disable_login = true;
    let disable_login_title = '';

    if (currentApplication.Email === '') {
        disable_login_title = 'Email Address field is empty';
        disable_login = true;
    } else if (currentApplication.Password === '') {
        disable_login_title = 'Password field is empty';
        disable_login = true;
    } else if (!props.login_finished) {
        disable_login_title = '';
        disable_login = true;
    } else {
        disable_login_title = '';
        disable_login = false;
    }

    return (
        <Container fluid="xs" className="px-3">
            <Row className="mb-5 d-flex flex-row justify-content-start w-100">
                <Col className="d-flex flex-row justify-content-start w-100">
                    <Button variant="secondary" className="btn-op70" onClick={() => props.clearApp()}>
                        Go Back
                    </Button>
                </Col>
            </Row>
            <Row className="d-flex flex-row justify-content-center">
                <Col xl={7} lg={7} med={10} sm={12} xs={12}>
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="email-address-input" label="Email Address">
                            <Form.Control
                                controlId="email-address-input"
                                variant="outline-secondary"
                                type="email"
                                maxLength={30}
                                value={currentApplication.Email}
                                name="Email"
                                onChange={handleApplicationChange}
                                onKeyPress={handleKeyPress}
                                disabled={!props.login_finished}
                                isInvalid={
                                    (props.error_message.includes('Email') && props.login_finished) ||
                                    (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(currentApplication.Email) &&
                                        props.login_finished)
                                }
                                isValid={
                                    /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(currentApplication.Email) &&
                                    props.login_finished
                                }
                            />
                        </FloatingLabel>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="d-flex flex-row justify-content-center">
                <Col xl={7} lg={7} med={10} sm={12} xs={12}>
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="password-input" label="Password">
                            <Form.Control
                                controlId="password-input"
                                variant="outline-secondary"
                                type={hide_password ? 'password' : 'text'}
                                maxLength={30}
                                value={currentApplication.Password}
                                name="Password"
                                onChange={handleApplicationChange}
                                onKeyPress={handleKeyPress}
                                disabled={!props.login_finished}
                                isInvalid={
                                    (props.error_message.includes('Password') && props.login_finished) ||
                                    (currentApplication.Password === '' && props.login_finished)
                                }
                                isValid={currentApplication.Password !== '' && props.login_finished}
                            />
                        </FloatingLabel>
                        <span class="input-group-text bg-transparent  border-1" id="basic-addon1">
                            <img
                                src={hide_password_icon}
                                style={{ width: '16px', height: '16px' }}
                                onClick={() => setHidePassword(!hide_password)}
                                alt="Show Password"
                            />
                        </span>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    {!props.login_finished ? (
                        <Button variant="light" type="button" className="btn-op70 px-3 my-3" disabled={true}>
                            Logging in...
                        </Button>
                    ) : (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip hidden={!disable_login} id="report-error-tooltip">
                                    {disable_login_title}
                                </Tooltip>
                            }
                        >
                            <span
                                className="d-flex flex-row mt-3"
                                style={{ justifyContent: 'center', alignSelf: 'center' }}
                            >
                                <Button
                                    variant="success"
                                    size="med"
                                    type="button"
                                    className="btn-op70 px-3 my-3 mx-auto"
                                    onClick={() => props.onsave()}
                                    disabled={disable_login}
                                >
                                    Login
                                </Button>
                            </span>
                        </OverlayTrigger>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.error_message !== '' ? (
                        <Alert variant="danger" show={true}>
                            <Alert.Heading as="h6">Login Failed</Alert.Heading>
                            <p>{props.error_message}</p>
                        </Alert>
                    ) : null}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="link" className="btn-op70" onClick={() => setShowForgot(true)}>
                        Forgot password?
                    </Button>
                    {showForgot ? <ForgotPassword onHide={() => setShowForgot(false)} /> : null}
                </Col>
            </Row>
        </Container>
    );
};
