import { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

function PhoneInput({ ...props }) {
    let c_1,
        c_2,
        c_3 = '';
    if (props.current_phone) {
        const split_phone = props.current_phone.split('-');
        c_1 = split_phone.length >= 1 ? split_phone[0] : '';
        c_2 = split_phone.length >= 2 ? split_phone[1] : '';
        c_3 = split_phone.length >= 3 ? split_phone[2] : '';
    }
    const [p1, setP1] = useState(c_1);
    const [p2, setP2] = useState(c_2);
    const [p3, setP3] = useState(c_3);
    if (!props.current_phone &&
        p1 && p2 && p3) {
        setP1('');
        setP2('');
        setP3('');

    }


    const handleChange = (e) => {
        let s_1 = p1;
        let s_2 = p2;
        let s_3 = p3;
        if (e.target.name === 'p1') {
            if (e.target.value.toString().length <= 3) {
                s_1 = e.target.value;
                setP1(s_1);
            } else {
                s_2 = e.target.value[3];
                setP2(s_2);
                const next_field = document.querySelector(`input[name=p2]`);
                next_field.focus();
            }
        } else if (e.target.name === 'p2') {
            if (e.target.value.toString().length <= 3) {
                s_2 = e.target.value;
                setP2(s_2);
            } else {
                s_3 = e.target.value[3];
                setP3(s_3);
                const next_field = document.querySelector(`input[name=p3]`);
                next_field.focus();
            }
        } else if (e.target.name === 'p3') {
            if (e.target.value.toString().length < 5) {
                s_3 = e.target.value;
                setP3(s_3);
            }
        }
        const new_phone = s_1 + '-' + s_2 + '-' + s_3;
        props.onupdate(new_phone);
    };

    const short_regex = /\b[0-9]{3}\b/;
    const long_regex = /\b[0-9]{4}\b/;

    const phone_form = (
        <InputGroup className="mb-3 opacity70 p-0">
            <div className="d-flex flex-column form-floating form-control justify-content-start">
                <small className="text-start text-muted pb-1">Phone</small>
                <InputGroup>
                    <Form.Control
                        type="number"
                        variant="outline-secondary"
                        value={p1}
                        name="p1"
                        onChange={(e) => handleChange(e)}
                        isValid={short_regex.test(p1)}
                        isInvalid={!short_regex.test(p1)}
                        placeholder="000"
                        max={999}
                        className="text-center"
                    ></Form.Control>
                    <label className="px-1 py-0 align-self-center">-</label>
                    <Form.Control
                        type="number"
                        variant="outline-secondary"
                        value={p2}
                        name="p2"
                        onChange={(e) => handleChange(e)}
                        isValid={short_regex.test(p2)}
                        isInvalid={!short_regex.test(p2)}
                        placeholder="000"
                        max={999}
                        className="text-center"
                    ></Form.Control>
                    <label className="px-1 py-0 align-self-center">-</label>
                    <Form.Control
                        type="number"
                        variant="outline-secondary"
                        value={p3}
                        name="p3"
                        onChange={(e) => handleChange(e)}
                        isValid={long_regex.test(p3)}
                        isInvalid={!long_regex.test(p3)}
                        placeholder="0000"
                        max={9999}
                        className="text-center"
                    ></Form.Control>
                </InputGroup>
            </div>
        </InputGroup>
    );

    return phone_form;
}

export default PhoneInput;
