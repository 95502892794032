import React, { Component } from 'react';
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import regions_map from '../../media/regions_map.png';
import { sendGetFetch } from '../application/FetchFunctions';
import ApplicationButtonBar from '../buttons/ApplicationButtonBar';

export class Experience extends Component {
    constructor(props) {
        super(props);
        const c_width = window.screen.availWidth;
        const c_height = window.screen.availHeight;
        const c_aspect = c_height / c_width;
        console.log(c_aspect);

        const image_width = 600;
        const image_height = 400;

        this.state = {
            validated: false,
            changed: false,
            selected_regions: [],
            image_width: image_width,
            image_height: image_height,
            mapper: {
                name: 'regionsMAP',
                areas: [
                    {
                        name: 'Pacific Northwest',
                        shape: 'poly',
                        coords: [
                            image_width * 0.05,
                            image_height * 0.01,
                            image_width * 0.33,
                            image_height * 0.1,

                            image_width * 0.33,
                            image_height * 0.1,
                            image_width * 0.31,
                            image_height * 0.38,
                            image_width * 0.21,
                            image_height * 0.36,
                            image_width * 0.21,
                            image_height * 0.32,
                            image_width * 0.03,
                            image_height * 0.24,
                        ],
                        preFillColor: 'transparent',
                        fillColor: 'rgba(0,0,0,0.3)',
                    },
                    {
                        name: 'Northern California',
                        shape: 'poly',
                        coords: [
                            image_width * 0.02,
                            image_height * 0.24,

                            image_width * 0.215,
                            image_height * 0.31,
                            image_width * 0.215,
                            image_height * 0.36,
                            image_width * 0.24,
                            image_height * 0.36,
                            image_width * 0.23,
                            image_height * 0.5,
                            image_width * 0.235,
                            image_height * 0.5,
                            image_width * 0.16,
                            image_height * 0.48,
                            image_width * 0.16,
                            image_height * 0.45,
                            image_width * 0.03,
                            image_height * 0.515,
                        ],
                        preFillColor: 'transparent',
                        fillColor: 'rgba(0,0,0,0.3)',
                    },
                    {
                        name: 'Midwest',
                        shape: 'poly',
                        coords: [
                            image_width * 0.33,
                            image_height * 0.09,

                            image_width * 0.6,
                            image_height * 0.12,
                            image_width * 0.62,
                            image_height * 0.33,
                            image_width * 0.61,
                            image_height * 0.335,
                            image_width * 0.58,
                            image_height * 0.335,
                            image_width * 0.59,
                            image_height * 0.335,
                            image_width * 0.53,
                            image_height * 0.55,
                            image_width * 0.34,
                            image_height * 0.52,
                            image_width * 0.34,
                            image_height * 0.38,
                            image_width * 0.32,
                            image_height * 0.38,
                        ],
                        preFillColor: 'transparent',
                        fillColor: 'rgba(0,0,0,0.3)',
                    },
                    {
                        name: 'Ohio River Valley',
                        shape: 'poly',
                        coords: [
                            image_width * 0.59,
                            image_height * 0.35,
                            image_width * 0.655,
                            image_height * 0.32,
                            image_width * 0.665,
                            image_height * 0.42,
                            image_width * 0.68,
                            image_height * 0.4,
                            image_width * 0.67,
                            image_height * 0.46,
                            image_width * 0.67,
                            image_height * 0.485,
                            image_width * 0.65,
                            image_height * 0.485,
                            image_width * 0.64,
                            image_height * 0.5,
                            image_width * 0.635,
                            image_height * 0.515,
                            image_width * 0.635,
                            image_height * 0.525,
                            image_width * 0.62,
                            image_height * 0.565,
                            image_width * 0.52,
                            image_height * 0.59,
                        ],
                        preFillColor: 'transparent',
                        fillColor: 'rgba(0,0,0,0.3)',
                    },
                    {
                        name: 'Northeast',
                        shape: 'poly',
                        coords: [
                            image_width * 0.66,
                            image_height * 0.31,
                            image_width * 0.66,
                            image_height * 0.27,
                            image_width * 0.7,
                            image_height * 0.25,
                            image_width * 0.7,
                            image_height * 0.225,
                            image_width * 0.72,
                            image_height * 0.18,
                            image_width * 0.77,
                            image_height * 0.14,
                            image_width * 0.77,
                            image_height * 0.055,
                            image_width * 0.795,
                            image_height * 0.055,
                            image_width * 0.8,
                            image_height * 0.08,
                            image_width * 0.81,
                            image_height * 0.1,
                            image_width * 0.82,
                            image_height * 0.15,
                            image_width * 0.8,
                            image_height * 0.31,
                        ],
                        preFillColor: 'transparent',
                        fillColor: 'rgba(0,0,0,0.3)',
                    },
                    {
                        name: 'Mid-Atlantic',
                        shape: 'poly',
                        coords: [
                            image_width * 0.75,
                            image_height * 0.31,
                            image_width * 0.75,
                            image_height * 0.41,
                            image_width * 0.75,
                            image_height * 0.51,
                            image_width * 0.72,
                            image_height * 0.6,
                            image_width * 0.7,
                            image_height * 0.58,
                            image_width * 0.67,
                            image_height * 0.56,
                            image_width * 0.63,
                            image_height * 0.57,
                            image_width * 0.63,
                            image_height * 0.54,
                            image_width * 0.65,
                            image_height * 0.52,
                            image_width * 0.67,
                            image_height * 0.5,
                            image_width * 0.67,
                            image_height * 0.47,
                            image_width * 0.69,
                            image_height * 0.39,
                            image_width * 0.66,
                            image_height * 0.41,
                            image_width * 0.66,
                            image_height * 0.31,
                        ],
                        preFillColor: 'transparent',
                        fillColor: 'rgba(0,0,0,0.3)',
                    },
                    {
                        name: 'Southeast',
                        shape: 'poly',
                        coords: [
                            image_width * 0.52,
                            image_height * 0.6,
                            image_width * 0.65,
                            image_height * 0.57,
                            image_width * 0.68,
                            image_height * 0.56,
                            image_width * 0.71,
                            image_height * 0.59,
                            image_width * 0.69,
                            image_height * 0.655,
                            image_width * 0.675,
                            image_height * 0.71,
                            image_width * 0.72,
                            image_height * 0.86,
                            image_width * 0.71,
                            image_height * 0.89,
                            image_width * 0.7,
                            image_height * 0.91,
                            image_width * 0.66,
                            image_height * 0.83,
                            image_width * 0.66,
                            image_height * 0.8,
                            image_width * 0.66,
                            image_height * 0.78,
                            image_width * 0.54,
                            image_height * 0.76,
                            image_width * 0.5,
                            image_height * 0.74,
                        ],
                        preFillColor: 'transparent',
                        fillColor: 'rgba(0,0,0,0.3)',
                    },
                ],
            },
        };
        this.handleApplicationChange = this.handleApplicationChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.submitInfo = this.submitInfo.bind(this);
        this.updateRegions = this.updateRegions.bind(this);
    }

    handleApplicationChange(e) {
        const current_info = this.props.experience;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
        }
        current_info[e.target.name] = e.target.value;
        this.props.onupdate(current_info);
        this.validateForm();
    }

    updateRegions(e) {
        const current_mapper = this.state.mapper;
        const current_info = this.props.experience;
        const current_regions = this.props.experience.Regions;
        if (current_regions.includes(e.target.name)) {
            current_info.Regions = current_regions.filter((s) => s !== e.target.name);
            for (let i = 0; i < current_mapper.areas.length; i++) {
                if (current_mapper.areas[i].name === e.target.name) {
                    current_mapper.areas[i].preFillColor = 'transparent';
                }
            }
            current_info.App_id = this.props.app_id;
        } else {
            current_regions.push(e.target.name);
            current_info.Regions = current_regions;
            for (let i = 0; i < current_mapper.areas.length; i++) {
                if (current_mapper.areas[i].name === e.target.name) {
                    current_mapper.areas[i].preFillColor = 'rgba(0,0,0,0.3)';
                }
            }
            current_info.App_id = this.props.app_id;
        }
        this.setState({ mapper: current_mapper });
        this.props.onupdate(current_info);
        this.validateForm();
    }

    validateForm() {
        const c_experience = this.props.experience;
        let is_valid = false;

        if (c_experience.Cross_US_CA !== '') {
            if (c_experience.Reefer !== '') {
                if (c_experience.Reefer === 'Y') {
                    if (c_experience.Months_Years_Reefer !== '') {
                        is_valid = true;
                    } else {
                        is_valid = false;
                    }
                } else {
                    is_valid = true;
                }
            } else {
                is_valid = false;
            }
        } else {
            is_valid = false;
        }

        this.setState({ validated: is_valid });
    }

    async submitInfo(button) {
        this.props.setMultiButton(button);
        let next_view = 'experience';
        if (button === 'back') {
            next_view = 'license';
        } else {
            next_view = 'accident';
        }
        const v_experience = await sendGetFetch('experience/' + this.props.app_id);

        if (v_experience.Id === 0) {
            this.props.onPost();
        } else {
            if (v_experience !== this.props.experience) {
                this.props.onPut();
            }
        }
        this.props.setMultiButton('');
        this.props.setView(next_view);
    }

    render() {
        const { validated } = this.state;

        const list_regions = [
            'Pacific Northwest',
            'Northern California',
            'Southern California',
            'Arizona',
            'Midwest',
            'Texas',
            'Ohio River Valley',
            'Southeast',
            'Mid-Atlantic',
            'Northeast',
        ];

        //#region Border Crossing
        const crossing_border = (
            <div className="mb-3 d-flex flex-column long-field-title">
                <label
                    style={{
                        wordWrap: 'break-word',
                        alignSelf: 'stretch',
                        whiteSpace: 'normal',
                        paddingBottom: '1rem',
                    }}
                >
                    Do you have experience crossing to the US from Canada in a commercial vehicle?
                </label>

                <Form.Select
                    variant="outline-secondary"
                    value={this.props.experience.Cross_US_CA}
                    name="Cross_US_CA"
                    onChange={this.handleApplicationChange}
                    isValid={this.props.experience.Cross_US_CA !== ''}
                    isInvalid={this.props.experience.Cross_US_CA === ''}
                >
                    <option value="">Select</option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                </Form.Select>
            </div>
        );
        //#endregion Border Crossing

        //#region Reefer Experience
        const reefer_experience = (
            <div className="mb-3 d-flex flex-column long-field-title">
                <label
                    style={{
                        wordWrap: 'break-word',
                        alignSelf: 'stretch',
                        whiteSpace: 'normal',
                        paddingBottom: '1rem',
                    }}
                >
                    Do you have Reefer experience?
                </label>

                <Form.Select
                    className="mb-3"
                    variant="outline-secondary"
                    value={this.props.experience.Reefer}
                    name="Reefer"
                    onChange={this.handleApplicationChange}
                    isValid={this.props.experience.Reefer !== ''}
                    isInvalid={this.props.experience.Reefer === ''}
                >
                    <option value="">Select</option>
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                </Form.Select>
                {this.props.experience.Reefer === 'Y' ? (
                    <FloatingLabel controlId="years-months-reefer" label="How many months/years of reefer experience?">
                        <Form.Select
                            variant="outline-secondary"
                            value={this.props.experience.Months_Years_Reefer}
                            name="Months_Years_Reefer"
                            onChange={this.handleApplicationChange}
                            isValid={this.props.experience.Months_Years_Reefer !== ''}
                            isInvalid={this.props.experience.Months_Years_Reefer === ''}
                            controlId="years-months-reefer"
                        >
                            <option value="0-6 months">0-6 months</option>
                            <option value="6-12 months">6-12 months</option>
                            <option value="1-3 years">1-3 years</option>
                            <option value="3+ years">3+ years</option>
                        </Form.Select>
                    </FloatingLabel>
                ) : null}
            </div>
        );
        //#endregion Reefer Experience

        //#region Other Equipment
        const other_equipment = (
            <div className="mb-3 d-flex flex-column long-field-title">
                <label
                    style={{
                        wordWrap: 'break-word',
                        alignSelf: 'stretch',
                        whiteSpace: 'normal',
                        paddingBottom: '1rem',
                    }}
                >
                    Other type of equipment you have experience with (EX: Van, Tank Flat, Straight Truck etc)
                </label>

                <Form.Control
                    as="textarea"
                    className="mt-3"
                    style={{ height: '100px' }}
                    variant="outline-secondary"
                    value={this.props.experience.Other_Equipment}
                    name="Other_Equipment"
                    onChange={this.handleApplicationChange}
                ></Form.Control>
            </div>
        );
        //#endregion Other Equipment

        //#region Auto Manual
        const auto_manual = (
            <div className="mb-3 d-flex flex-column long-field-title">
                <label
                    style={{
                        wordWrap: 'break-word',
                        alignSelf: 'stretch',
                        whiteSpace: 'normal',
                        paddingBottom: '1rem',
                    }}
                >
                    Do you drive Automatic or Manual Transmission?
                </label>

                <Form.Select
                    variant="outline-secondary"
                    value={this.props.experience.Auto_Manual}
                    name="Auto_Manual"
                    onChange={this.handleApplicationChange}
                    isValid={this.props.experience.Auto_Manual !== ''}
                    isInvalid={this.props.experience.Auto_Manual === ''}
                >
                    <option value="">Select</option>
                    <option value="Automatic">Automatic</option>
                    <option value="Manual">Manual</option>
                    <option value="Both">Both</option>
                </Form.Select>
            </div>
        );
        //#endregion Auto Manual

        //#region Regions

        const regions = this.props.experience ? (
            <div className="mb-3 d-flex flex-column long-field-title">
                <label
                    style={{
                        wordWrap: 'break-word',
                        alignSelf: 'stretch',
                        whiteSpace: 'normal',
                        paddingBottom: '1rem',
                    }}
                >
                    US Regions you have operated in
                </label>
                <Container>
                    <Row>
                        <Col xs={12} med={12} lg={9}>
                            <img src={regions_map} style={{ width: '100%' }} alt="" />
                        </Col>
                        <Col xs={12} lg={3}>
                            {list_regions.map((d, index) => (
                                <Form.Check
                                    key={index}
                                    name={d}
                                    type="switch"
                                    checked={this.props.experience.Regions.includes(d)}
                                    label={d}
                                    onChange={this.updateRegions}
                                />
                            ))}
                        </Col>
                    </Row>
                </Container>
            </div>
        ) : null;
        //#endregion Regions

        return (
            <div className="d-flex flex-column">
                {auto_manual}
                {crossing_border}
                {reefer_experience}
                {other_equipment}
                {regions}
                <ApplicationButtonBar
                    multi_button={this.props.multi_button}
                    submitInfo={(prop) => this.submitInfo(prop)}
                    validated={validated}
                />
            </div>
        );
    }
}
