import { AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate } from '@azure/msal-react';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import { loginRequest } from '../authConfig';
import header_image from '../media/S_logo.png';
import { Admin } from './Admin';
import { AdminLogin } from './AdminLogin';
import { Applicant } from './Applicant';
import { Home } from './Home';
import { Recruiter } from './Recruiter';

export class DesktopLayout extends Component {
    static displayName = DesktopLayout.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            applicant_status: -1,
        };
    }

    handleLogin = async () => {
        try {
            const inst = this.context.instance;

            await inst.loginRedirect(loginRequest).then((res) => console.log(res));
        } catch (e) {
            console.log(e);
        }
    };

    handleLogout = async () => {
        try {
            const inst = this.context.instance;
            await inst.logoutRedirect(loginRequest).then((res) => console.log(res));
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        let current_role = '';
        let current_email = '';
        if (this.context.instance.initialized) {
            if (this.context.accounts.length > 0) {
                if (this.context.accounts[0].idTokenClaims.roles.length > 0) {
                    current_email = this.context.accounts[0].username;
                    current_role = this.context.accounts[0].idTokenClaims.roles[0];
                }
            }
        }

        const AdminRoutes = [
            {
                index: true,
                element: <Home />,
            },
            {
                path: '/admin',
                element: <Admin />,
            },
        ];
        const RecruiterRoutes = [
            {
                index: true,
                element: <Home />,
            },
            {
                path: '/admin',
                element: <Recruiter recruiter_email={current_email} />,
            },
        ];
        const ApplicantRoutes = [
            {
                index: true,
                element: <Home />,
            },
            {
                path: '/apply',
                element: <Applicant />,
            },
            {
                path: '/ad-login',
                element: <AdminLogin />,
            },
        ];

        const show_sign_in =
            window.location.pathname === '/apply' ? null : (
                <div className="bg-dark d-flex flex-row p-3" style={{ justifyContent: 'space-between' }}>
                    <div className="d-flex flex-row">
                        <img
                            src={header_image}
                            className="img-fluid rounded"
                            alt="Scotlynn Logo"
                            style={{ height: '2rem', alignSelf: 'center' }}
                        />
                        <label
                            style={{
                                fontSize: '1.2rem',
                                color: 'white',
                                fontWeight: '600',
                                alignSelf: 'center',
                                paddingLeft: '1rem',
                            }}
                        >
                            Driver Application
                        </label>
                    </div>
                </div>
            );
        return (
            <div>
                <AuthenticatedTemplate>
                    <div className="bg-dark d-flex flex-row p-3" style={{ justifyContent: 'space-between' }}>
                        <div className="d-flex flex-row">
                            <img
                                src={header_image}
                                className="img-fluid rounded"
                                alt="Scotlynn Logo"
                                style={{ height: '2rem', alignSelf: 'center' }}
                            />
                            <label
                                style={{
                                    fontSize: '1.2rem',
                                    color: 'white',
                                    fontWeight: '600',
                                    alignSelf: 'center',
                                    paddingLeft: '1rem',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {current_role} Portal
                            </label>
                        </div>
                        <Button
                            variant="warning"
                            size="sm"
                            style={{ alignSelf: 'center', color: 'black' }}
                            onClick={() => this.handleLogout()}
                            className="btn-op70"
                        >
                            Logout
                        </Button>
                    </div>

                    {current_role === 'admin' ? (
                        <Routes>
                            {AdminRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                        </Routes>
                    ) : null}
                    {current_role === 'recruiter' ? (
                        <Routes>
                            {RecruiterRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                        </Routes>
                    ) : null}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    {show_sign_in}

                    <Routes>
                        {ApplicantRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </UnauthenticatedTemplate>
            </div>
        );
    }
}

/*
            
                    
            */
