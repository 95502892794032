import React, { Component } from 'react';
import { Accordion, Button, FloatingLabel, Form, InputGroup, Stack } from 'react-bootstrap';
import { sendDeleteFetch, sendPostFetch, sendPutFetch } from '../application/FetchFunctions';
import { initial_accident } from '../dtos/InitialValues';

export class Accidents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_accident: initial_accident,
            edit_accident: initial_accident,
            new_validated: false,
            edit_validated: false,
            show_add_accident: false,
            been_in_accident: this.props.current_application.Has_Accidents
                ? this.props.current_application.Has_Accidents
                : '',
        };
        this.handleNewAccident = this.handleNewAccident.bind(this);
        this.handleEditAccident = this.handleEditAccident.bind(this);
        this.addAccident = this.addAccident.bind(this);
        this.editAccident = this.editAccident.bind(this);
        this.deleteAccident = this.deleteAccident.bind(this);
        this.validateNewAccident = this.validateNewAccident.bind(this);
        this.validateEditAccident = this.validateEditAccident.bind(this);
        this.handleBeenInAccident = this.handleBeenInAccident.bind(this);
        this.setHasAccidents = this.setHasAccidents.bind(this);
    }

    async updateHasAccidents() {
        const current_app = this.props.current_application;
        current_app.Has_Accidents = this.state.been_in_accident;
        const putResponse = await fetch('driverapp/UpdateAccidents', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(current_app),
        });
        const data = await putResponse.json();
        console.log(data);
        this.props.update_application(current_app);
    }

    handleBeenInAccident(e) {
        if (e.target.value === 'Y') {
            this.setState({ been_in_accident: 'Y', show_add_accident: true });
        } else {
            this.setState({ been_in_accident: 'N', show_add_accident: false });
        }
    }

    //#region New Accident
    handleNewAccident(e) {
        const current_info = this.state.new_accident;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
        }
        current_info[e.target.name] = e.target.value;
        this.setState({ new_accident: current_info });
        this.validateNewAccident();
    }

    validateNewAccident() {
        const c_accident = this.state.new_accident;
        let is_valid = false;
        if (c_accident.Has_Accidents !== '') {
            if (c_accident.Accident_Date !== '') {
                if (c_accident.Fatalities !== '') {
                    if (c_accident.Preventable !== '') {
                        if (c_accident.Injuries !== '') {
                            is_valid = true;
                        } else {
                            is_valid = false;
                        }
                    } else {
                        is_valid = false;
                    }
                } else {
                    is_valid = false;
                }
            } else {
                is_valid = false;
            }
        } else {
            is_valid = false;
        }

        this.setState({ new_validated: is_valid });
    }

    async addAccident() {
        this.props.setMultiButton('add accident');
        await sendPostFetch(JSON.stringify(this.state.new_accident), 'accident').then((res) => {
            if (res === 'open issue') {
                this.props.setIssue('Could not add Accident.');
            } else {
                const accident_list = this.props.accidents;
                accident_list.push(res);
                this.props.updateAccidents(accident_list);
                this.props.setMultiButton('');
                this.props.onStatusUpdate();
                this.updateHasAccidents();
                this.setState({
                    new_accident: {
                        App_id: 0,
                        Has_Accidents: '',
                        Accident_Date: '',
                        Fatalities: '',
                        Preventable: '',
                        Injuries: '',
                        Id: 0,
                    },
                    new_validated: false,
                    show_add_accident: false,
                });
            }
        });
    }
    //#endregion New Accident

    //#region Edit Accident
    handleEditAccident(e) {
        const current_info = this.state.edit_accident;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
        }
        current_info[e.target.name] = e.target.value;
        this.setState({ edit_accident: current_info });
        this.validateEditAccident();
    }

    validateEditAccident() {
        const c_accident = this.state.edit_accident;
        let is_valid = false;
        if (c_accident.Has_Accidents !== '') {
            if (c_accident.Accident_Date !== '') {
                if (c_accident.Fatalities !== '') {
                    if (c_accident.Preventable !== '') {
                        if (c_accident.Injuries !== '') {
                            is_valid = true;
                        } else {
                            is_valid = false;
                        }
                    } else {
                        is_valid = false;
                    }
                } else {
                    is_valid = false;
                }
            } else {
                is_valid = false;
            }
        } else {
            is_valid = false;
        }

        this.setState({ edit_validated: is_valid });
    }

    async editAccident() {
        this.props.setMultiButton('edit accident');
        await sendPutFetch(JSON.stringify(this.state.edit_accident), 'accident').then((res) => {
            if (res === 'open issue') {
                this.props.setIssue('Could not update Accident.');
                this.props.setMultiButton('');
            } else {
                const accident_list = this.props.accidents.filter((s) => s.Id !== this.state.edit_accident.Id);
                accident_list.push(this.state.edit_accident);
                this.props.updateAccidents(accident_list);
                this.props.setMultiButton('');
            }
        });
        this.setState({
            edit_accident: {
                App_id: 0,
                Has_Accidents: '',
                Accident_Date: '',
                Fatalities: '',
                Preventable: '',
                Injuries: '',
                Id: 0,
            },
            edit_validated: false,
        });
    }
    //#endregion Edit Accident

    async setHasAccidents(dir) {
        await this.updateHasAccidents();
        if (dir === 'back') {
            this.props.onBack();
        } else {
            this.props.onNext();
        }
    }

    async deleteAccident(d) {
        this.props.setMultiButton('delete accident');
        if (window.confirm('Are you sure you want to delete this Accident?')) {
            await sendDeleteFetch(JSON.stringify(d), 'accident').then((res) => {
                const accident_list = this.props.accidents.filter((s) => s.Id !== d.Id);
                this.props.updateAccidents(accident_list);
                this.props.setMultiButton('');
            });
        } else {
            this.props.setMultiButton('');
        }
    }

    render() {
        const { new_accident, edit_accident, new_validated, edit_validated, been_in_accident } = this.state;

        //#region Edit Accident Form
        const edit_accident_form = (
            <div className="d-flex flex-column">
                <h6>Edit Accident</h6>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="has-accidents" label="Was this accident reported?">
                        <Form.Select
                            variant="outline-secondary"
                            value={edit_accident.Has_Accidents}
                            name="Has_Accidents"
                            onChange={this.handleEditAccident}
                            isValid={edit_accident.Has_Accidents !== ''}
                            isInvalid={edit_accident.Has_Accidents === ''}
                            controlId="has-accidents"
                        >
                            <option value="">Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="accident-date" label="Accident Date">
                        <Form.Control
                            type="date"
                            variant="outline-secondary"
                            value={edit_accident.Accident_Date}
                            name="Accident_Date"
                            onChange={this.handleEditAccident}
                            isValid={edit_accident.Accident_Date !== ''}
                            isInvalid={edit_accident.Accident_Date === ''}
                            controlId="accident-date"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="fatalities" label="Were there any fatalities?">
                        <Form.Select
                            variant="outline-secondary"
                            value={edit_accident.Fatalities}
                            name="Fatalities"
                            onChange={this.handleEditAccident}
                            isValid={edit_accident.Fatalities !== ''}
                            isInvalid={edit_accident.Fatalities === ''}
                            controlId="fatalities"
                        >
                            <option value="">Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="injuries" label="Were there any injuries?">
                        <Form.Select
                            variant="outline-secondary"
                            value={edit_accident.Injuries}
                            name="Injuries"
                            onChange={this.handleEditAccident}
                            isValid={edit_accident.Injuries !== ''}
                            isInvalid={edit_accident.Injuries === ''}
                            controlId="injuries"
                        >
                            <option value="">Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="preventable" label="Was it preventable?">
                        <Form.Select
                            variant="outline-secondary"
                            value={edit_accident.Preventable}
                            name="Preventable"
                            onChange={this.handleEditAccident}
                            isValid={edit_accident.Preventable !== ''}
                            isInvalid={edit_accident.Preventable === ''}
                            controlId="preventable"
                        >
                            <option value="">Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 d-flex flex-row" style={{ justifyContent: 'center' }}>
                    <Button
                        variant={this.props.multi_button === 'edit accident' ? 'light' : 'success'}
                        onClick={() => this.editAccident()}
                        className="btn-op70 submit-buttons mt-2"
                        disabled={this.props.multi_button === 'edit accident' || !edit_validated}
                    >
                        {this.props.multi_button === 'edit accident' ? 'Saving...' : 'Save'}
                    </Button>

                    <Button
                        variant="danger"
                        onClick={() => this.setState({ edit_accident: initial_accident })}
                        className="btn-op70 submit-buttons mt-2"
                    >
                        Cancel
                    </Button>
                </InputGroup>
            </div>
        );
        //#endregion New Accident Form

        //#region Accident Accordion
        const accident_accordion = (
            <div className="d-block mb-5">
                <Accordion defaultActiveKey="0">
                    {this.props.accidents
                        .sort((a, b) => new Date(a.Accident_Date) - new Date(b.Accident_Date))
                        .map((d, index) => (
                            <Accordion.Item eventKey={d.Id} key={d.Id}>
                                <Accordion.Header as="h6" className="text-color">
                                    {'Accident Date: ' + d.Accident_Date}
                                </Accordion.Header>
                                {edit_accident.Id === d.Id ? (
                                    <Accordion.Body>{edit_accident_form}</Accordion.Body>
                                ) : (
                                    <Accordion.Body
                                        className="d-flex flex-column text-color"
                                        style={{ textAlign: 'left' }}
                                    >
                                        <label className="p-2">
                                            <strong>Accident was reported:</strong>
                                            <bdi className="ms-2">{d.Has_Accidents}</bdi>
                                        </label>
                                        <label className="p-2">
                                            <strong>Fatalities:</strong>
                                            <bdi className="ms-2">{d.Fatalities === 'N' ? 'No' : 'Yes'}</bdi>
                                        </label>
                                        <label className="p-2">
                                            <strong>Preventable:</strong>
                                            <bdi className="ms-2">{d.Preventable === 'N' ? 'No' : 'Yes'}</bdi>
                                        </label>
                                        <label className="p-2">
                                            <strong>Injuries:</strong>
                                            <bdi className="ms-2">{d.Injuries === 'N' ? 'No' : 'Yes'}</bdi>
                                        </label>
                                        <div className="d-flex flex-row mt-3">
                                            <Button
                                                variant={
                                                    this.props.multi_button === 'delete accident' ? 'light' : 'danger'
                                                }
                                                style={{ width: '80px' }}
                                                onClick={() => this.deleteAccident(d)}
                                                className="btn-op70"
                                                size="sm"
                                                disabled={this.props.multi_button === 'delete accident'}
                                            >
                                                {this.props.multi_button === 'delete accident'
                                                    ? 'Deleting...'
                                                    : 'Delete'}
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                className="btn-op70 ms-1"
                                                style={{ width: '80px' }}
                                                onClick={() => this.setState({ edit_accident: d })}
                                                size="sm"
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    </Accordion.Body>
                                )}
                            </Accordion.Item>
                        ))}
                </Accordion>
            </div>
        );
        //#endregion Accident Accordion

        //#region New Accident Form
        const new_accident_form = (
            <div className="d-block">
                <h6>Add Accident</h6>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="has-accidents" label="Was this accident reported?">
                        <Form.Select
                            variant="outline-secondary"
                            value={new_accident.Has_Accidents}
                            name="Has_Accidents"
                            onChange={this.handleNewAccident}
                            isValid={new_accident.Has_Accidents !== ''}
                            isInvalid={new_accident.Has_Accidents === ''}
                            controlId="has-accidents"
                        >
                            <option value="">Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="accident-date" label="Accident Date">
                        <Form.Control
                            type="date"
                            variant="outline-secondary"
                            value={new_accident.Accident_Date}
                            name="Accident_Date"
                            onChange={this.handleNewAccident}
                            isValid={new_accident.Accident_Date !== ''}
                            isInvalid={new_accident.Accident_Date === ''}
                            controlId="accident-date"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="fatalities" label="Were there any fatalities?">
                        <Form.Select
                            variant="outline-secondary"
                            value={new_accident.Fatalities}
                            name="Fatalities"
                            onChange={this.handleNewAccident}
                            isValid={new_accident.Fatalities !== ''}
                            isInvalid={new_accident.Fatalities === ''}
                            controlId="fatalities"
                        >
                            <option value="">Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="injuries" label="Were there any injuries?">
                        <Form.Select
                            variant="outline-secondary"
                            value={new_accident.Injuries}
                            name="Injuries"
                            onChange={this.handleNewAccident}
                            isValid={new_accident.Injuries !== ''}
                            isInvalid={new_accident.Injuries === ''}
                            controlId="injuries"
                        >
                            <option value="">Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="preventable" label="Was it preventable?">
                        <Form.Select
                            variant="outline-secondary"
                            value={new_accident.Preventable}
                            name="Preventable"
                            onChange={this.handleNewAccident}
                            isValid={new_accident.Preventable !== ''}
                            isInvalid={new_accident.Preventable === ''}
                            controlId="preventable"
                        >
                            <option value="">Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>

                <Button
                    variant={this.props.multi_button === 'add accident' ? 'light' : 'success'}
                    onClick={() => this.addAccident()}
                    className="btn-op70 mt-2"
                    disabled={this.props.multi_button === 'add accident' || !new_validated}
                >
                    {this.props.multi_button === 'add accident' ? 'Adding...' : 'Add Accident'}
                </Button>
            </div>
        );
        //#endregion New Accident Form

        return (
            <div className="d-flex flex-column">
                {accident_accordion}
                <InputGroup className="mb-3 opacity70 w-75 align-self-center">
                    <FloatingLabel label="Have you been in an accident?">
                        <Form.Select
                            variant="outline-secondary"
                            value={been_in_accident}
                            name="been_in_accident"
                            onChange={this.handleBeenInAccident}
                            isValid={been_in_accident !== ''}
                            isInvalid={been_in_accident === ''}
                        >
                            <option value="">Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>

                <div className="d-flex flex-column">
                    {been_in_accident === 'Y' ? new_accident_form : null}

                    <Stack direction="horizontal" gap={3} className="mt-5" style={{ alignSelf: 'center' }} fluid="xs">
                        <Button
                            variant="outline-secondary"
                            onClick={() => this.setHasAccidents('back')}
                            className="btn-op70 submit-buttons"
                        >
                            Back
                        </Button>
                        <Button
                            variant={this.props.multi_button === 'next' ? 'light' : 'secondary'}
                            onClick={() => this.setHasAccidents('next')}
                            className="btn-op70 submit-buttons"
                        >
                            Next
                        </Button>
                    </Stack>
                </div>
            </div>
        );
    }
}
