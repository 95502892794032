import { differenceInMonths } from 'date-fns';
import React, { Component } from 'react';
import { Accordion, Alert, Button, Col, Container, FloatingLabel, Form, InputGroup, Row, Stack } from 'react-bootstrap';
import { sendDeleteFetch, sendPostFetch, sendPutFetch } from '../application/FetchFunctions';
import { initial_employer, initial_gap } from '../dtos/InitialValues';
import PhoneInput from '../inputs/PhoneInput';
export class Employers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_employer: initial_employer(this.props.app_id),
            employer_validated: false,
            new_gap: initial_gap(this.props.app_id),
            gap_validated: false,
            edit_gap: initial_gap(this.props.app_id),
            edit_gap_validated: false,
            edit_employer: initial_employer(this.props.app_id),
            edit_employer_validated: false,
            not_10_years: '',
            show_add_employer: false,
            show_add_gap: false,
            employment_confirmed: this.props.current_application
                ? this.props.current_application.Employment_Completed
                : '',
            display_gap_options: true,
        };
        this.validateEmployer = this.validateEmployer.bind(this);
        this.handleCurrentEmployerCheck = this.handleCurrentEmployerCheck.bind(this);
        this.validateGap = this.validateGap.bind(this);
        this.handleNewEmploymentChange = this.handleNewEmploymentChange.bind(this);
        this.handleNewGap = this.handleNewGap.bind(this);
        this.addEmployer = this.addEmployer.bind(this);
        this.addGap = this.addGap.bind(this);
        this.handleEditEmploymentChange = this.handleEditEmploymentChange.bind(this);
        this.validateEditEmployer = this.validateEditEmployer.bind(this);
        this.editEmployer = this.editEmployer.bind(this);
        this.handleEditCurrentEmployerCheck = this.handleEditCurrentEmployerCheck.bind(this);
        this.validateEditGap = this.validateEditGap.bind(this);
        this.handleEditGap = this.handleEditGap.bind(this);
        this.editGap = this.editGap.bind(this);
        this.deleteGap = this.deleteGap.bind(this);
        this.deleteEmployer = this.deleteEmployer.bind(this);
        this.updateEditPhone = this.updateEditPhone.bind(this);
        this.updateNewPhone = this.updateNewPhone.bind(this);
        this.setEmploymentCheck = this.setEmploymentCheck.bind(this);
    }

    componentDidUpdate() {
        this.validateEmployer();
        this.validateEditEmployer();
    }

    async updateEmploymentCompleted(completed) {
        const current_app = this.props.current_application;
        current_app.Employment_Completed = completed;
        const putResponse = await fetch('driverapp/UpdateEmployment', {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(current_app),
        });
        putResponse.json();
        this.props.update_application(current_app);
    }

    //#region New Employer

    updateNewPhone(phone) {
        const c_employer = this.state.new_employer;
        c_employer.Phone = phone;
        this.setState({ new_employer: c_employer });
    }
    handleNewEmploymentChange(e) {
        const current_info = this.state.new_employer;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
        }
        current_info[e.target.name] = e.target.value;
        this.setState({ new_employer: current_info });
        this.validateEmployer();
    }
    handleCurrentEmployerCheck(e) {
        const currentInfo = this.state.new_employer;
        if (e.target.value === 'Y') {
            currentInfo.Reason_For_Leaving = '';
            currentInfo.Terminate_Date = '';
            currentInfo.Current_Employer = 'Y';
        } else {
            currentInfo.Current_Employer = 'N';
        }
        this.setState({ new_employer: currentInfo });
        this.validateEmployer();
    }

    async addEmployer() {
        this.props.setMultiButton('add employer');
        await sendPostFetch(JSON.stringify(this.state.new_employer), 'employers').then((res) => {
            if (res === 'open issue') {
                this.props.setIssue('Employer could not be added.');
                this.props.setMultiButton('');
            } else {
                try {
                    const employers_list = this.props.employers;
                    employers_list.push(res);
                    const current_employer_list = employers_list.filter((s) => s.Current_Employer === 'Y');
                    let past_employer_list = employers_list.filter((s) => s.Current_Employer !== 'Y');
                    past_employer_list = past_employer_list.sort(
                        (a, b) => new Date(a.Terminate_Date) - new Date(b.Terminate_Date),
                    );
                    const return_list = current_employer_list.concat(past_employer_list);

                    this.props.updateEmployerList(return_list);
                    this.updateEmploymentCompleted('Y');
                    this.props.setMultiButton('');
                    this.setState({
                        new_employer: initial_employer(this.props.app_id),
                        employer_validated: false,
                        employment_confirmed: 'Y',
                    });
                    this.props.onStatusUpdate();
                } catch (error) {
                    this.props.setMultiButton('');
                    this.setState({
                        new_employer: {
                            App_id: 0,
                            Employer_Name: '',
                            Employer_Address: '',
                            Contact_Name: '',
                            Phone: '',
                            Position: '',
                            Hire_Date: '',
                            Terminate_Date: '',
                            Reason_For_Leaving: '',
                            Current_Employer: '',
                            Id: 0,
                        },
                        employer_validated: false,
                        new_p_1: '',
                        new_p_2: '',
                        new_p_3: '',
                        not_10_years: '',
                        show_add_employer: false,
                    });
                }
            }
        });
    }

    validateEmployer() {
        const v_employer = this.state.new_employer;
        const phone_regex = /\b[0-9]{3}\b[-]{1}\b[0-9]{3}\b[-]{1}\b[0-9]{4}\b/;
        let is_valid = false;
        if (
            v_employer.Employer_Name &&
            v_employer.Employer_Address &&
            v_employer.Contact_Name &&
            phone_regex.test(v_employer.Phone) &&
            v_employer.Position &&
            v_employer.Hire_Date &&
            v_employer.Current_Employer
        ) {
            if (v_employer.Current_Employer === 'N') {
                if (v_employer.Reason_For_Leaving && v_employer.Terminate_Date) {
                    is_valid = true;
                } else {
                    is_valid = false;
                }
            } else {
                is_valid = true;
            }
        }
        if (is_valid !== this.state.employer_validated) {
            this.setState({ employer_validated: is_valid });
        }
    }
    //#endregion New Employer

    //#region New Gap
    handleNewGap(e) {
        const current_gap = this.state.new_gap;
        if (current_gap.App_id === 0) {
            current_gap.App_id = this.props.app_id;
        }
        current_gap[e.target.name] = e.target.value;
        this.setState({ new_gap: current_gap });
        this.validateGap();
    }

    validateGap() {
        const v_gap = this.state.new_gap;
        let is_valid = false;
        if (v_gap.Start_Date !== '') {
            if (v_gap.End_Date !== '') {
                if (v_gap.Reason !== '') {
                    is_valid = true;
                } else {
                    is_valid = false;
                }
            } else {
                is_valid = false;
            }
        } else {
            is_valid = false;
        }

        this.setState({ gap_validated: is_valid });
    }

    async addGap() {
        this.props.setMultiButton('add gap');
        await sendPostFetch(JSON.stringify(this.state.new_gap), 'employmentgap').then((res) => {
            if (res === 'open issue') {
                this.props.setIssue('Could not add Employment Gap');
            } else {
                const gap_list = this.props.employment_gaps;
                gap_list.push(res);
                this.props.updateGapList(gap_list);
                this.setState({
                    new_gap: {
                        App_id: 0,
                        Start_Date: '',
                        End_Date: '',
                        Reason: '',
                        Id: 0,
                    },
                    gap_validated: false,
                    show_add_gap: false,
                });
                this.props.setMultiButton('');
            }
        });
    }
    //#endregion New Gap

    //#region Edit Employer
    updateEditPhone(phone) {
        const c_employer = this.state.edit_employer;
        c_employer.Phone = phone;
        this.setState({ edit_employer: c_employer });
    }

    handleEditEmploymentChange(e) {
        const current_info = this.state.edit_employer;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
        }
        current_info[e.target.name] = e.target.value;
        this.setState({ edit_employer: current_info });
        this.validateEditEmployer();
    }

    handleEditCurrentEmployerCheck(e) {
        const currentInfo = this.state.edit_employer;
        if (e.target.value === 'Y') {
            currentInfo.Reason_For_Leaving = '';
            currentInfo.Terminate_Date = '';
            currentInfo.Current_Employer = 'Y';
        } else {
            currentInfo.Current_Employer = 'N';
        }
        this.validateEditEmployer();
    }

    validateEditEmployer() {
        const v_employer = this.state.edit_employer;
        const phone_regex = /\b[0-9]{3}\b[-]{1}\b[0-9]{3}\b[-]{1}\b[0-9]{4}\b/;
        let is_valid = false;
        if (
            v_employer.Employer_Name &&
            v_employer.Employer_Address &&
            v_employer.Contact_Name &&
            phone_regex.test(v_employer.Phone) &&
            v_employer.Position &&
            v_employer.Hire_Date &&
            v_employer.Current_Employer
        ) {
            if (v_employer.Current_Employer === 'N') {
                if (v_employer.Reason_For_Leaving && v_employer.Terminate_Date) {
                    is_valid = true;
                } else {
                    is_valid = false;
                }
            } else {
                is_valid = true;
            }
        }
        if (is_valid !== this.state.edit_employer) {
            this.setState({ edit_employer: is_valid });
        }
    }

    async editEmployer() {
        this.props.setMultiButton('edit employer');
        await sendPutFetch(JSON.stringify(this.state.edit_employer), 'employers').then((res) => {
            if (res === 'open issue') {
                this.props.setIssue('Employer could not be updated.');
                this.props.setMultiButton('');
            } else {
                const employers_list = this.props.employers.filter((s) => s.Id !== this.state.edit_employer.Id);
                employers_list.push(this.state.edit_employer);
                const current_employer_list = employers_list.filter((s) => s.Current_Employer === 'Y');
                let past_employer_list = employers_list.filter((s) => s.Current_Employer !== 'Y');
                past_employer_list = past_employer_list.sort(
                    (a, b) => new Date(a.Terminate_Date) - new Date(b.Terminate_Date),
                );
                const return_list = current_employer_list.concat(past_employer_list);

                this.props.updateEmployerList(return_list);
                this.props.setMultiButton('');
                this.setState({
                    edit_employer: initial_employer(this.props.app_id),
                    edit_employer_validated: false,
                    edit_p_1: '',
                    edit_p_2: '',
                    edit_p_3: '',
                });
            }
        });
    }
    //#endregion Edit Employer

    //#region Edit Gap
    handleEditGap(e) {
        const current_gap = this.state.edit_gap;
        if (current_gap.App_id === 0) {
            current_gap.App_id = this.props.app_id;
        }
        current_gap[e.target.name] = e.target.value;
        this.setState({ edit_gap: current_gap });
        this.validateEditGap();
    }

    validateEditGap() {
        const v_gap = this.state.edit_gap;
        let is_valid = false;
        if (v_gap.Start_Date !== '') {
            if (v_gap.End_Date !== '') {
                if (v_gap.Reason !== '') {
                    is_valid = true;
                } else {
                    is_valid = false;
                }
            } else {
                is_valid = false;
            }
        } else {
            is_valid = false;
        }

        this.setState({ edit_gap_validated: is_valid });
    }

    async editGap() {
        this.props.setMultiButton('edit gap');
        await sendPutFetch(JSON.stringify(this.state.edit_gap), 'employmentgap').then((res) => {
            if (res === 'open issue') {
                this.props.setIssue('Could not update Employment Gap');
            } else {
                const gap_list = this.props.employment_gaps.filter((s) => s.Id !== this.state.edit_gap.Id);
                gap_list.push(this.state.new_gap);
                this.props.updateGapList(gap_list);
                this.setState({
                    edit_gap: {
                        App_id: 0,
                        Start_Date: '',
                        End_Date: '',
                        Reason: '',
                        Id: 0,
                    },
                    edit_gap_validated: false,
                });
                this.props.setMultiButton('');
            }
        });
    }
    //#endregion Edit Gap

    async deleteGap(d) {
        this.props.setMultiButton('delete gap');
        if (window.confirm('Are you sure you want to delete this Period of Non-Employment?')) {
            await sendDeleteFetch(JSON.stringify(d), 'employmentgap').then((res) => {
                const current_gaps = this.props.employment_gaps.filter((s) => s.Id !== d.Id);
                this.props.updateGapList(current_gaps);
                this.props.setMultiButton('');
            });
        } else {
            this.props.setMultiButton('');
        }
    }

    async deleteEmployer(d) {
        this.props.setMultiButton('delete employer');
        if (window.confirm('Are you sure you want to delete this Employment History?')) {
            await sendDeleteFetch(JSON.stringify(d), 'employers').then((res) => {
                if (this.props.employers.length === 1) {
                    this.updateEmploymentCompleted('N');
                    this.setState({ employment_confirmed: 'N' });
                }
                const current_employers = this.props.employers.filter((s) => s.Id !== d.Id);
                this.props.updateEmployerList(current_employers);
                this.props.setMultiButton('');
            });
        } else {
            this.props.setMultiButton('');
        }
    }

    async setEmploymentCheck(dir) {
        const employment_check = this.state.employment_confirmed;
        if (this.props.employers.length === 0 && employment_check === 'Y') {
            await this.updateEmploymentCompleted('N');
        } else {
            await this.updateEmploymentCompleted(employment_check);
        }

        if (dir === 'back') {
            this.props.onBack();
        } else {
            this.props.onNext();
        }
    }

    render() {
        const {
            new_employer,
            employer_validated,
            new_gap,
            gap_validated,
            edit_employer_validated,
            edit_employer,
            edit_gap,
            edit_gap_validated,
            employment_confirmed,
            display_gap_options,
        } = this.state;

        //#region Duration of Experience
        const current_experience = this.props.employers ? this.props.employers : [];
        let months_of_experience = 0;
        for (let i = 0; i < current_experience.length; i++) {
            if (current_experience[i].Current_Employer === 'Y') {
                const diffMonths = differenceInMonths(new Date(), new Date(current_experience[i].Hire_Date));
                months_of_experience = months_of_experience + diffMonths;
            } else {
                const diffMonths = differenceInMonths(
                    new Date(current_experience[i].Terminate_Date),
                    new Date(current_experience[i].Hire_Date),
                );
                months_of_experience = months_of_experience + diffMonths;
            }
        }

        //#endregion Duration of Experience

        //#region New Employment Form
        const new_form_title = this.props.employers.length > 0 ? 'Add Past Employer' : 'Current Employer';

        let new_form_open = false;
        if (
            new_employer.Employer_Name ||
            new_employer.Employer_Address ||
            new_employer.Contact_Name ||
            new_employer.Position ||
            new_employer.Current_Employer ||
            new_employer.Hire_Date ||
            new_employer.Terminate_Date ||
            new_employer.Reason_For_Leaving
        ) {
            new_form_open = true;
        } else {
            new_form_open = false;
        }

        const new_phone_input = (
            <PhoneInput
                onupdate={(prop) => this.updateNewPhone(prop)}
                current_phone={new_employer ? new_employer.Phone : ''}
            />
        );

        const new_employement_form = (
            <div
                className="d-block mb-5  p-2 bg-light-gray border rounded"
                style={new_form_open ? {} : { filter: 'grayscale(1)' }}
            >
                <h6>{new_form_title}</h6>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="employer-name" label="Employer's Name">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={new_employer.Employer_Name}
                            name="Employer_Name"
                            onChange={this.handleNewEmploymentChange}
                            isValid={new_employer.Employer_Name}
                            isInvalid={!new_employer.Employer_Name}
                            controlId="employer-name"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="employer-address" label="Address">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={new_employer.Employer_Address}
                            name="Employer_Address"
                            onChange={this.handleNewEmploymentChange}
                            isValid={new_employer.Employer_Address}
                            isInvalid={!new_employer.Employer_Address}
                            controlId="employer-address"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="contact-name" label="Contact Name">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={new_employer.Contact_Name}
                            name="Contact_Name"
                            onChange={this.handleNewEmploymentChange}
                            isValid={new_employer.Contact_Name}
                            isInvalid={!new_employer.Contact_Name}
                            controlId="contact-name"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                {new_phone_input}
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="position" label="Transport Driver?">
                        <Form.Select
                            type="text"
                            variant="outline-secondary"
                            value={new_employer.Position}
                            name="Position"
                            onChange={this.handleNewEmploymentChange}
                            isValid={new_employer.Position}
                            isInvalid={!new_employer.Position}
                            controlId="position"
                        >
                            <option value="">Select(Y/N)</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="current-employer" label="This is my current employer">
                        <Form.Select
                            type="text"
                            variant="outline-secondary"
                            value={new_employer.Current_Employer}
                            name="Current_Employer"
                            onChange={this.handleCurrentEmployerCheck}
                            isValid={new_employer.Current_Employer}
                            isInvalid={!new_employer.Current_Employer}
                            controlId="current-employer"
                        >
                            <option value="">Select(Y/N)</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="start-date" label="Start Date">
                        <Form.Control
                            type="date"
                            variant="outline-secondary"
                            value={new_employer.Hire_Date}
                            name="Hire_Date"
                            onChange={this.handleNewEmploymentChange}
                            isValid={new_employer.Hire_Date}
                            isInvalid={!new_employer.Hire_Date}
                            controlId="start-date"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                {new_employer.Current_Employer === 'N' ? (
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="end-date" label="End Date">
                            <Form.Control
                                type="date"
                                variant="outline-secondary"
                                value={new_employer.Terminate_Date}
                                name="Terminate_Date"
                                onChange={this.handleNewEmploymentChange}
                                isValid={new_employer.Terminate_Date}
                                isInvalid={!new_employer.Terminate_Date}
                                controlId="end-date"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>
                ) : null}
                {new_employer.Current_Employer === 'N' ? (
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="reason-for-leaving" label="Reason For Leaving">
                            <Form.Control
                                type="textarea"
                                variant="outline-secondary"
                                value={new_employer.Reason_For_Leaving}
                                name="Reason_For_Leaving"
                                onChange={this.handleNewEmploymentChange}
                                isValid={new_employer.Reason_For_Leaving}
                                isInvalid={!new_employer.Reason_For_Leaving}
                                controlId="reason-for-leaving"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>
                ) : null}
                <Button
                    variant={this.props.multi_button === 'add employer' ? 'outline-success' : 'success'}
                    onClick={() => this.addEmployer()}
                    className="btn-op70 me-3 mt-3"
                    disabled={this.props.multi_button === 'add employer' || !employer_validated}
                >
                    {this.props.multi_button === 'add employer' ? 'Saving...' : 'Save'}
                </Button>
            </div>
        );
        //#endregion New Employment Form

        //#region Edit Employment Form
        const edit_phone = (
            <PhoneInput
                onupdate={(prop) => this.updateEditPhone(prop)}
                current_phone={edit_employer ? edit_employer.Phone : ''}
            />
        );

        const edit_employement_form = (
            <div className="d-block mb-5">
                <h6>Edit Employment History</h6>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="employer-name" label="Employer's Name">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={edit_employer.Employer_Name}
                            name="Employer_Name"
                            onChange={this.handleEditEmploymentChange}
                            isValid={edit_employer.Employer_Name !== ''}
                            isInvalid={edit_employer.Employer_Name === ''}
                            controlId="employer-name"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="employer-address" label="Address">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={edit_employer.Employer_Address}
                            name="Employer_Address"
                            onChange={this.handleEditEmploymentChange}
                            isValid={edit_employer.Employer_Address !== ''}
                            isInvalid={edit_employer.Employer_Address === ''}
                            controlId="employer-address"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="contact-name" label="Contact Name">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={edit_employer.Contact_Name}
                            name="Contact_Name"
                            onChange={this.handleEditEmploymentChange}
                            isValid={edit_employer.Contact_Name !== ''}
                            isInvalid={edit_employer.Contact_Name === ''}
                            controlId="contact-name"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                {edit_phone}
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="position" label="Transport Driver?">
                        <Form.Select
                            type="text"
                            variant="outline-secondary"
                            value={edit_employer.Position}
                            name="Position"
                            onChange={this.handleEditEmploymentChange}
                            isValid={edit_employer.Position !== ''}
                            isInvalid={edit_employer.Position === ''}
                            controlId="position"
                        >
                            <option value="">Select(Y/N)</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="current-employer" label="This is my current employer">
                        <Form.Select
                            type="text"
                            variant="outline-secondary"
                            value={edit_employer.Current_Employer}
                            name="Current_Employer"
                            onChange={this.handleEditCurrentEmployerCheck}
                            isValid={edit_employer.Current_Employer !== ''}
                            isInvalid={edit_employer.Current_Employer === ''}
                            controlId="current-employer"
                        >
                            <option value="">Select(Y/N)</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="start-date" label="Start Date">
                        <Form.Control
                            type="date"
                            variant="outline-secondary"
                            value={edit_employer.Hire_Date}
                            name="Hire_Date"
                            onChange={this.handleEditEmploymentChange}
                            isValid={edit_employer.Hire_Date !== ''}
                            isInvalid={edit_employer.Hire_Date === ''}
                            controlId="start-date"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                {edit_employer.Current_Employer === 'N' ? (
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="end-date" label="End Date">
                            <Form.Control
                                type="date"
                                variant="outline-secondary"
                                value={edit_employer.Terminate_Date}
                                name="Terminate_Date"
                                onChange={this.handleEditEmploymentChange}
                                isValid={edit_employer.Terminate_Date !== ''}
                                isInvalid={edit_employer.Terminate_Date === ''}
                                controlId="end-date"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>
                ) : null}
                {edit_employer.Current_Employer === 'N' ? (
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="reason-for-leaving" label="Reason For Leaving">
                            <Form.Control
                                type="textarea"
                                variant="outline-secondary"
                                value={edit_employer.Reason_For_Leaving}
                                name="Reason_For_Leaving"
                                onChange={this.handleEditEmploymentChange}
                                isValid={edit_employer.Reason_For_Leaving !== ''}
                                isInvalid={edit_employer.Reason_For_Leaving === ''}
                                controlId="reason-for-leaving"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>
                ) : null}
                <InputGroup className="mb-3 d-flex flex-row mt-3" style={{ justifyContent: 'center' }}>
                    <Button
                        variant={this.props.multi_button === 'edit employer' ? 'light' : 'success'}
                        onClick={() => this.editEmployer()}
                        className="btn-op70 submit-buttons"
                        disabled={this.props.multi_button === 'edit employer' || !edit_employer_validated}
                    >
                        {this.props.multi_button === 'edit employer' ? 'Saving...' : 'Save'}
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => this.setState({ edit_employer: initial_employer(this.props.app_id) })}
                        className="btn-op70 submit-buttons"
                    >
                        Cancel
                    </Button>
                </InputGroup>
            </div>
        );
        //#endregion New Employment Form

        //#region Employers Accordion
        const employer_accordion = (
            <div className="d-block mb-5">
                <h5 className="op-70 mb-4">*Requires 10 years of Employment History*</h5>
                <Accordion defaultActiveKey="0">
                    {this.props.employers.map((d, index) => (
                        <Accordion.Item eventKey={d.Id}>
                            <Accordion.Header as="div" className="text-color d-block">
                                <h6 className="mt-2" style={{ alignSelf: 'center' }}>
                                    {d.Employer_Name}
                                </h6>
                                <small className="font-italic p-2" style={{ alignSelf: 'center' }}>
                                    {d.Current_Employer === 'N'
                                        ? '(Duration: ' +
                                          (differenceInMonths(new Date(d.Terminate_Date), new Date(d.Hire_Date)) < 12
                                              ? differenceInMonths(new Date(d.Terminate_Date), new Date(d.Hire_Date)) +
                                                  ' months)'
                                              : Math.round(
                                                      (differenceInMonths(
                                                          new Date(d.Terminate_Date),
                                                          new Date(d.Hire_Date),
                                                      ) /
                                                          12) *
                                                          10,
                                                  ) /
                                                      10 +
                                                  ' years)')
                                        : '(Current Employer for last ' +
                                          (differenceInMonths(new Date(), new Date(d.Hire_Date)) < 12
                                              ? differenceInMonths(new Date(), new Date(d.Hire_Date)) + ' months)'
                                              : Math.round(
                                                      (differenceInMonths(new Date(), new Date(d.Hire_Date)) / 12) *
                                                          100,
                                                  ) /
                                                      100 +
                                                  ' years)')}
                                </small>
                            </Accordion.Header>
                            {edit_employer.Id === d.Id ? (
                                <Accordion.Body>{edit_employement_form}</Accordion.Body>
                            ) : (
                                <Accordion.Body className="d-flex flex-column text-color" style={{ textAlign: 'left' }}>
                                    <label className="p-2">
                                        <strong>Address:</strong>
                                        <bdi className="ms-2">{d.Employer_Address}</bdi>
                                    </label>
                                    <label className="p-2">
                                        <strong>Contact Name:</strong>
                                        <bdi className="ms-2">{d.Contact_Name}</bdi>
                                    </label>
                                    <label className="p-2">
                                        <strong>Phone:</strong>
                                        <bdi className="ms-2">{d.Phone}</bdi>
                                    </label>
                                    <label className="p-2">
                                        <strong>Transport Driver:</strong>
                                        <bdi className="ms-2">{d.Position === 'N' ? 'No' : 'Yes'}</bdi>
                                    </label>
                                    <label className="p-2">
                                        <strong>Current Employer:</strong>
                                        <bdi className="ms-2">{d.Current_Employer === 'N' ? 'No' : 'Yes'}</bdi>
                                    </label>
                                    <label className="p-2">
                                        <strong>Start Date:</strong>
                                        <bdi className="ms-2">{d.Hire_Date}</bdi>
                                    </label>
                                    {d.Current_Employer === 'N' ? (
                                        <label className="p-2">
                                            <strong>End Date:</strong>
                                            <bdi className="ms-2">{d.Terminate_Date}</bdi>
                                        </label>
                                    ) : null}
                                    {d.Current_Employer === 'N' ? (
                                        <label className="p-2">
                                            <strong>Reason:</strong>
                                            <bdi className="ms-2">{d.Reason_For_Leaving}</bdi>
                                        </label>
                                    ) : null}

                                    <div className="d-flex flex-row mt-3">
                                        <Button
                                            variant={this.props.multi_button === 'delete employer' ? 'light' : 'danger'}
                                            className="btn-op70"
                                            size="sm"
                                            style={{ width: '80px' }}
                                            onClick={() => this.deleteEmployer(d)}
                                            disabled={this.props.multi_button === 'delete employer'}
                                        >
                                            {this.props.multi_button === 'delete employer' ? 'Deleting...' : 'Delete'}
                                        </Button>
                                        {edit_employer.Id === 0 ? (
                                            <Button
                                                variant="secondary"
                                                style={{ width: '80px' }}
                                                className="btn-op70 ms-1"
                                                onClick={() =>
                                                    this.setState({ edit_employer: d, edit_employer_validated: false })
                                                }
                                                size="sm"
                                            >
                                                Edit
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="secondary"
                                                style={{ width: '80px' }}
                                                className="btn-op70 ms-1"
                                                onClick={() =>
                                                    this.setState({
                                                        edit_employer: initial_employer(this.props.app_id),
                                                        edit_employer_validated: false,
                                                    })
                                                }
                                                size="sm"
                                            >
                                                Cancel
                                            </Button>
                                        )}
                                    </div>
                                </Accordion.Body>
                            )}
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        );
        //#endregion Employers Accordion

        //#region New Gap Form
        let new_gap_open = false;
        if (new_gap.Start_Date || new_gap.End_Date || new_gap.Reason) {
            new_gap_open = true;
        } else {
            new_gap_open = false;
        }

        const new_gap_form = (
            <div className="d-block" style={new_gap_open ? {} : { filter: 'grayscale(1)' }}>
                <h6>Add Period of Non-Employment</h6>
                <InputGroup className="mb-2 opacity70">
                    <FloatingLabel controlId="gap-start" label="Start Date">
                        <Form.Control
                            type="date"
                            variant="outline-secondary"
                            value={new_gap.Start_Date}
                            name="Start_Date"
                            onChange={this.handleNewGap}
                            isValid={new_gap.Start_Date}
                            isInvalid={!new_gap.Start_Date}
                            controlId="gap-start"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-2 opacity70">
                    <FloatingLabel controlId="gap-end" label="End Date">
                        <Form.Control
                            type="date"
                            variant="outline-secondary"
                            value={new_gap.End_Date}
                            name="End_Date"
                            onChange={this.handleNewGap}
                            isValid={new_gap.End_Date}
                            isInvalid={!new_gap.End_Date}
                            controlId="gap-end"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-2 opacity70">
                    <FloatingLabel controlId="gap-reason" label="Reason">
                        <Form.Control
                            type="textarea"
                            variant="outline-secondary"
                            value={new_gap.Reason}
                            name="Reason"
                            onChange={this.handleNewGap}
                            isValid={new_gap.Reason}
                            isInvalid={!new_gap.Reason}
                            controlId="reason"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                <Button
                    variant={this.props.multi_button === 'add gap' ? 'light' : 'success'}
                    onClick={() => this.addGap()}
                    className="btn-op70 me-3 mt-3"
                    disabled={this.props.multi_button === 'add gap' || !gap_validated}
                >
                    {this.props.multi_button === 'add gap' ? 'Saving...' : 'Save'}
                </Button>
            </div>
        );
        //#endregion New Gap Form

        //#region edit Gap Form
        const edit_gap_form = (
            <div className="d-block">
                <h6>Edit Period of Non-Employment</h6>
                <InputGroup className="mb-2 opacity70">
                    <FloatingLabel controlId="gap-start" label="Start Date">
                        <Form.Control
                            type="date"
                            variant="outline-secondary"
                            value={edit_gap.Start_Date}
                            name="Start_Date"
                            onChange={this.handleEditGap}
                            isValid={edit_gap.Start_Date !== ''}
                            isInvalid={edit_gap.Start_Date === ''}
                            controlId="gap-start"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-2 opacity70">
                    <FloatingLabel controlId="gap-end" label="End Date">
                        <Form.Control
                            type="date"
                            variant="outline-secondary"
                            value={edit_gap.End_Date}
                            name="End_Date"
                            onChange={this.handleEditGap}
                            isValid={edit_gap.End_Date !== ''}
                            isInvalid={edit_gap.End_Date === ''}
                            controlId="gap-end"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>

                <InputGroup className="mb-2 opacity70">
                    <FloatingLabel controlId="gap-reason" label="Reason">
                        <Form.Control
                            type="textarea"
                            variant="outline-secondary"
                            value={edit_gap.Reason}
                            name="Reason"
                            onChange={this.handleEditGap}
                            isValid={edit_gap.Reason !== ''}
                            isInvalid={edit_gap.Reason === ''}
                            controlId="reason"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3 d-flex flex-row mt-2" style={{ justifyContent: 'center' }}>
                    <Button
                        variant={this.props.multi_button === 'edit gap' ? 'light' : 'success'}
                        onClick={() => this.editGap()}
                        className="btn-op70 submit-buttons"
                        disabled={this.props.multi_button === 'edit gap' || !edit_gap_validated}
                    >
                        {this.props.multi_button === 'edit gap' ? 'Saving...' : 'Save'}
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => this.setState({ edit_gap: initial_gap(this.props.app_id) })}
                        className="btn-op70 submit-buttons"
                    >
                        Cancel
                    </Button>
                </InputGroup>
            </div>
        );
        //#endregion New Gap Form

        //#region Gap Accordion
        const gap_accordion = (
            <div className="d-block mb-5">
                <h5 className="op-70 mb-4">Periods of Non-Employment</h5>
                <Accordion defaultActiveKey="0">
                    {this.props.employment_gaps.map((d, index) => (
                        <Accordion.Item eventKey={d.Id}>
                            <Accordion.Header as="h6" className="d-flex flex-row justify-content-center">
                                <h6 className="p-0 m-0">{'Start Date: ' + d.Start_Date}</h6>
                            </Accordion.Header>
                            {edit_gap.Id === d.Id ? (
                                <Accordion.Body>{edit_gap_form}</Accordion.Body>
                            ) : (
                                <Accordion.Body className="d-flex flex-column text-color" style={{ textAlign: 'left' }}>
                                    <label className="p-2">
                                        <strong>Start Date:</strong>
                                        <bdi className="ms-2">{d.Start_Date}</bdi>
                                    </label>
                                    <label className="p-2">
                                        <strong>End Date:</strong>
                                        <bdi className="ms-2">{d.End_Date}</bdi>
                                    </label>
                                    <label className="p-2">
                                        <strong>Reason:</strong>
                                        <bdi className="ms-2">{d.Reason}</bdi>
                                    </label>
                                    <div className="d-flex flex-row">
                                        <Button
                                            variant={this.props.multi_button === 'delete gap' ? 'light' : 'danger'}
                                            style={{ width: '80px' }}
                                            onClick={() => this.deleteGap(d)}
                                            className="btn-op70"
                                            size="sm"
                                            disabled={this.props.multi_button === 'delete gap'}
                                        >
                                            {this.props.multi_button === 'delete gap' ? 'Deleting...' : 'Delete'}
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            className="btn-op70 ms-1"
                                            style={{ width: '80px' }}
                                            onClick={() =>
                                                this.setState({
                                                    edit_gap:
                                                        edit_gap.Id === d.Id ? initial_employer(this.props.app_id) : d,
                                                })
                                            }
                                            size="sm"
                                        >
                                            Edit
                                        </Button>
                                    </div>
                                </Accordion.Body>
                            )}
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        );
        //#endregion Gap Accordion

        //#region Display Gap Options
        const gap_options = display_gap_options ? (
            <div className="p-2 bg-light-gray border rounded mb-5">
                {gap_accordion}
                {new_gap_form}
            </div>
        ) : null;

        //#endregion Display Gap Options

        //#region Select Employment Option
        const select_employment_option = (
            <Container fluid="xs" className="d-flex flex-column justify-content-center p-3 border border-2 rounded">
                <Row>
                    <Col className="d-flex flex-column justify-content-center">
                        <h5>Select an option before continuing:</h5>
                        <hr />
                    </Col>
                </Row>
                <Row className="d-flex flex-row justify-content-around">
                    <Col xl={3} lg={4} med={6} sm={6} xs={6}>
                        <Form.Check
                            checked={employment_confirmed === 'Y'}
                            isInvalid={employment_confirmed !== 'Y' && employment_confirmed !== 'U'}
                            label="I have filled out my previous 10 years of history"
                            style={{ fontWeight: '600' }}
                            onClick={() =>
                                this.setState({ employment_confirmed: employment_confirmed === 'Y' ? '' : 'Y' })
                            }
                        />
                    </Col>
                    <Col xl={3} lg={4} med={6} sm={6} xs={6}>
                        <Form.Check
                            checked={employment_confirmed === 'U'}
                            isInvalid={employment_confirmed !== 'Y' && employment_confirmed !== 'U'}
                            style={{ fontWeight: '600' }}
                            label="I was unemployed or in school for the previous 10 years"
                            onClick={() =>
                                this.setState({ employment_confirmed: employment_confirmed === 'U' ? '' : 'U' })
                            }
                        />
                    </Col>
                </Row>
            </Container>
        );
        //#endregion Select Employment Option

        return (
            <div className="d-flex flex-column">
                {months_of_experience > 0 ? (
                    <Alert variant={months_of_experience >= 120 ? 'success' : 'danger'}>
                        Applicant's Total Length of Experience: {Math.round((months_of_experience / 12) * 10) / 10}{' '}
                        years
                    </Alert>
                ) : null}

                {employer_accordion}
                {new_employement_form}

                {gap_options}
                {select_employment_option}

                {employment_confirmed === 'Y' && this.props.employers.length === 0 ? (
                    <Alert variant="danger" className="mt-3">
                        You have selected that you filled out your previous 10 years of history, but no Employment
                        History can be found.
                    </Alert>
                ) : null}

                {new_form_open ? (
                    <Alert variant="danger" className="mt-3">
                        The information you have entered in the "{new_form_title}" form has not been saved. Please save
                        the form before continuing, or{' '}
                        <Button
                            variant="link"
                            onClick={() => this.setState({ new_employer: initial_employer(this.props.app_id) })}
                            className="text-underlined text-primary p-0"
                        >
                            click here
                        </Button>{' '}
                        to clear the form.
                    </Alert>
                ) : null}

                {new_gap_open ? (
                    <Alert variant="danger" className="mt-3">
                        The information you have entered in the "Add Period of Non-Employment" form has not been saved.
                        Please save the form before continuing, or{' '}
                        <Button
                            variant="link"
                            onClick={() => this.setState({ new_gap: initial_gap(this.props.app_id) })}
                            className="text-underlined text-primary p-0"
                        >
                            click here
                        </Button>{' '}
                        to clear the form.
                    </Alert>
                ) : null}

                <Stack direction="horizontal" gap={3} className="mt-5" style={{ alignSelf: 'center' }}>
                    <Button
                        variant="outline-secondary"
                        onClick={() => this.setEmploymentCheck('back')}
                        className="btn-op70 submit-buttons"
                    >
                        Back
                    </Button>
                    <Button
                        variant={this.props.multi_button === 'next' ? 'light' : 'secondary'}
                        onClick={() => this.setEmploymentCheck('next')}
                        className="btn-op70 submit-buttons"
                        disabled={
                            new_gap_open ||
                            new_form_open ||
                            (employment_confirmed !== 'Y' && employment_confirmed !== 'U') ||
                            (employment_confirmed === 'Y' && this.props.employers.length === 0)
                        }
                    >
                        Next
                    </Button>
                </Stack>
            </div>
        );
    }
}
