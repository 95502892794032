import { format } from 'date-fns';
import React, { Component } from 'react';
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { sendGetFetch } from '../application/FetchFunctions';
export class SignatureTerms extends Component {
    constructor(props) {
        super(props);
        this.state = { esign_terms: false, button_clicked: false };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updateCheck = this.updateCheck.bind(this);
    }

    async updateCheck(e) {
        this.setState({ esign_terms: true });
        const current_consent = this.props.signature_consent;
        if (e.target.checked) {
            await sendGetFetch('https://ipapi.co/json/').then((res) => {
                current_consent.Gave_Consent = 'Y';
                current_consent.Full_Name = this.props.full_name;
                current_consent.IP_Address = res.ip;
                current_consent.Consent_Date = new Date();
                current_consent.App_id = this.props.app_id;
                this.props.updateConsent(JSON.stringify(current_consent));
                this.handleSubmit();
            });
        } else {
            current_consent.Gave_Consent = '';
            current_consent.Full_Name = '';
            current_consent.IP_Address = '';
            current_consent.App_id = this.props.app_id;
            this.props.updateConsent(JSON.stringify(current_consent));
        }
    }

    handleSubmit() {
        this.setState({ button_clicked: true });
        this.props.postConsent();
        this.setState({ esign_terms: false, button_clicked: false });
    }

    render() {
        return (
            <Container fluid="xs" className="px-3">
                <Row>
                    <Col>
                        <h5 className="text-start">TERMS AND CONDITIONS</h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h6 className="text-start">E-Sign Act Requirements</h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="text-start">
                            The <bdi className="font-italic">E-Sign Act</bdi> states the requirements for Electronic
                            Signatures that are being used on Electronic Documents in the United States and Canada.
                        </p>

                        <p className="text-start">
                            An Electronic Signature is defined as{' '}
                            <bdi className="font-italic">
                                "an electronic sound, symbol or process, attached to or logically associated with a
                                contract or other record and excuted or adopted by a person with the intent to sign the
                                record."{' '}
                            </bdi>
                        </p>
                        <p className="text-start">
                            The applicant agrees to create an account in order to apply for this position. The applicant
                            will be instructed to create an Electronic Signature once the account is created. The
                            Electronic Signature that the applicant creates is a legally binding signature.
                        </p>
                        <p className="text-start">
                            Upon creating an Electronic Signature, the applicant is giving their consent to sign the
                            document electronically using this signature. The applicant has a right to request a
                            printable copy of the application. The printable copy can requested by emailing
                            'recruiting@scotlynn.com'.
                        </p>

                        <p className="text-start">
                            The applicant has a right to withdraw their consent of using the Electronic Signature;
                            however, the application will not be accepted unless it is signed. If the applicant does not
                            want to give consent to an electronic signature, they can request a printable copy of the
                            application. This application would then need to be printed out by the applicant, signed and
                            then returned to 'recruiting@scotlynn.com'.
                        </p>

                        <p className="text-start">
                            The consent given by the applicant is an agreement to use the Electronic Signature they
                            create to sign the application, as well as various disclosures that the applicant will have
                            an opportunity to review prior to signing. The applicant's signature is a legally binding
                            agreement that all the information provided by the applicant is true to the best of their
                            knowledge.
                        </p>

                        <p className="text-start">
                            The Electronic Signature created by the applicant will be kept in the Scotlynn Commodities
                            Inc records for a reasonable amount of time. The applicant has a right to request a copy of
                            this record. The Electronic Signature created by the applicant can be emailed to them in a
                            PDF form. The applicant would need a device that can open a PDF inorder to view the record.
                        </p>

                        <p className="text-start">
                            Attaching an oral recording of consent to the application DOES NOT qualify as an Electronic
                            Signature and cannot be accepted as a legally binding aggreement between Scotlynn
                            Commodities Inc and the applicant.
                        </p>

                        <p className="text-start">
                            Scotlynn has made clear, to the best of their abilities, exactly what the applicant is using
                            their Electronic Signature for, as well as what they agreeing to.
                        </p>
                    </Col>
                </Row>
                <Row className="d-flex flex-row justify-content-center w-100 mt-4">
                    <Col xl={4} lg={6} med={11} sm={12} xs={12}>
                        {this.props.signature_consent.Gave_Consent === 'Y' ? (
                            <InputGroup className="mb-3 op-70">
                                <Form.Check
                                    type="radio"
                                    checked={true}
                                    label={
                                        this.props.full_name +
                                        ' agreed on ' +
                                        format(
                                            new Date(this.props.signature_consent.Consent_Date),
                                            'MM/dd/yyyy HH:mm:ss',
                                        )
                                    }
                                    variant="outline-secondary"
                                    disabled={true}
                                    isValid={true}
                                ></Form.Check>
                            </InputGroup>
                        ) : (
                            <InputGroup className="mb-3 op-70">
                                <Form.Check
                                    type="radio"
                                    label={'I ' + this.props.full_name + ', agree to the E-Sign Terms and Conditions.'}
                                    isInvalid={true}
                                    variant="outline-secondary"
                                    onClick={(e) => this.updateCheck(e)}
                                ></Form.Check>
                            </InputGroup>
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}
