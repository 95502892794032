async function sendPutFetch(body, fetchString) {
    try {
        const fetchResponse = await fetch(fetchString, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: body,
        });
        const data = await fetchResponse.json();
        return data;
    } catch (e) {
        return e;
    }
}

async function sendPostFetch(body, fetchString) {
    try {
        const fetchResponse = await fetch(fetchString, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: body,
        });
        const data = await fetchResponse.json();
        return data;
    } catch (e) {
        return e;
    }
}

async function sendGetFetch(fetchString) {
    try {
        const response = await fetch(fetchString);
        const data = await response.json();
        return data;
    } catch (e) {
        return e;
    }
}

async function sendDeleteFetch(body, fetchString) {
    try {
        const fetchResponse = await fetch(fetchString, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: body,
        });
        const data = await fetchResponse.json();
        return data;
    } catch (e) {
        return e;
    }
}

export { sendPutFetch, sendGetFetch, sendDeleteFetch, sendPostFetch };
