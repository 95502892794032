import { MsalContext } from '@azure/msal-react';
import React, { Component } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { loginRequest } from '../authConfig';
import { initial_mailgun_user } from './dtos/InitialValues';
import { ReportApplicantIssue } from './modals/ReportApplicantIssue';
import { sendPutFetch } from './utilities/FetchFunctions';

export class AdminLogin extends Component {
    static displayName = AdminLogin.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            show_modal: false,
            send_error_clicked: false,
            mailgun_user: initial_mailgun_user,
            apply_now_clicked: false,
        };
        this.handleLogin = this.handleLogin.bind(this);
    }
    componentDidMount() {
        localStorage.clear();
    }

    handleLogin = async () => {
        try {
            const inst = this.context.instance;
            await inst.loginRedirect(loginRequest).then((res) => console.log(res));
        } catch (e) {
            console.log(e);
        }
    };

    render() {
        const { show_modal, send_error_clicked, mailgun_user } = this.state;

        //#region Login Body

        const login_body = (
            <Card className="text-center mt-3">
                <Card.Header as="h4" className="p-3">
                    Recruiter Login
                </Card.Header>
                <Card.Body className="py-5 d-flex flex-column">
                    <Button
                        variant="warning"
                        onClick={() => this.handleLogin()}
                        className="btn-op70 w-50"
                        style={{ fontWeight: '500' }}
                    >
                        Login
                    </Button>
                    <label className="mt-5">Not a recruiter?</label>
                    <label>
                        Click <a href="/apply">here</a> to apply!
                    </label>
                </Card.Body>
                <Card.Footer className="text-muted p-3">
                    <Button variant="link" className="btn-op70" onClick={() => this.setIssue('')}>
                        Report Issue
                    </Button>
                </Card.Footer>
            </Card>
        );
        //#endregion Login Body

        return (
            <div className="mb-3">
                <Container fluid="xs" className="mb-5 mx-3 mt-3">
                    <Row className="d-flex flex-row justify-content-center">
                        <Col xs={12} lg={8}>
                            {login_body}
                        </Col>
                    </Row>
                </Container>

                <ReportApplicantIssue
                    show={show_modal}
                    mailgun_user={mailgun_user}
                    updateUser={(prop) => this.setState({ mailgun_user: JSON.parse(prop) })}
                    ignore={() =>
                        this.setState({
                            send_error_clicked: false,
                            show_modal: false,
                            mailgun_user: initial_mailgun_user,
                        })
                    }
                    sendEmail={async () =>
                        await sendPutFetch(JSON.stringify(mailgun_user), '/mailgun').then((res) => {
                            if (res.StatusCode === 200) {
                                this.setState({
                                    send_error_clicked: false,
                                    show_modal: false,
                                    mailgun_user: initial_mailgun_user,
                                });
                            } else {
                                this.setState({
                                    send_error_clicked: false,
                                    show_modal: false,
                                    mailgun_user: initial_mailgun_user,
                                });
                            }
                        })
                    }
                    errorClicked={send_error_clicked}
                    click_error={() => this.setState({ send_error_clicked: !send_error_clicked })}
                />
            </div>
        );
    }
}
