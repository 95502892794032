import { isBefore } from 'date-fns';
import React, { Component } from 'react';
import { Alert, FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { sendGetFetch } from '../application/FetchFunctions';
import ApplicationButtonBar from '../buttons/ApplicationButtonBar';
import PhoneInput from '../inputs/PhoneInput';

export class PersonalHistory extends Component {
    constructor(props) {
        super(props);
        const ssn =
            this.props.personal_history.App_id > 0 && this.props.personal_history.SSN !== ''
                ? this.props.personal_history.SSN.split('-')
                : '';

        const placeholder = this.props.country === 'CA' ? '***' : '**';

        const s_1 = ssn.length >= 1 ? '***' : '';
        const s_2 = ssn.length >= 2 ? placeholder : '';
        const s_3 = ssn.length >= 3 ? ssn[2] : '';

        this.state = {
            validated: false,
            changed: false,
            ssn_1: s_1,
            ssn_2: s_2,
            ssn_3: s_3,
            date_of_birth_error: '',
        };
        this.handleApplicationChange = this.handleApplicationChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.submitInfo = this.submitInfo.bind(this);
        this.handleSSN = this.handleSSN.bind(this);
        this.handleDateOfBirth = this.handleDateOfBirth.bind(this);
        this.updateCellPhone = this.updateCellPhone.bind(this);
    }

    handleApplicationChange(e) {
        const current_info = this.props.personal_history;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
            current_info.Full_Name = this.props.Full_Name;
        }
        current_info[e.target.name] = e.target.value;

        this.props.onupdate(current_info);
        this.validateForm();
    }

    handleDateOfBirth(e) {
        const current_info = this.props.personal_history;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
            current_info.Full_Name = this.props.Full_Name;
        }
        const selected_date = new Date(e.target.value);
        if (isBefore(selected_date, new Date())) {
            this.setState({ date_of_birth_error: '' });
            current_info[e.target.name] = e.target.value;
            this.props.onupdate(current_info);
            this.validateForm();
        } else {
            this.setState({ date_of_birth_error: 'Date of Birth cannot be in the future' });
        }
    }

    handleSSN(e) {
        const current_history = this.props.personal_history;
        let s_1 = this.state.ssn_1;
        let s_2 = this.state.ssn_2;
        let s_3 = this.state.ssn_3;
        const maxval_2 = this.props.country === 'CA' ? 4 : 3;
        const maxval_3 = this.props.country === 'CA' ? 4 : 5;
        if (e.target.name === 'ssn_1') {
            if (e.target.value.toString().length < 4) {
                s_1 = e.target.value;
                this.setState({ ssn_1: s_1 });
            } else {
                s_2 = e.target.value[3];
                this.setState({ ssn_2: e.target.value[3] });
                const next_field = document.querySelector(`input[name=ssn_2]`);
                next_field.focus();
            }
        } else if (e.target.name === 'ssn_2') {
            if (e.target.value.toString().length < maxval_2) {
                s_2 = e.target.value;
                this.setState({ ssn_2: s_2 });
            } else {
                s_3 = e.target.value[maxval_2 - 1];
                this.setState({ ssn_3: s_3 });
                const next_field = document.querySelector(`input[name=ssn_3]`);
                next_field.focus();
            }
        } else if (e.target.name === 'ssn_3') {
            if (e.target.value.toString().length < maxval_3) {
                s_3 = e.target.value;
                this.setState({ ssn_3: s_3 });
            }
        }
        current_history.SSN = s_1 + '-' + s_2 + '-' + s_3;
        this.props.onupdate(current_history);
        this.validateForm();
    }

    validateForm() {
        const personalHistory = this.props.personal_history;
        let is_valid = false;
        const phone_regex = /\b[0-9]{3}\b[-]{1}\b[0-9]{3}\b[-]{1}\b[0-9]{4}\b/;
        if (personalHistory.App_id === 0) {
            personalHistory.App_id = this.props.app_id;
        }
        if (
            personalHistory.Street_Address !== '' &&
            personalHistory.City !== '' &&
            personalHistory.Province !== '' &&
            personalHistory.Postal_Code !== '' &&
            phone_regex.test(personalHistory.Cell_Phone) &&
            personalHistory.Date_of_Birth !== '' &&
            personalHistory.SSN.length >= 9
        ) {
            is_valid = true;
        } else {
            is_valid = false;
        }
        if (is_valid !== this.state.validated) {
            this.setState({ validated: is_valid });
        }
    }

    componentDidMount() {
        this.validateForm();
    }

    componentDidUpdate() {
        this.validateForm();
    }

    async submitInfo(button) {
        this.props.setMultiButton(button);
        let next_view = 'personal';
        if (button === 'back') {
            next_view = 'general';
        } else {
            next_view = 'emergency';
        }
        const v_personal_history = await sendGetFetch('personalhistory/' + this.props.app_id);
        if (v_personal_history.Id === 0) {
            this.props.onPost();
        } else {
            this.props.onPut();
        }
        this.props.setMultiButton('');
        this.props.setView(next_view);
    }

    updateCellPhone(phone) {
        const current_history = this.props.personal_history;
        current_history.Cell_Phone = phone;
        this.props.onupdate(current_history);
    }

    render() {
        const { ssn_1, ssn_2, ssn_3, date_of_birth_error } = this.state;

        //#region Full Name
        const full_name = (
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel controlId="full-name" label="Full Name">
                    <Form.Control
                        type="text"
                        variant="outline-secondary"
                        value={this.props.Full_Name}
                        controlId="full-name"
                        readOnly
                    ></Form.Control>
                </FloatingLabel>
            </InputGroup>
        );
        //#endregion Full Name

        //#region Street Address
        const street_address =
            this.props.personal_history !== undefined ? (
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="street-address" label="Street Address">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={this.props.personal_history.Street_Address}
                            name="Street_Address"
                            onChange={this.handleApplicationChange}
                            isValid={this.props.personal_history.Street_Address !== ''}
                            isInvalid={this.props.personal_history.Street_Address === ''}
                            controlId="street-address"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
            ) : null;
        //#endregion Street Address

        //#region City
        const city =
            this.props.personal_history !== undefined ? (
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="city" label="City">
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={this.props.personal_history.City}
                            name="City"
                            onChange={this.handleApplicationChange}
                            isValid={this.props.personal_history.City !== ''}
                            isInvalid={this.props.personal_history.City === ''}
                            controlId="city"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
            ) : null;
        //#endregion City

        //#region Province
        const province =
            this.props.personal_history !== undefined ? (
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel
                        controlId="province-state"
                        label={this.props.country === 'CA' ? 'Province' : 'State'}
                    >
                        <Form.Select
                            variant="outline-secondary"
                            value={this.props.personal_history.Province}
                            name="Province"
                            onChange={this.handleApplicationChange}
                            isValid={this.props.personal_history.Province !== ''}
                            isInvalid={this.props.personal_history.Province === ''}
                            controlId="province-state"
                        >
                            <option value="">Select</option>
                            {this.props.state_provs.map((d, index) => (
                                <option key={d.Pst_id} value={d.Pst_id}>
                                    {d.Pst_Name}
                                </option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>
            ) : null;
        //#endregion Province

        //#region Postal Code
        const postal_code =
            this.props.personal_history !== undefined ? (
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel
                        controlId="postal-code"
                        label={this.props.country === 'CA' ? 'Postal Code' : 'Zip Code'}
                    >
                        <Form.Control
                            type="text"
                            variant="outline-secondary"
                            value={this.props.personal_history.Postal_Code}
                            name="Postal_Code"
                            onChange={this.handleApplicationChange}
                            isValid={this.props.personal_history.Postal_Code !== ''}
                            isInvalid={this.props.personal_history.Postal_Code === ''}
                            controlId="postal-code"
                        ></Form.Control>
                    </FloatingLabel>
                </InputGroup>
            ) : null;
        //#endregion Postal Code

        //#region Cell Phone
        const phone_input = (
            <PhoneInput
                onupdate={(prop) => this.updateCellPhone(prop)}
                personal_history={this.props.personal_history}
                current_phone={this.props.personal_history ? this.props.personal_history.Cell_Phone : ''}
            />
        );
        //#endregion Cell Phone

        //#region DOB
        const DOB = (
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel controlId="date-of-birth" label="Date of Birth">
                    <Form.Control
                        type="date"
                        variant="outline-secondary"
                        value={this.props.personal_history.Date_of_Birth}
                        name="Date_of_Birth"
                        onChange={this.handleDateOfBirth}
                        isValid={this.props.personal_history.Date_of_Birth !== ''}
                        isInvalid={this.props.personal_history.Date_of_Birth === '' || date_of_birth_error !== ''}
                        controlId="date-of-birth"
                    ></Form.Control>
                </FloatingLabel>
            </InputGroup>
        );
        //#endregion DOB

        //#region SSN/SIN
        const SSN =
            this.props.personal_history !== undefined ? (
                <InputGroup className="mb-3 opacity70">
                    <div
                        controlId="sin-ssn"
                        className="d-flex flex-column form-floating form-control"
                        style={{ justifyContent: 'flex-start' }}
                    >
                        <small className="text-start text-muted pb-1">
                            {this.props.country === 'CA' ? 'SIN' : 'SSN'}
                        </small>
                        <InputGroup controlId="sin-ssn">
                            <Form.Control
                                type="number"
                                variant="outline-secondary"
                                value={ssn_1}
                                name="ssn_1"
                                onChange={this.handleSSN}
                                isValid={ssn_1.toString().length === 3}
                                isInvalid={ssn_1.toString().length !== 3}
                                controlId="sin-ssn"
                                placeholder="***"
                                className="text-center"
                            ></Form.Control>
                            <label className="px-1 py-0" style={{ alignSelf: 'center' }}>
                                -
                            </label>
                            <Form.Control
                                type="number"
                                variant="outline-secondary"
                                value={ssn_2}
                                name="ssn_2"
                                onChange={this.handleSSN}
                                isValid={ssn_2.toString().length >= 2}
                                isInvalid={ssn_2.toString().length < 2}
                                controlId="sin-ssn"
                                placeholder={this.props.country === 'CA' ? '***' : '**'}
                                className="text-center"
                            ></Form.Control>
                            <label className="px-1 py-0" style={{ alignSelf: 'center' }}>
                                -
                            </label>
                            <Form.Control
                                type="number"
                                variant="outline-secondary"
                                value={ssn_3}
                                name="ssn_3"
                                onChange={this.handleSSN}
                                isValid={ssn_3.toString().length >= 3}
                                isInvalid={ssn_3.toString().length < 3}
                                controlId="sin-ssn"
                                placeholder={this.props.country === 'CA' ? '***' : '****'}
                                className="text-center"
                            ></Form.Control>
                        </InputGroup>
                    </div>
                </InputGroup>
            ) : null;
        //#endregion SSN/SIN

        //#region Marital Status
        const marital_status =
            this.props.personal_history !== undefined ? (
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="marital-status" label="Marital Status">
                        <Form.Select
                            variant="outline-secondary"
                            value={this.props.personal_history.Marital_Status}
                            name="Marital_Status"
                            onChange={this.handleApplicationChange}
                            isValid={this.props.personal_history.Marital_Status !== ''}
                            isInvalid={this.props.personal_history.Marital_Status === ''}
                            controlId="marital-status"
                        >
                            <option value="">Select</option>
                            <option value="Divorced">Divorced</option>
                            <option value="Married">Married</option>
                            <option value="Separated">Separated</option>
                            <option value="Single">Single</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>
            ) : null;
        //#endregion Marital Status

        return (
            <div className="d-flex flex-column p-0">
                {full_name}
                {street_address}
                {city}
                {province}
                {postal_code}
                {phone_input}
                {DOB}
                {date_of_birth_error !== '' ? (
                    <Alert variant="danger">
                        <small>{date_of_birth_error}</small>
                    </Alert>
                ) : null}

                {SSN}
                {marital_status}

                <ApplicationButtonBar
                    multi_button={this.props.multi_button}
                    submitInfo={(prop) => this.submitInfo(prop)}
                />
            </div>
        );
    }
}
