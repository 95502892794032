import { format } from 'date-fns';
import React, { Component } from 'react';
import { Alert, Button, Form, Image, InputGroup } from 'react-bootstrap';
import { sendGetFetch } from '../application/FetchFunctions';
import ApplicationButtonBar from '../buttons/ApplicationButtonBar';

export class ReferenceCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
        };

        this.addDocument = this.addDocument.bind(this);
        this.editDocument = this.editDocument.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.signDocument = this.signDocument.bind(this);
        this.submitInfo = this.submitInfo.bind(this);
    }

    async addDocument() {
        this.props.setMultiButton('save');
        await this.props.onPost();
        this.props.setMultiButton('');
    }
    editDocument() {
        this.props.onPut();
    }
    deleteDocument() {
        this.props.onDelete();
    }

    async signDocument(signed) {
        const current_document = this.props.reference_check;
        if (current_document.App_id === 0) {
            current_document.App_id = this.props.app_id;
        }
        if (signed === 'Y') {
            await sendGetFetch('https://ipapi.co/json/').then((res) => {
                current_document.Signed = 'Y';
                current_document.Signed_Date_Time = format(new Date(), 'MM/dd/yyyy HH:mm:ss');
                current_document.IP_Address = res.ip;
                this.props.onUpdate(current_document);
            });
        } else {
            current_document.Signed = 'N';
            current_document.Signed_Date_Time = '';
            current_document.IP_Address = '';
            this.props.onUpdate(current_document);
        }

        this.setState({ validated: true });
    }

    async submitInfo(button) {
        this.props.setMultiButton(button);
        let next_view = 'reference';
        if (button === 'back') {
            next_view = 'agreement';
        } else {
            next_view = 'background';
        }
        const v_reference_check = await sendGetFetch('referencecheck/' + this.props.app_id);
        if (v_reference_check.Id === 0) {
            this.props.onPost();
        } else {
            if (v_reference_check !== this.props.reference_check) {
                this.props.onPut();
            }
        }

        this.props.setMultiButton('');
        this.props.setView(next_view);
    }

    render() {
        const { validated } = this.state;
        const address =
            this.props.country === 'CA'
                ? '1150 Vittoria Rd. R.R. #1, Vittoria, ON N0E 1W0'
                : '9597 Gulf Research Lane, Fort Myers, FL 33912';
        const doc_title = this.props.country === 'CA' ? 'Scotlynn Commodities' : 'Scotlynn Transport';

        const personal_info = (
            <div className="d-block mb-3" style={{ alignSelf: 'center' }}>
                <InputGroup className="mb-3 op-70">
                    <InputGroup.Text>Name</InputGroup.Text>
                    <Form.Control
                        readOnly
                        value={this.props.personal_history.Full_Name}
                        variant="outline-secondary"
                    ></Form.Control>
                </InputGroup>
                <InputGroup className="mb-3 op-70">
                    <InputGroup.Text>Address</InputGroup.Text>
                    <Form.Control
                        readOnly
                        value={this.props.personal_history.Street_Address}
                        variant="outline-secondary"
                    ></Form.Control>
                </InputGroup>
                <InputGroup className="mb-3 op-70">
                    <InputGroup.Text>City</InputGroup.Text>
                    <Form.Control
                        readOnly
                        value={this.props.personal_history.City}
                        variant="outline-secondary"
                    ></Form.Control>
                </InputGroup>
                <InputGroup className="mb-3 op-70">
                    <InputGroup.Text>State/Province</InputGroup.Text>
                    <Form.Control
                        readOnly
                        value={this.props.personal_history.Province}
                        variant="outline-secondary"
                    ></Form.Control>
                </InputGroup>
                <InputGroup className="mb-3 op-70">
                    <InputGroup.Text>Postal Code</InputGroup.Text>
                    <Form.Control
                        readOnly
                        value={this.props.personal_history.Postal_Code}
                        variant="outline-secondary"
                    ></Form.Control>
                </InputGroup>
            </div>
        );

        const applicant_signed =
            this.props.reference_check.Signed === 'Y' ? (
                <div className="d-flex flex-column">
                    <Image
                        src={this.props.signature}
                        style={{ maxWidth: '60vw', alignSelf: 'center' }}
                        className="rounded mb-2"
                        fluid
                        onClick={() => this.signDocument('N')}
                    />
                    <small className="font-italic">
                        Signed: {format(new Date(this.props.reference_check.Signed_Date_Time), 'MM/dd/yyyy HH:mm:ss')}
                    </small>
                </div>
            ) : (
                <Alert variant="danger" onClick={() => this.signDocument('Y')}>
                    Click here to sign
                </Alert>
            );

        let check_personal_history = false;
        if (this.props.personal_history !== undefined && this.props.personal_history !== null) {
            if (this.props.personal_history.Full_Name === '') {
                check_personal_history = false;
            } else if (this.props.personal_history.Street_Address === '') {
                check_personal_history = false;
            } else if (this.props.personal_history.City === '') {
                check_personal_history = false;
            } else if (this.props.personal_history.Province === '') {
                check_personal_history = false;
            } else if (this.props.personal_history.Postal_Code === '') {
                check_personal_history = false;
            } else {
                check_personal_history = true;
            }
        }

        return (
            <div className="d-flex flex-column">
                <h6 className="mb-3">{address}</h6>
                <strong className="mb-3">
                    The following named person has made an application with our company for the position of AZ Driver:
                </strong>
                {personal_info}
                <strong className="mb-3">
                    I hereby authorize you to release the following information to {doc_title} for purpose of
                    investigating as required by Section 391.23 of the Federal Motor Carrier Safety Regulations. You are
                    released from any and all liability, which may result from such information:
                </strong>
                {check_personal_history ? (
                    applicant_signed
                ) : (
                    <Alert variant="danger">
                        <h6>
                            <bdi>Personal History must be fully completed before signing this document.</bdi>
                            <Button
                                variant="link"
                                onClick={() => this.props.setView('personal')}
                                className="text-primary text-underlined p-0 pb-1 ps-1 btn-op70"
                            >
                                Click here
                            </Button>{' '}
                            to update it.
                        </h6>
                    </Alert>
                )}
                <ApplicationButtonBar
                    multi_button={this.props.multi_button}
                    submitInfo={(prop) => this.submitInfo(prop)}
                    validated={validated}
                    hide_save={check_personal_history ? 'N' : 'Y'}
                    with_signature={this.props.reference_check.Signed}
                />
            </div>
        );
    }
}
