import ConvertApi from 'convertapi-js'


export async function Convert_To_PDF(file) {
    try {

        const convertApi = ConvertApi.auth({ secret: 'xH4v9b4lGOJMoxlv' })
        const params = convertApi.createParams();
        params.add('file', file);
/*        params.add('Presets', 'web');*/
        params.add('StoreFile', 'True');
        const result = await convertApi.convert('pdf', 'compress', params);
        const blob = await fetch(result.dto.Files[0].Url)
            .then((r) => r.blob())
            .then((blobFile) => new File([blobFile], file.name, { type: file.type }));
        return blob;
    } catch (e) {
        console.log(e)
        return null;
    }
}
export async function Compress_Image(file) {
    try {
        let file_type = file.type.toString().replaceAll('image/', '').trim();

        const convertApi = ConvertApi.auth({ secret: 'xH4v9b4lGOJMoxlv' })
        const params = convertApi.createParams();
        
        params.add('file', file);
        const result = await convertApi.convert(file_type, 'jpg', params);
        const blob = await fetch(result.dto.Files[0].Url)
            .then((r) => r.blob())
            .then((blobFile) => new File([blobFile], file.name, { type: file.type }));
        var newFile = new File([blob], file.name.toLowerCase().replaceAll(`.${file_type}`, '.jpg'), { type: 'image/jpg', lastModified: Date.now() });
        const next_params = convertApi.createParams();

        next_params.add('file', newFile);
        const next_result = await convertApi.convert('jpg', 'compress', next_params);
        const next_blob = await fetch(next_result.dto.Files[0].Url)
            .then((r) => r.blob())
            .then((blobFile) => new File([blobFile], file.name, { type: file.type }));
        return next_blob;
      
       
    
  
    } catch (e) {
        console.log(e)
        return null;
    }
}