import React, { Component } from 'react';
import {
    Alert,
    Button,
    Col,
    Container,
    FloatingLabel,
    Form,
    InputGroup,
    OverlayTrigger,
    Row,
    Tooltip,
} from 'react-bootstrap';
import hide_password_icon from '../../media/hide_password.png';

export class CreateApplication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hide_password: true,
        };

        this.handleApplicationChange = this.handleApplicationChange.bind(this);
        this.createNewApplication = this.createNewApplication.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
    }
    async sendEmail(email, full_name) {
        try {
            const response = await fetch('mailgun/ApplicationStarted/' + email + '/' + full_name);
            response.json();
        } catch (e) {
            console.log(e);
        }
    }

    handleApplicationChange(e) {
        const updatedApplication = this.props.current_application;
        updatedApplication[e.target.name] = e.target.value;
        this.props.onupdate(updatedApplication);
    }

    async createNewApplication() {
        try {
            const currentApplication = this.props.current_application;
            this.props.click_button('save');
            await this.props.onsave();
            await this.sendEmail(
                currentApplication.Reviewed_By,
                currentApplication.First_Name + ' ' + currentApplication.Last_Name,
            );
            this.props.click_button('');
        } catch (e) {
            console.log(e);
        }
    }

    handleNameChange(e) {
        const updatedApplication = this.props.current_application;
        updatedApplication[e.target.name] = e.target.value;
        const first = updatedApplication.First_Name;
        const middle = updatedApplication.Middle_Name === '' ? '' : ' ' + updatedApplication.Middle_Name;
        const last = ' ' + updatedApplication.Last_Name;
        updatedApplication.Full_Name = first + middle + last;
        this.props.onupdate(updatedApplication);
    }

    handleKeyPress(e) {
        const currentApplication = this.props.current_application;
        if (currentApplication.Full_Name !== '') {
            if (currentApplication.Country !== '') {
                if (currentApplication.Email !== '') {
                    if (currentApplication.New_Password === currentApplication.Password) {
                        if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(currentApplication.Password)) {
                            if (/[A-Z]+$/.test(currentApplication.Password)) {
                                if (currentApplication.Password.length > 7) {
                                    if (e.key === 'Enter') {
                                        this.createNewApplication();
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    render() {
        const { hide_password } = this.state;
        const currentApplication = this.props.current_application;

        //#region Check Password for pattern
        let password_good = false;
        let password_tooltip =
            'Password must contain a minimum of 8 characters, 1 uppercase letter, and 1 special character.';
        if (currentApplication.Password.length > 7) {
            if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(currentApplication.Password)) {
                if (/[A-Z]/.test(currentApplication.Password)) {
                    password_good = true;
                    password_tooltip = '';
                } else {
                    password_good = false;
                    password_tooltip = 'Password must contain 1 uppercase letter.';
                }
            } else {
                if (/[A-Z]/.test(currentApplication.Password)) {
                    password_good = false;
                    password_tooltip = 'Password must contain 1 special character.';
                } else {
                    password_good = false;
                    password_tooltip = 'Password must contain 1 uppercase letter and 1 special character.';
                }
            }
        } else {
            if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(currentApplication.Password)) {
                if (/[A-Z]/.test(currentApplication.Password)) {
                    password_good = false;
                    password_tooltip = 'Password must be a minimum of 8 characters.';
                } else {
                    password_good = false;
                    password_tooltip = 'Password must be a minimum of 8 characters and contain 1 uppercase letter.';
                }
            } else {
                if (/[A-Z]/.test(currentApplication.Password)) {
                    password_good = false;
                    password_tooltip = 'Password must be a minimum of 8 characters and contain 1 special character.';
                } else {
                    password_good = false;
                    password_tooltip =
                        'Password must be a minimum of 8 characters, contain 1 uppercase letter, and 1 special character.';
                }
            }
        }
        //#endregion Check Password for pattern

        //#region Check form for validation
        let disable_login = true;
        let disable_login_title = '';
        if (currentApplication.Reviewed_By === '') {
            disable_login_title = 'Selecting a recruiter is required.';
            disable_login = true;
        } else if (currentApplication.First_Name === '') {
            disable_login_title = 'First Name is required.';
            disable_login = true;
        } else if (currentApplication.Last_Name === '') {
            disable_login_title = 'Last Name is required.';
            disable_login = true;
        } else if (currentApplication.Country === '') {
            disable_login_title = 'Country is required.';
            disable_login = true;
        } else if (!/[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(currentApplication.Email)) {
            disable_login_title = 'Email Address is not valid.';
            disable_login = true;
        } else if (currentApplication.Password !== currentApplication.New_Password) {
            disable_login_title = 'Passwords do not match.';
            disable_login = true;
        } else if (!password_good) {
            disable_login_title =
                'Password must contain a minimum of 8 characters, 1 uppercase letter, and 1 special character.';
            disable_login = true;
        } else {
            disable_login = false;
            disable_login_title = '';
        }
        //#endregion Check form for validation

        //#region Recruiter Form
        const recruiter_form = this.props.recruiters ? (
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel controlId="new-applicant-recruiter" label="Recruiter">
                    <Form.Select
                        controlId="new-applicant-recruiter"
                        variant="outline-secondary"
                        value={currentApplication.Reviewed_By}
                        name="Reviewed_By"
                        disabled={this.props.multi_button === 'save'}
                        onChange={(e) => this.handleApplicationChange(e)}
                        isValid={currentApplication.Reviewed_By !== ''}
                        isInvalid={currentApplication.Reviewed_By === ''}
                    >
                        <option value="">Select</option>
                        {this.props.recruiters
                            .filter((s) => s.Is_Active === 'Y')
                            .map((d, index) => (
                                <option value={d.Email} key={index}>
                                    {d.First_Name}
                                </option>
                            ))}
                        <option value="recruiting@scotlynn.com">No Recruiter</option>
                    </Form.Select>
                </FloatingLabel>
            </InputGroup>
        ) : null;
        //#endregion Recruiter Form

        //#region Name Form
        const name_form = (
            <div className="d-flex flex-column">
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="first-name-input" label="First Name">
                        <Form.Control
                            controlId="first-name-input"
                            variant="outline-secondary"
                            type="text"
                            maxLength={30}
                            disabled={this.props.multi_button === 'save'}
                            value={currentApplication.First_Name}
                            name="First_Name"
                            onChange={this.handleNameChange}
                            onKeyPress={this.handleKeyPress}
                            isInvalid={currentApplication.First_Name === ''}
                            isValid={currentApplication.First_Name !== ''}
                        />
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="middle-name-input" label="Middle Name (optional)">
                        <Form.Control
                            controlId="middle-name-input"
                            variant="outline-secondary"
                            type="text"
                            disabled={this.props.multi_button === 'save'}
                            maxLength={30}
                            value={currentApplication.Middle_Name}
                            name="Middle_Name"
                            onChange={this.handleNameChange}
                            onKeyPress={this.handleKeyPress}
                        />
                    </FloatingLabel>
                </InputGroup>
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="last-name-input" label="Last Name">
                        <Form.Control
                            controlId="last-name-input"
                            variant="outline-secondary"
                            type="text"
                            disabled={this.props.multi_button === 'save'}
                            maxLength={30}
                            value={currentApplication.Last_Name}
                            name="Last_Name"
                            onChange={this.handleNameChange}
                            onKeyPress={this.handleKeyPress}
                            isInvalid={currentApplication.Last_Name === ''}
                            isValid={currentApplication.Last_Name !== ''}
                        />
                    </FloatingLabel>
                </InputGroup>
            </div>
        );
        //#endregion Name Form

        //#region Country Form
        const country_form = (
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel controlId="new-applicant-country" label="Country of Residence">
                    <Form.Select
                        controlId="new-applicant-country"
                        variant="outline-secondary"
                        disabled={this.props.multi_button === 'save'}
                        value={currentApplication.Country}
                        name="Country"
                        onChange={(e) => this.handleApplicationChange(e)}
                        isValid={currentApplication.Country !== ''}
                        isInvalid={currentApplication.Country === ''}
                    >
                        <option value="">Select</option>
                        <option value="CA">Canada</option>
                        <option value="US">United States</option>
                    </Form.Select>
                </FloatingLabel>
            </InputGroup>
        );
        //#endregion Country Form

        //#region Email Form
        const email_form = (
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel controlId="email-input" label="Email Address">
                    <Form.Control
                        controlId="email-input"
                        variant="outline-secondary"
                        type="email"
                        maxLength={30}
                        value={currentApplication.Email}
                        name="Email"
                        disabled={this.props.multi_button === 'save'}
                        onChange={this.handleApplicationChange}
                        onKeyPress={this.handleKeyPress}
                        isInvalid={!/[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(currentApplication.Email)}
                        isValid={/[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(currentApplication.Email)}
                    />
                </FloatingLabel>
            </InputGroup>
        );
        //#endregion Email Form

        //#region Password Form
        const password_form = (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip hidden={password_good} id="password-check-tooltip">
                        {password_tooltip}
                    </Tooltip>
                }
            >
                <InputGroup className="mb-3 opacity70">
                    <FloatingLabel controlId="password-input" label="Password">
                        <Form.Control
                            autofill={false}
                            controlId="password-input"
                            variant="outline-secondary"
                            type={hide_password ? 'password' : 'text'}
                            maxLength={30}
                            disabled={this.props.multi_button === 'save'}
                            value={currentApplication.Password}
                            name="Password"
                            onChange={this.handleApplicationChange}
                            onKeyPress={this.handleKeyPress}
                            isInvalid={!password_good}
                            isValid={password_good}
                        />
                    </FloatingLabel>
                    <span class="input-group-text bg-transparent  border-1" id="basic-addon1">
                        <img
                            src={hide_password_icon}
                            style={{ width: '16px', height: '16px' }}
                            onClick={() => this.setState({ hide_password: !hide_password })}
                            alt=""
                        />
                    </span>
                </InputGroup>
            </OverlayTrigger>
        );
        //#endregion Password Form

        //#region Re-Password Form
        const re_password_form = (
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel controlId="retype-password-input" label="Re-Enter Password">
                    <Form.Control
                        controlId="retype-password-input"
                        variant="outline-secondary"
                        type={hide_password ? 'password' : 'text'}
                        maxLength={30}
                        disabled={this.props.multi_button === 'save'}
                        value={currentApplication.New_Password}
                        name="New_Password"
                        onChange={this.handleApplicationChange}
                        onKeyPress={this.handleKeyPress}
                        isInvalid={
                            currentApplication.New_Password !== currentApplication.Password ||
                            currentApplication.New_Password === '' ||
                            !password_good
                        }
                        isValid={
                            currentApplication.New_Password === currentApplication.Password &&
                            currentApplication.New_Password !== '' &&
                            password_good
                        }
                    />
                </FloatingLabel>
                <span class="input-group-text bg-transparent  border-1" id="basic-addon1">
                    <img
                        src={hide_password_icon}
                        style={{ width: '16px', height: '16px' }}
                        onClick={() => this.setState({ hide_password: !hide_password })}
                        alt=""
                    />
                </span>
            </InputGroup>
        );
        //#endregion Re-Password Form

        //#region Email Exists Alert
        const email_exists_alert =
            this.props.email_already_exists !== '' ? (
                <Alert variant="danger" className="d-flex flex-column">
                    <label style={{ alignSelf: 'center' }}>{this.props.email_already_exists}</label>
                </Alert>
            ) : null;
        //#endregion Email Exists Alert

        return (
            <Container fluid="xs" className="px-3">
                <Row className="mb-5 d-flex flex-row justify-content-start w-100">
                    <Col className="d-flex flex-row justify-content-start w-100">
                        <Button variant="secondary" className="btn-op70" onClick={() => this.props.clearApp()}>
                            Go Back
                        </Button>
                    </Col>
                </Row>
                <Row className="d-flex flex-row justify-content-center">
                    <Col xl={7} lg={7} med={10} sm={12} xs={12}>
                        {recruiter_form}
                    </Col>
                </Row>
                <Row className="d-flex flex-row justify-content-center">
                    <Col xl={7} lg={7} med={10} sm={12} xs={12}>
                        {name_form}
                    </Col>
                </Row>
                <Row className="d-flex flex-row justify-content-center">
                    <Col xl={7} lg={7} med={10} sm={12} xs={12}>
                        {country_form}
                    </Col>
                </Row>
                <Row className="d-flex flex-row justify-content-center">
                    <Col xl={7} lg={7} med={10} sm={12} xs={12}>
                        {email_form}
                    </Col>
                </Row>
                <Row className="d-flex flex-row justify-content-center">
                    <Col xl={7} lg={7} med={10} sm={12} xs={12}>
                        {password_form}
                    </Col>
                </Row>
                <Row className="d-flex flex-row justify-content-center">
                    <Col xl={7} lg={7} med={10} sm={12} xs={12}>
                        {re_password_form}
                    </Col>
                </Row>
                <Row className="d-flex flex-row justify-content-center">
                    <Col xl={7} lg={7} med={10} sm={12} xs={12}>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip hidden={!disable_login} id="report-error-tooltip">
                                    {disable_login_title}
                                </Tooltip>
                            }
                        >
                            <span
                                className="d-flex flex-row mt-3"
                                style={{ justifyContent: 'center', alignSelf: 'center' }}
                            >
                                <Button
                                    variant={this.props.multi_button === 'save' ? 'light' : 'success'}
                                    size="med"
                                    className="btn-op70 px-3 my-3"
                                    onClick={() => this.createNewApplication()}
                                    disabled={disable_login || this.props.multi_button === 'save'}
                                >
                                    {this.props.multi_button === 'save' ? 'Saving...' : 'Begin Application'}
                                </Button>
                            </span>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="d-flex flex-row justify-content-center">
                    <Col sm={12} xs={12}>
                        {email_exists_alert}
                    </Col>
                </Row>
            </Container>
        );
    }
}
