import React, { Component } from 'react';
import { Card, CloseButton, Col, Container, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import CustomButton from '../buttons/CustomButton';
import SelectInput from '../inputs/SelectInput';
import TextFieldInput from '../inputs/TextFieldInput';
import TextareaInput from '../inputs/TextareaInput';
import * as baseColors from '../utilities/BaseColors';

export class ReportApplicantIssue extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.handleInputChange = this.handleInputChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    handleInputChange = (e) => {
        var current_user = this.props.mailgun_user;
        current_user[e.target.name] = e.target.value;
        this.props.updateUser(JSON.stringify(current_user));
    };

    sendEmail() {
        this.props.click_error();
        this.props.sendEmail();
    }

    render() {
        let disabled_submit = true;
        let disabled_submit_title = '';

        if (this.props.mailgun_user.Country === '') {
            disabled_submit = true;
            disabled_submit_title = 'Country is required';
        } else if (this.props.mailgun_user.Name === '') {
            disabled_submit = true;
            disabled_submit_title = 'Full Name is required';
        } else if (this.props.mailgun_user.Email === '') {
            disabled_submit = true;
            disabled_submit_title = 'Email Address is required';
        } else if (this.props.mailgun_user.Issue === '') {
            disabled_submit = true;
            disabled_submit_title = 'Issue is required';
        } else {
            disabled_submit = false;
            disabled_submit_title = 'Submit Error Now';
        }

        const user_info = this.props.mailgun_user ? (
            <Card bg="light" className="mb-3 p-3 d-flex flex-column">
                <Form onSubmit={() => this.sendEmail()} className="d-flex flex-column">
                    <SelectInput
                        label="Country"
                        controlid="mailgun-user-country"
                        data={this.props.mailgun_user}
                        field_name="Country"
                        handleInputChange={(e) => this.handleInputChange(e)}
                        disabled="N"
                        bgColor={this.props.mailgun_user.Country === '' ? baseColors.light_red : 'white'}
                        options={[
                            { label: 'Canada', value: 'CA' },
                            { label: 'United States', value: 'US' },
                        ]}
                    />
                    <TextFieldInput
                        label="Full Name"
                        bgColor={this.props.mailgun_user.Name === '' ? baseColors.light_red : 'white'}
                        controlid="mailgun-user-name"
                        maxLength={60}
                        data={this.props.mailgun_user}
                        field_name="Name"
                        handleInputChange={(e) => this.handleInputChange(e)}
                        disabled="N"
                    />
                    <TextFieldInput
                        label="Email Address"
                        bgColor={this.props.mailgun_user.Email === '' ? baseColors.light_red : 'white'}
                        controlid="mailgun-user-email"
                        maxLength={50}
                        data={this.props.mailgun_user}
                        field_name="Email"
                        handleInputChange={(e) => this.handleInputChange(e)}
                        disabled="N"
                    />

                    <TextareaInput
                        label="Issue"
                        controlid="mailgun-user-issue"
                        maxLength={250}
                        data={this.props.mailgun_user}
                        field_name="Issue"
                        bgColor={this.props.mailgun_user.Issue === '' ? baseColors.light_red : 'white'}
                        handleInputChange={(e) => this.handleInputChange(e)}
                        disabled="N"
                        height="5rem"
                    />

                    {this.props.errorClicked ? (
                        <CustomButton
                            variant="light"
                            alignment="center"
                            button_title="Submitting..."
                            text_color="black"
                            disabled={true}
                        />
                    ) : (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip hidden={!disabled_submit} id="report-error-tooltip">
                                    {disabled_submit_title}
                                </Tooltip>
                            }
                        >
                            <span
                                className="d-flex flex-row mt-3"
                                style={{ justifyContent: 'center', alignSelf: 'center' }}
                            >
                                <CustomButton
                                    variant="success"
                                    alignment="center"
                                    button_title="Submit Issue"
                                    button_action={() => this.sendEmail()}
                                    text_color="white"
                                    disabled={disabled_submit}
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                </Form>
            </Card>
        ) : null;

        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    fullscreen="md-down"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    contentClassName="light-modal"
                >
                    <Modal.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <CloseButton style={{ width: '1.25rem' }} onClick={() => this.props.ignore()} />

                        <Modal.Title
                            id="contained-modal-title-vcenter"
                            className="align-items-center"
                            style={{ fontWeight: '600', textAlign: 'center', width: '100%', filter: 'opacity(0.7)' }}
                        >
                            <h4 style={{ alignSelf: 'end' }}>Report Issue</h4>
                        </Modal.Title>
                        <div style={{ width: '1.25rem' }}></div>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <Container>
                            <Row>
                                <Col xs={12}>{user_info}</Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
