import { FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import '../.././App.scss';

/**
 * TextFieldInput
 * params {label:string, controlId:string,maxLength:number, data:any, field_name:string, handleInputChange:function, disabled:(Y/N), bgColor:string}
 * returns Button
 */

function TextFieldInput({ ...props }) {
    return (
        <InputGroup className="mb-2 opacity70">
            <FloatingLabel controlId={props.controlid} label={props.label}>
                <Form.Control
                    controlId={props.controlid}
                    variant="outline-secondary"
                    type="text"
                    maxLength={props.maxLength}
                    value={props.data[props.field_name]}
                    name={props.field_name}
                    onChange={props.handleInputChange}
                    onKeyPress={props.handleKeyPress}
                    readOnly={props.disabled === 'Y'}
                    disabled={props.disabled === 'Y'}
                    style={{ backgroundColor: props.bgColor }}
                />
            </FloatingLabel>
        </InputGroup>
    );
}

export default TextFieldInput;
