import React, { useRef, useState } from 'react';
import { Button, Col, Container, Figure, FloatingLabel, Form, Image, InputGroup, Row } from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';
import { sendGetFetch } from '../application/FetchFunctions';
import TextSignature from '../inputs/TextSignature';
import { SignatureTerms } from './SignatureTerms';

export const Signature = ({ ...props }) => {
    const [editSignature, setEditSignature] = useState(props.signature.App_id <= 0);
    const [showDisclosure, setShowDisclosure] = useState(false);
    const sigCanvas = useRef(null);
    const textCanvas = useRef(null);
    const clear = () => sigCanvas.current.clear();

    function updateSignature(e) {
        const c_signature = props.signature;
        c_signature[e.target.name] = e.target.value;
        props.handleUpdate(JSON.stringify(c_signature));
    }

    async function save() {
        props.click_save();
        const c_signature = props.signature;
        if (c_signature.Signature_Type === 'generated_signature') {
            if (textCanvas.current !== null) {
                c_signature.File_Bit_Array = textCanvas.current.toDataURL('img/png');
            }
        } else {
            if (sigCanvas.current !== null) {
                c_signature.File_Bit_Array = sigCanvas.current.toDataURL('img/png');
            }
        }
        c_signature.File_Name = 'signature_' + props.app_id + '.png';
        c_signature.App_id = props.app_id;
        await sendGetFetch('attachment/GetAttachment/signature_' + props.app_id + '.png/' + props.app_id).then(
            (res) => {
                if (res.App_id === 0) {
                    props.onPost(JSON.stringify(c_signature));
                    props.click_clear();
                } else {
                    props.onPut(JSON.stringify(c_signature));
                    props.click_clear();
                }
            },
        );
    }

    async function deleteSignature() {
        props.click_delete();
        await props.onDelete();
    }

    const generated_signature =
        props.signature.Signature_Type === 'generated_signature' ? (
            <TextSignature font="Brush Script MT, cursive" textCanvas={textCanvas} full_name={props.full_name} />
        ) : null;
    const signature_pad =
        props.signature.Signature_Type === 'signature_pad' ? (
            <SignaturePad ref={sigCanvas} canvasProps={{ className: 'signature-canvas' }} />
        ) : null;

    //#region Signature Selection Row
    const signature_selection_row = (
        <Row className="mb-4">
            <Col xs={12}>
                <InputGroup className="mb-2 opacity70">
                    <FloatingLabel controlId="signature-method" label="Signature Method">
                        <Form.Select
                            disabled={!editSignature}
                            controlId="signature-method"
                            variant="outline-secondary"
                            value={props.signature.Signature_Type}
                            name="Signature_Type"
                            onChange={updateSignature}
                            isInvalid={props.signature.Signature_Type === ''}
                            isValid={props.signature.Signature_Type !== ''}
                        >
                            <option value="">Select a Signature Method</option>
                            <option value="signature_pad">Signature Pad</option>
                            <option value="generated_signature">Generated Signature</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>
            </Col>
        </Row>
    );
    //#endregion Signature Selection Row

    const clear_button =
        props.signature.Signature_Type === 'generated_signature' ? null : (
            <Button variant="secondary" className="btn-op70" style={{ width: '49%' }} onClick={clear}>
                Clear
            </Button>
        );

    const edit_button = editSignature ? (
        clear_button
    ) : (
        <Button
            style={{ width: '49%' }}
            variant="secondary"
            className="btn-op70"
            onClick={() => setEditSignature(true)}
        >
            Edit
        </Button>
    );

    const delete_button = editSignature ? (
        <Button
            variant="danger"
            className="btn-op70"
            style={{ width: '49%' }}
            onClick={() => {
                setEditSignature(false);
            }}
        >
            Cancel
        </Button>
    ) : (
        <Button
            variant={props.button_clicked === 'delete' ? 'outline-danger' : 'danger'}
            className="btn-op70"
            style={{ width: '49%' }}
            disabled={props.signature.File_Bit_Array === '' || props.button_clicked === 'delete'}
            onClick={() => deleteSignature()}
        >
            {props.button_clicked === 'delete' ? 'Deleting...' : 'Delete'}
        </Button>
    );

    return (
        <Container fluid="xs">
            {props.signature_consent.Gave_Consent === 'Y' ? null : (
                <Row className="mb-5">
                    <Col>
                        <h5 className="mb-3">
                            Before creating a signature, please view and agree to the the Signature Consent Disclosure
                        </h5>
                    </Col>
                </Row>
            )}
            <Row className="mb-5">
                <Col>
                    {showDisclosure ? (
                        <Button
                            variant="link"
                            onClick={() => setShowDisclosure(false)}
                            className="text-underlined btn-op70 mb-3"
                        >
                            Close Signature Consent Disclosure
                        </Button>
                    ) : (
                        <Button
                            variant="link"
                            onClick={() => setShowDisclosure(true)}
                            className="text-underlined btn-op70 mb-3"
                        >
                            View Signature Consent Disclosure
                        </Button>
                    )}
                </Col>
            </Row>

            <Row className="mb-5">
                <Col>
                    {showDisclosure ? (
                        <SignatureTerms
                            postConsent={() => props.onPostConsent()}
                            signature_consent={props.signature_consent}
                            full_name={props.full_name}
                            updateConsent={(prop) => props.onUpdateConsent(prop)}
                            app_id={props.app_id}
                        />
                    ) : null}
                </Col>
            </Row>

            {props.signature_consent.Gave_Consent === 'Y' ? (
                <Row className="d-flex flex-row justify-content-center mb-3">
                    <Col xl={7} lg={7} med={10} sm={12} xs={12}>
                        {signature_selection_row}
                    </Col>
                </Row>
            ) : null}

            {props.signature_consent.Gave_Consent === 'Y' ? (
                <Row className="d-flex flex-row justify-content-center mb-3">
                    {props.signature.File_Name !== '' ? (
                        editSignature ? (
                            <Col xs={12}>
                                {generated_signature}
                                {signature_pad}
                            </Col>
                        ) : (
                            <Col xs={12}>
                                <Figure className="d-flex flex-column">
                                    <Image
                                        rounded
                                        fluid
                                        src={props.signature.File_Bit_Array}
                                        className="signature-image"
                                    />
                                    <Figure.Caption>
                                        <small>Created on: {props.signature.Date_Create}</small>
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                        )
                    ) : (
                        <Col xs={12}>
                            {editSignature ? generated_signature : null}
                            {editSignature ? signature_pad : null}
                        </Col>
                    )}
                </Row>
            ) : null}
            {props.signature_consent.Gave_Consent === 'Y' ? (
                <Row className="d-flex flex-row justify-content-between">
                    <Col xs={12} sm={12} med={2} lg={6} xl={6}>
                        <div className="d-flex flex-row justify-content-between w-100">
                            {props.signature.File_Name !== '' ? edit_button : clear_button}
                            {props.signature.File_Name !== '' ? delete_button : null}
                        </div>
                    </Col>

                    <Col xs={12} sm={12} med={8} lg={6} xl={6}>
                        <Row className="d-flex flex-row justify-content-end">
                            <Col xs={12} sm={12} med={12} lg={6} xl={6} className="d-flex flex-row justify-content-end">
                                <Button
                                    variant={props.button_clicked === 'save' ? 'outline-success' : 'success'}
                                    className="btn-op70 w-100 my-2"
                                    disabled={props.button_clicked === 'save'}
                                    onClick={() => save()}
                                >
                                    {props.button_clicked === 'save' ? 'Saving...' : 'Save & Continue'}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : null}
        </Container>
    );
};
