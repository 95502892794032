import { FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import '../.././App.scss';

/**
 * SelectInput
 * params {label:string, controlid:string,data:any, field_name:string, handleInputChange:function, disabled:(Y/N), options:[], bgColor:string }
 * returns Button
 */

function SelectInput({ ...props }) {
    const return_form = props.options ? (
        <InputGroup className="mb-2 opacity70">
            <FloatingLabel controlId={props.controlid} label={props.label}>
                <Form.Select
                    controlId={props.controlid}
                    variant="outline-secondary"
                    value={props.data[props.field_name]}
                    name={props.field_name}
                    onChange={props.handleInputChange}
                    readOnly={props.disabled === 'Y'}
                    disabled={props.disabled === 'Y'}
                    style={{ backgroundColor: props.bgColor }}
                >
                    <option value="">Select</option>
                    {props.options.map((d, index) => (
                        <option value={d.value} key={d.key}>
                            {d.label}
                        </option>
                    ))}
                </Form.Select>
            </FloatingLabel>
        </InputGroup>
    ) : null;
    return return_form;
}

export default SelectInput;
