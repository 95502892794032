import React, { Component } from 'react';
import { FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { sendGetFetch } from '../application/FetchFunctions';
import ApplicationButtonBar from '../buttons/ApplicationButtonBar';

export class EmergencyContact extends Component {
    constructor(props) {
        super(props);
        const primary_phone =
            this.props.emergency_contact.App_id > 0 && this.props.emergency_contact.Phone !== ''
                ? this.props.emergency_contact.Phone.split('-')
                : '';
        const p_1 = primary_phone.length >= 1 ? primary_phone[0] : '';
        const p_2 = primary_phone.length >= 2 ? primary_phone[1] : '';
        const p_3 = primary_phone.length >= 3 ? primary_phone[2] : '';
        this.state = {
            validated: false,
            changed: false,
            phone_1: p_1,
            phone_2: p_2,
            phone_3: p_3,
        };
        this.handleApplicationChange = this.handleApplicationChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.submitInfo = this.submitInfo.bind(this);
        this.handlePrimaryPhone = this.handlePrimaryPhone.bind(this);
    }

    handleApplicationChange(e) {
        const current_info = this.props.emergency_contact;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
        }
        current_info[e.target.name] = e.target.value;
        this.props.onupdate(current_info);
        this.validateForm();
    }

    handlePrimaryPhone(e) {
        const current_info = this.props.emergency_contact;
        let s_1 = this.state.phone_1;
        let s_2 = this.state.phone_2;
        let s_3 = this.state.phone_3;
        if (e.target.name === 'phone_1') {
            if (e.target.value.toString().length < 4) {
                s_1 = e.target.value;
                this.setState({ phone_1: s_1 });
            } else {
                s_2 = e.target.value[3];
                this.setState({ phone_2: e.target.value[3] });
                const next_field = document.querySelector(`input[name=phone_2]`);
                next_field.focus();
            }
        } else if (e.target.name === 'phone_2') {
            if (e.target.value.toString().length < 4) {
                s_2 = e.target.value;
                this.setState({ phone_2: s_2 });
            } else {
                s_3 = e.target.value[3];
                this.setState({ phone_3: e.target.value[3] });
                const next_field = document.querySelector(`input[name=phone_3]`);
                next_field.focus();
            }
        } else if (e.target.name === 'phone_3') {
            if (e.target.value.toString().length < 5) {
                s_3 = e.target.value;
                this.setState({ phone_3: s_3 });
            }
        }
        current_info.Phone = s_1 + '-' + s_2 + '-' + s_3;
        this.props.onupdate(current_info);
        this.validateForm();
    }

    validateForm() {
        const emergencyContact = this.props.emergency_contact;
        let is_valid = false;
        if (
            emergencyContact.Full_Name !== '' &&
            emergencyContact.Phone !== '' &&
            emergencyContact.Relationship !== ''
        ) {
            is_valid = true;
        }

        this.setState({ validated: is_valid });
    }

    async submitInfo(button) {
        this.props.setMultiButton(button);
        let next_view = 'emergency';
        if (button === 'back') {
            next_view = 'personal';
        } else {
            next_view = 'physical';
        }
        const v_emergency_contact = await sendGetFetch('emergencycontact/' + this.props.app_id);
        if (v_emergency_contact.Id === 0) {
            this.props.onPost();
        } else {
            if (v_emergency_contact !== this.props.emergency_contact) {
                this.props.onPut();
            }
        }

        this.props.setMultiButton('');
        this.props.setView(next_view);
    }

    render() {
        const { validated, phone_1, phone_2, phone_3 } = this.state;

        //#region Full Name
        const full_name = (
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel controlId="full-name" label="Full Name">
                    <Form.Control
                        type="text"
                        variant="outline-secondary"
                        value={this.props.emergency_contact.Full_Name}
                        name="Full_Name"
                        onChange={this.handleApplicationChange}
                        isValid={this.props.emergency_contact.Full_Name !== ''}
                        isInvalid={this.props.emergency_contact.Full_Name === ''}
                        controlId="full-name"
                    ></Form.Control>
                </FloatingLabel>
            </InputGroup>
        );
        //#endregion Full Name

        //#region Phone
        const phone = (
            <InputGroup className="mb-3 opacity70 p-0">
                <div
                    controlId="primary-phone"
                    className="d-flex flex-column form-floating form-control"
                    style={{ justifyContent: 'flex-start' }}
                >
                    <small className="text-start text-muted pb-1">Phone</small>
                    <InputGroup controlId="primary-phone">
                        <Form.Control
                            type="number"
                            variant="outline-secondary"
                            value={phone_1}
                            name="phone_1"
                            onChange={this.handlePrimaryPhone}
                            isValid={phone_1.toString().length === 3}
                            isInvalid={phone_1.toString().length !== 3}
                            controlId="primary-phone"
                            placeholder="000"
                            max={999}
                            className="text-center"
                        ></Form.Control>
                        <label className="px-1 py-0" style={{ alignSelf: 'center' }}>
                            -
                        </label>
                        <Form.Control
                            type="number"
                            variant="outline-secondary"
                            value={phone_2}
                            name="phone_2"
                            onChange={this.handlePrimaryPhone}
                            isValid={phone_2.toString().length === 3}
                            isInvalid={phone_2.toString().length !== 3}
                            controlId="primary-phone"
                            placeholder="000"
                            max={999}
                            className="text-center"
                        ></Form.Control>
                        <label className="px-1 py-0" style={{ alignSelf: 'center' }}>
                            -
                        </label>
                        <Form.Control
                            type="number"
                            variant="outline-secondary"
                            value={phone_3}
                            name="phone_3"
                            onChange={this.handlePrimaryPhone}
                            isValid={phone_3.toString().length === 4}
                            isInvalid={phone_3.toString().length !== 4}
                            controlId="primary-phone"
                            placeholder="0000"
                            max={9999}
                            className="text-center"
                        ></Form.Control>
                    </InputGroup>
                </div>
            </InputGroup>
        );
        //#endregion Phone

        //#region Relationship
        const relationship = (
            <InputGroup className="mb-3 opacity70">
                <FloatingLabel controlId="relationship" label="Relationship">
                    <Form.Control
                        type="text"
                        variant="outline-secondary"
                        value={this.props.emergency_contact.Relationship}
                        name="Relationship"
                        onChange={this.handleApplicationChange}
                        isValid={this.props.emergency_contact.Relationship !== ''}
                        isInvalid={this.props.emergency_contact.Relationship === ''}
                        controlId="relationship"
                    ></Form.Control>
                </FloatingLabel>
            </InputGroup>
        );
        //#endregion Relationship

        return (
            <div className="d-flex flex-column">
                {full_name}
                {phone}
                {relationship}

                <ApplicationButtonBar
                    multi_button={this.props.multi_button}
                    submitInfo={(prop) => this.submitInfo(prop)}
                    validated={validated}
                />
            </div>
        );
    }
}
