export const msalConfig = {
    auth: {
        clientId: '37367ef3-cc73-4b2a-a845-ab73b7a208a8',
        authority: 'https://login.microsoftonline.com/6e656c29-cc78-41f6-a482-7968f203ef0d/',
        redirectUri: '/admin',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const protectedResources = {
    adminResources: {
        scopes: ['api://37367ef3-cc73-4b2a-a845-ab73b7a208a8/log-admin-in'],
    },
};

export const loginRequest = {
    scopes: [...protectedResources.adminResources.scopes],
};

export const appRoles = {
    Admin: 'title.Admin',
};
