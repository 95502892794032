import React, { Component } from 'react';
import { FloatingLabel, Form, InputGroup } from 'react-bootstrap';
import { sendGetFetch } from '../application/FetchFunctions';
import ApplicationButtonBar from '../buttons/ApplicationButtonBar';

export class PhysicalHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validated: false,
            changed: false,
        };
        this.handleApplicationChange = this.handleApplicationChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.submitInfo = this.submitInfo.bind(this);
    }

    handleApplicationChange(e) {
        const current_info = this.props.physical_history;
        if (current_info.App_id === 0) {
            current_info.App_id = this.props.app_id;
        }
        current_info[e.target.name] = e.target.value;
        this.props.onupdate(current_info);
        this.validateForm();
    }

    validateForm() {
        const physicalHistory = this.props.physical_history;
        let manual_work_valid = false;
        let injured_valid = false;
        let is_valid = false;

        if (physicalHistory.Manual_Work !== '') {
            if (physicalHistory.Manual_Work === 'N') {
                if (physicalHistory.Manual_Work_Reason !== '') {
                    manual_work_valid = true;
                } else {
                    manual_work_valid = false;
                }
            } else {
                manual_work_valid = true;
            }
        }

        if (physicalHistory.Injured_On_Job !== '') {
            if (physicalHistory.Injured_On_Job === 'Y') {
                if (physicalHistory.Injured_Reason !== '') {
                    injured_valid = true;
                } else {
                    injured_valid = false;
                }
            } else {
                injured_valid = true;
            }
        }

        if (manual_work_valid && injured_valid) {
            is_valid = true;
        } else {
            is_valid = false;
        }

        this.setState({ validated: is_valid });
    }

    async submitInfo(button) {
        this.props.setMultiButton(button);
        let next_view = 'physical';
        if (button === 'back') {
            next_view = 'emergency';
        } else {
            next_view = 'employers';
        }
        const v_physical_history = await sendGetFetch('physicalhistory/' + this.props.app_id);
        if (v_physical_history.Id === 0) {
            this.props.onPost();
        } else {
            if (v_physical_history !== this.props.physical_history) {
                this.props.onPut();
            }
        }
        this.props.setMultiButton('');
        this.props.setView(next_view);
    }

    render() {
        const { validated } = this.state;

        //#region Capable of manual work?
        const manual_work = (
            <div className="d-block">
                <InputGroup className="mb-2 opacity70">
                    <FloatingLabel controlId="manual-work" label="Capable of manual work?">
                        <Form.Select
                            variant="outline-secondary"
                            value={this.props.physical_history.Manual_Work}
                            name="Manual_Work"
                            onChange={this.handleApplicationChange}
                            isValid={this.props.physical_history.Manual_Work !== ''}
                            isInvalid={this.props.physical_history.Manual_Work === ''}
                            controlId="manual-work"
                        >
                            <option value="">Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>
                {this.props.physical_history.Manual_Work === 'N' ? (
                    <InputGroup className="mb-2 opacity70">
                        <FloatingLabel controlId="manual-work-reason" label="Explanation for 'No'">
                            <Form.Control
                                type="textarea"
                                variant="outline-secondary"
                                value={this.props.physical_history.Manual_Work_Reason}
                                name="Manual_Work_Reason"
                                onChange={this.handleApplicationChange}
                                isValid={this.props.physical_history.Manual_Work_Reason !== ''}
                                isInvalid={this.props.physical_history.Manual_Work_Reason === ''}
                                controlId="manual-work-reason"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>
                ) : null}
            </div>
        );
        //#endregion Capable of manual work?

        //#region Have been injured on the job?
        const injured = (
            <div className="d-block">
                <InputGroup className="mb-2 opacity70">
                    <FloatingLabel controlId="injured-job" label="Have been injured on the job?">
                        <Form.Select
                            variant="outline-secondary"
                            value={this.props.physical_history.Injured_On_Job}
                            name="Injured_On_Job"
                            onChange={this.handleApplicationChange}
                            isValid={this.props.physical_history.Injured_On_Job !== ''}
                            isInvalid={this.props.physical_history.Injured_On_Job === ''}
                            controlId="injured-job"
                        >
                            <option value="">Select</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </Form.Select>
                    </FloatingLabel>
                </InputGroup>
                {this.props.physical_history.Injured_On_Job === 'Y' ? (
                    <InputGroup className="mb-2 opacity70">
                        <FloatingLabel controlId="injured-job-reason" label="Explanation for 'Yes'">
                            <Form.Control
                                as="textarea"
                                variant="outline-secondary"
                                value={this.props.physical_history.Injured_Reason}
                                name="Injured_Reason"
                                onChange={this.handleApplicationChange}
                                isValid={this.props.physical_history.Injured_Reason !== ''}
                                isInvalid={this.props.physical_history.Injured_Reason === ''}
                                controlId="injured-job-reason"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>
                ) : null}
            </div>
        );
        //#endregion Have been injured on the job?

        //#region Other Issues
        const other_issues = (
            <InputGroup className="mb-2 opacity70">
                <FloatingLabel controlId="other-issues" label="Other Issues">
                    <Form.Control
                        type="text"
                        variant="outline-secondary"
                        value={this.props.physical_history.Other_Issues}
                        name="Other_Issues"
                        onChange={this.handleApplicationChange}
                        controlId="other-issues"
                    ></Form.Control>
                </FloatingLabel>
            </InputGroup>
        );
        //#endregion Other Issues

        return (
            <div className="d-flex flex-column">
                {manual_work}
                {injured}
                {other_issues}
                <ApplicationButtonBar
                    multi_button={this.props.multi_button}
                    submitInfo={(prop) => this.submitInfo(prop)}
                    validated={validated}
                />
            </div>
        );
    }
}
