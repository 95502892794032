import { MsalContext } from '@azure/msal-react';
import { differenceInDays, format } from 'date-fns';
import React, { Component } from 'react';
import { Accordion, Button, Card, Col, Container, FloatingLabel, Form, InputGroup, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PDFApplication } from './application/PDFApplication';
import { sendDeleteFetch, sendGetFetch, sendPostFetch, sendPutFetch } from './utilities/FetchFunctions';

//#region Initial Values
import {
    initial_accident,
    initial_agreement,
    initial_application,
    initial_background,
    initial_driver_check,
    initial_drug_screening,
    initial_education,
    initial_emergency,
    initial_employer,
    initial_experience,
    initial_fair_credit,
    initial_gap,
    initial_general,
    initial_license,
    initial_personal,
    initial_physical,
    initial_recruiter,
    initial_reference,
    initial_signature,
    initial_status,
    initial_truck,
    initial_violation,
} from './dtos/InitialValues';
//#endregion Initial Values

export class Admin extends Component {
    static displayName = Admin.name;
    static contextType = MsalContext;
    constructor(props) {
        super(props);
        this.state = {
            applications: [],
            app_id: 0,
            current_application: initial_application,
            general_info: initial_general,
            //#region Attachments
            driver_license_file: undefined,
            driver_license_file_back: undefined,
            truck_front_file: undefined,
            truck_back_file: undefined,
            truck_left_file: undefined,
            truck_right_file: undefined,
            //#endregion Attachments
            loading: true,
            truck_info: initial_truck,
            personal_history: initial_personal,
            emergency_contact: initial_emergency,
            education: initial_education,
            physical_history: initial_physical,
            employers: [],
            add_employer: initial_employer,
            edit_employer: initial_employer,
            employment_gaps: [],
            add_gap: initial_gap,
            edit_gap: initial_gap,
            license_info: initial_license,
            experience: initial_experience,
            accidents: [],
            add_accident: initial_accident,
            edit_accident: initial_accident,
            traffic_violations: [],
            add_violation: initial_violation,
            edit_violation: initial_violation,
            drug_screening: initial_drug_screening,
            application_agreement: initial_agreement,
            reference_check: initial_reference,
            fair_credit: initial_fair_credit,
            driver_check: initial_driver_check,
            background_check: initial_background,
            error_message: '',
            signature: initial_signature,
            status_id: initial_status,
            application_loading: true,
            reason_closed: '',
            recruiters: [],
            edit_recruiter: initial_recruiter,
            new_recruiter: initial_recruiter,
            recruiter_button: '',
            show_add_recruiter: false,
            viewer_event: '',
            search_in_progress: '',
            search_completed: '',
            search_closed: '',
        };

        this.closeApplication = this.closeApplication.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.reOpenApplication = this.reOpenApplication.bind(this);
        this.handleEditRecruiter = this.handleEditRecruiter.bind(this);
        this.handleNewRecruiter = this.handleNewRecruiter.bind(this);
        this.handleViewerEvent = this.handleViewerEvent.bind(this);
        this.markComplete = this.markComplete.bind(this);
        this.markIncomplete = this.markIncomplete.bind(this);
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    async handleViewerEvent(id) {
        try {
            const current_event = this.state.viewer_event;
            if (current_event === id) {
                this.setState({ viewer_event: '', application_loading: true });
                this.clearApplication();
            } else {
                this.setState({ viewer_event: id, application_loading: true });
                await this.clearApplication();
            }
        } catch (e) {
            console.log(e);
        }
    }

    async validateLogin() {
        const account = this.context.accounts;
        try {
            if (account.length > 0) {
                const current_account = {
                    isAuthenticated: false,
                    tenantId: account[0].tenantId,
                    roles: account[0].idTokenClaims.roles,
                };
                const response = await fetch('api/Login/Admin', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(current_account),
                });
                const data = await response.json();
                if (data.status === 401) {
                    return 'Unauthorized';
                } else {
                    return data.token;
                }
            } else {
                return 'Unauthorized';
            }
        } catch (e) {
            return 'Unauthorized';
        }
    }

    async componentDidMount() {
        try {
            this.validateLogin().then((auth) =>
                fetch('driverapp/GetAllApplications', { method: 'GET', headers: { Authorization: 'Bearer ' + auth } })
                    .then((res) => {
                        return res.json();
                    })
                    .then((data) => {
                        this.setState({ applications: data });
                        fetch('recruiter/GetRecruiters', {
                            method: 'GET',
                            headers: { Authorization: 'Bearer ' + auth },
                        })
                            .then((recruiter_res) => {
                                return recruiter_res.json();
                            })
                            .then((recruiter_data) => {
                                this.setState({
                                    recruiters: recruiter_data.sort((a, b) => a.First_Name.localeCompare(b.First_Name)),
                                    loading: false,
                                });
                            });
                    }),
            );
        } catch (e) {
            console.log(e);
            this.setState({ applications: [], loading: false, recruiters: [] });
        }
    }

    //#region Recruiter Functions
    async updateRecruiter() {
        this.setState({ recruiter_button: 'edit' });
        try {
            await this.validateLogin().then((auth) => {
                const options = {
                    method: 'PUT',
                    headers: {
                        Authorization: 'Bearer ' + auth,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.state.edit_recruiter),
                };
                fetch('recruiter', options)
                    .then((res) => {
                        return res.json();
                    })
                    .then((data) => {
                        const current_recruiters = this.state.recruiters.filter(
                            (s) => s.Id !== this.state.edit_recruiter.Id,
                        );
                        current_recruiters.push(this.state.edit_recruiter);
                        this.setState({
                            recruiters: current_recruiters.sort((a, b) => a.First_Name.localeCompare(b.First_Name)),
                            edit_recruiter: {
                                Id: 0,
                                First_Name: '',
                                Last_Name: '',
                                Country: '',
                                Email: '',
                                Phone: '',
                                Is_Active: '',
                            },
                            recruiter_button: '',
                        });
                    });
            });
        } catch (e) {
            this.setState({ recruiter_button: '' });
        }
    }

    async addRecruiter() {
        this.setState({ recruiter_button: 'add' });
        try {
            await this.validateLogin().then((auth) => {
                const options = {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + auth,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.state.new_recruiter),
                };
                fetch('recruiter', options)
                    .then((res) => {
                        return res.json();
                    })
                    .then((data) => {
                        const current_recruiters = this.state.recruiters;
                        current_recruiters.push(data);
                        this.setState({
                            recruiters: current_recruiters.sort((a, b) => a.First_Name.localeCompare(b.First_Name)),
                            new_recruiter: {
                                Id: 0,
                                First_Name: '',
                                Last_Name: '',
                                Country: '',
                                Email: '',
                                Phone: '',
                                Is_Active: '',
                            },
                            recruiter_button: '',
                        });
                    });
            });
        } catch (e) {
            this.setState({ recruiter_button: '' });
        }
    }

    async deleteRecruiter(d) {
        this.setState({ recruiter_button: 'delete' });
        try {
            if (window.confirm('Are you sure you want to delete this recruiter?')) {
                await this.validateLogin().then((auth) => {
                    const options = {
                        method: 'DELETE',
                        headers: {
                            Authorization: 'Bearer ' + auth,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(d),
                    };
                    fetch('recruiter', options)
                        .then((res) => {
                            return res.json();
                        })
                        .then((data) => {
                            const current_recruiters = this.state.recruiters.filter((s) => s.Id !== d.Id);
                            this.setState({
                                recruiters: current_recruiters.sort((a, b) => a.First_Name.localeCompare(b.First_Name)),
                                recruiter_button: '',
                            });
                        });
                });
            } else {
                this.props.setMultiButton('');
            }
        } catch (e) {
            this.props.setMultiButton('');
        }
    }
    //#endregion Recruiter Functions

    async clearApplication() {
        this.setState({
            app_id: 0,
            application_loading: true,
            current_application: {
                Country: '',
                Date_Reviewed: '',
                Date_Started: '',
                Email: '',
                Full_Name: '',
                Hired: 0,
                Hired_By: '',
                Id: 0,
                New_Password: '',
                Password: '',
                Reviewed_By: '',
                Status_id: '',
                Date_Submitted: '',
                Application_Open: '',
                Position: '',
                First_Name: '',
                Middle_Name: '',
                Last_Name: '',
            },
        });

        return 'Cleared';
    }

    async GetCurrentUser(current_app) {
        if (this.state.app_id === current_app.Id) {
            this.setState({
                app_id: -1,
                current_application: initial_application,
                general_info: initial_general,
                driver_license_file: undefined,
                abstract_file: undefined,
                cvor_file: undefined,
                border_crossing_id: undefined,
                truck_info: initial_truck,
                personal_history: initial_personal,
                emergency_contact: initial_emergency,
                education: initial_education,
                physical_history: initial_physical,
                employers: [],
                add_employer: initial_employer,
                edit_employer: initial_employer,
                employment_gaps: [],
                add_gap: initial_gap,
                edit_gap: initial_gap,
                license_info: initial_license,
                experience: initial_experience,
                accidents: [],
                add_accident: initial_accident,
                edit_accident: initial_accident,
                traffic_violations: [],
                add_violation: initial_violation,
                edit_violation: initial_violation,
                drug_screening: initial_drug_screening,
                application_agreement: initial_agreement,
                reference_check: initial_reference,
                fair_credit: initial_fair_credit,
                driver_check: initial_driver_check,
                background_check: initial_background,
                error_message: '',
                signature: initial_signature,
                status_id: initial_status,
                reason_closed: '',
                application_loading: true,
            });
        } else {
            this.setState({ app_id: current_app.Id, current_application: current_app, reason_closed: '' });
            await this.populateApplication(current_app.Id, current_app.Email);
        }
    }

    async populateGeneralInfo(app_id) {
        await sendGetFetch('generalinfo/GetGeneralInfo/' + app_id).then((res) => {
            if (res === 'open issue') {
                this.setState({ general_info: initial_general });
            } else {
                this.setState({ general_info: res.Id === 0 ? initial_general : res });
                if (res.License_Copy_id !== '' && res.License_Copy_id !== null) {
                    sendGetFetch('attachment/GetAttachment/' + res.License_Copy_id + '/' + app_id).then((data) => {
                        if (data === 'open issue') {
                            this.setIssue('Could not load Front License Image.');
                            this.setState({ driver_license_file: undefined });
                        } else {
                            this.setState({ driver_license_file: data.File_Bit_Array });
                        }
                    });
                } else {
                    this.setState({ driver_license_file: undefined });
                }
                if (res.License_Copy_id_Back !== '' && res.License_Copy_id_Back !== null) {
                    sendGetFetch('attachment/GetAttachment/' + res.License_Copy_id_Back + '/' + app_id).then((data) => {
                        if (data === 'open issue') {
                            this.setIssue('Could not load Back License Image.');
                            this.setState({ driver_license_file_back: undefined });
                        } else {
                            this.setState({ driver_license_file_back: data.File_Bit_Array });
                        }
                    });
                } else {
                    this.setState({ driver_license_file_back: undefined });
                }
            }
        });
    }

    async populateTruckInfo(app_id) {
        await sendGetFetch('truckinfo/GetTruckInfo/' + app_id).then((res) => {
            if (res === 'open issue') {
                this.setIssue('Could not load Truck Information.');
            } else {
                if (res.Id === 0) {
                    this.setState({ truck_info: initial_truck });
                } else {
                    this.setState({ truck_info: res });
                    if (res.Front_Image_id !== '' && res.Front_Image_id !== null) {
                        sendGetFetch('attachment/GetAttachment/' + res.Front_Image_id + '/' + app_id).then((data) => {
                            if (data === 'open issue') {
                                this.setIssue('Could not load front image of truck.');
                                this.setState({ truck_front_file: undefined });
                            } else {
                                this.setState({ truck_front_file: data.File_Bit_Array });
                            }
                        });
                    } else {
                        this.setState({ truck_front_file: undefined });
                    }
                    if (res.Back_Image_id !== '' && res.Back_Image_id !== null) {
                        sendGetFetch('attachment/GetAttachment/' + res.Back_Image_id + '/' + app_id).then((data) => {
                            if (data === 'open issue') {
                                this.setIssue('Could not load back image of truck.');
                                this.setState({ truck_back_file: undefined });
                            } else {
                                this.setState({ truck_back_file: data.File_Bit_Array });
                            }
                        });
                    } else {
                        this.setState({ truck_back_file: undefined });
                    }
                    if (res.Right_Image_id !== '' && res.Right_Image_id !== null) {
                        sendGetFetch('attachment/GetAttachment/' + res.Right_Image_id + '/' + app_id).then((data) => {
                            if (data === 'open issue') {
                                this.setIssue('Could not load right image of truck.');
                                this.setState({ truck_right_file: undefined });
                            } else {
                                this.setState({ truck_right_file: data.File_Bit_Array });
                            }
                        });
                    } else {
                        this.setState({ truck_right_file: undefined });
                    }
                    if (res.Left_Image_id !== '' && res.Left_Image_id !== null) {
                        sendGetFetch('attachment/GetAttachment/' + res.Left_Image_id + '/' + app_id).then((data) => {
                            if (data === 'open issue') {
                                this.setIssue('Could not load left image of truck.');
                                this.setState({ truck_left_file: undefined });
                            } else {
                                this.setState({ truck_left_file: data.File_Bit_Array });
                            }
                        });
                    } else {
                        this.setState({ truck_left_file: undefined });
                    }
                }
            }
        });
    }

    async populateApplication(d) {
        try {
            await this.clearApplication().then((res) => {
                this.setState({
                    app_id: d.id,
                    application_loading: true,
                    current_application: d,
                });
            });

            const app_id = d.Id;
            const email = d.Email;
            await sendGetFetch('attachment/GetAttachment/signature_' + app_id + '.png/' + app_id).then((res) => {
                this.setState({ signature: res.App_id === 0 ? initial_signature : res });
            });
            await this.populateGeneralInfo(app_id);
            await this.populateTruckInfo(app_id);
            await sendGetFetch('personalhistory/GetPersonalHistory/' + app_id).then((res) =>
                this.setState({ personal_history: res.Id === 0 ? initial_personal : res }),
            );
            await sendGetFetch('emergencycontact/GetEmergencyContact/' + app_id).then((res) =>
                this.setState({ emergency_contact: res.Id === 0 ? initial_emergency : res }),
            );
            await sendGetFetch('education/GetEducation/' + app_id).then((res) =>
                this.setState({ education: res.Id === 0 ? initial_education : res }),
            );
            await sendGetFetch('physicalhistory/GetPhysicalHistory/' + app_id).then((res) =>
                this.setState({ physical_history: res.Id === 0 ? initial_physical : res }),
            );
            await sendGetFetch('employers/GetEmployers/' + app_id).then((res) => {
                if (res.length > 0) {
                    const newest = res
                        .filter((s) => s.Current_Employer === 'Y')
                        .sort((a, b) => new Date(a.Hire_Date) - new Date(b.Hire_Date));
                    const oldest = res
                        .filter((s) => s.Current_Employer === 'N')
                        .sort((a, b) => new Date(a.Terminate_Date) - new Date(b.Terminate_Date));
                    this.setState({ employers: oldest.concat(newest) });
                } else {
                    this.setState({ employers: [] });
                }
            });
            await sendGetFetch('employmentgap/GetGap/' + app_id).then((res) => this.setState({ employment_gaps: res }));
            await sendGetFetch('licenseinfo/GetLicenseInfo/' + app_id).then((res) =>
                this.setState({ license_info: res.Id === 0 ? initial_license : res }),
            );
            await sendGetFetch('experience/GetExperience/' + app_id).then((res) =>
                this.setState({ experience: res.Id === 0 ? initial_experience : res }),
            );
            await sendGetFetch('accident/GetAccidents/' + app_id).then((res) => this.setState({ accidents: res }));
            await sendGetFetch('trafficviolations/GetViolations/' + app_id).then((res) =>
                this.setState({ traffic_violations: res }),
            );
            await sendGetFetch('driverapp/GetApplicationStatus/' + app_id + '/' + email).then((res) =>
                this.setState({ view_page: res.View_Page, status_id: res, login_finished: true }),
            );
            await sendGetFetch('applicationagreement/GetApplicationAgreement/' + app_id).then((res) =>
                this.setState({ application_agreement: res.Id === 0 ? initial_agreement : res }),
            );
            await sendGetFetch('referencecheck/GetReferenceCheck/' + app_id).then((res) =>
                this.setState({ reference_check: res.Id === 0 ? initial_reference : res }),
            );
            await sendGetFetch('backgroundreport/GetBackgroundReport/' + app_id).then((res) =>
                this.setState({ background_check: res.Id === 0 ? initial_background : res }),
            );

            await this.application_finished().then((res) => {
                console.log(res);
            });
        } catch (e) {
            console.log(e);
        }
    }

    async application_finished() {
        this.setState({ application_loading: false });
        return 'finished';
    }

    async closeApplication(current_app) {
        try {
            await this.clearApplication();
            const application = current_app;
            current_app.Application_Open = 'N';
            await sendPutFetch(JSON.stringify(application), 'driverapp/CloseApplication').then((data) => {
                let inx = -1;
                const current_list = this.state.applications;
                for (let i = 0; i < current_list.length; i++) {
                    if (current_list[i].Id === application.Id) {
                        inx = i;
                    }
                }
                if (inx > -1) {
                    current_list[inx] = application;
                }
                this.setState({ applications: current_list, viewer_event: '' });
            });
        } catch (e) {
            console.log(e);
        }
    }

    async reOpenApplication(current_app) {
        try {
            await this.clearApplication();
            const application = current_app;
            current_app.Application_Open = 'Y';
            await sendPutFetch(JSON.stringify(current_app), 'driverapp/OpenApplication').then((data) => {
                let inx = -1;
                const current_list = this.state.applications;
                for (let i = 0; i < current_list.length; i++) {
                    if (current_list[i].Id === application.Id) {
                        inx = i;
                    }
                }
                if (inx > -1) {
                    current_list[inx] = application;
                }
                this.setState({ applications: current_list, viewer_event: '' });
            });
        } catch (e) {
            console.log(e);
        }
    }

    async markComplete(current_app) {
        try {
            await this.clearApplication();
            const application = current_app;
            application.Date_Submitted = format(new Date(), 'M/d/yyyy hh:mm:ss a');
            const response = await fetch('driverapp/MarkComplete/' + application.Id + '/Y');
            await response.json();
            let inx = -1;
            const current_list = this.state.applications;
            for (let i = 0; i < current_list.length; i++) {
                if (current_list[i].Id === application.Id) {
                    inx = i;
                }
            }
            if (inx > -1) {
                current_list[inx] = application;
            }
            this.setState({ applications: current_list, viewer_event: '' });
        } catch (e) {
            console.log(e);
        }
    }

    async markIncomplete(current_app) {
        try {
            await this.clearApplication();
            const application = current_app;
            application.Date_Submitted = '';
            const response = await fetch('driverapp/MarkComplete/' + application.Id + '/N');
            await response.json();
            const subject = 'Scotlynn Driver Application Re-Opened';
            const messageText =
                'Your application has been re-opened and can now be editted. \nVisit https://driver.scotlynn.app/apply to access your application.';
            const recipients = [
                {
                    emailAddress: {
                        address: application.Email,
                    },
                },
            ];
            const message_fields = {
                subject: subject,
                body: messageText,
                recipients: recipients,
            };
            this.SendEmail(message_fields);

            let inx = -1;
            const current_list = this.state.applications;
            for (let i = 0; i < current_list.length; i++) {
                if (current_list[i].Id === application.Id) {
                    inx = i;
                }
            }
            if (inx > -1) {
                current_list[inx] = application;
            }
            this.setState({ applications: current_list, viewer_event: '' });
        } catch (e) {
            console.log(e);
        }
    }

    handleEditRecruiter(e) {
        const current_recruiter = this.state.edit_recruiter;
        if (e.target.name === 'Is_Active') {
            current_recruiter.Is_Active = 'Y';
        } else if (e.target.name === 'Is_Not_Active') {
            current_recruiter.Is_Active = 'N';
        } else {
            current_recruiter[e.target.name] = e.target.value;
        }

        this.setState({ edit_recruiter: current_recruiter });
    }

    handleNewRecruiter(e) {
        const current_recruiter = this.state.new_recruiter;
        if (e.target.name === 'Is_Active') {
            current_recruiter.Is_Active = 'Y';
        } else if (e.target.name === 'Is_Not_Active') {
            current_recruiter.Is_Active = 'N';
        } else {
            current_recruiter[e.target.name] = e.target.value;
        }
        this.setState({ new_recruiter: current_recruiter });
    }

    SendEmail(fields) {
        const instance = this.context.instance;
        const accounts = this.context.accounts;
        try {
            const body = JSON.stringify({
                message: {
                    subject: fields.subject,
                    body: {
                        contentType: 'Text',
                        content: fields.body,
                    },
                    toRecipients: fields.recipients,
                },
            });
            if (accounts.length > 0) {
                const request = {
                    account: accounts[0],
                    scopes: ['Mail.Send'],
                };
                instance
                    .acquireTokenSilent(request)
                    .then((response) => {
                        const headers = new Headers();
                        const bearer = `Bearer ${response.accessToken}`;
                        headers.append('Authorization', bearer);
                        headers.append('Content-Type', 'application/json');
                        headers.append('client-request-id', '8e9a239e-6f6a-16b6-1f90-d14732bcb97d');
                        headers.append('cache-control', 'no-cache');
                        const options = { method: 'POST', headers: headers, body: body };
                        fetch('https://graph.microsoft.com/v1.0/me/sendMail', options)
                            .then((o) => {
                                toast.success('Email Sent');
                                return o;
                            })
                            .catch((error) => {
                                toast.error('Email Failed');
                                return error;
                            });
                    })
                    .catch((error) => {
                        return error;
                    });
            } else {
                return 'Could not locate AD account.';
            }
        } catch (e) {
            return e;
        }
    }

    render() {
        const {
            applications,
            app_id,
            current_application,
            signature,
            general_info,
            truck_info,
            personal_history,
            emergency_contact,
            education,
            physical_history,
            employers,
            employment_gaps,
            license_info,
            experience,
            accidents,
            traffic_violations,
            application_agreement,
            reference_check,
            fair_credit,
            background_check,
            loading,
            application_loading,
            recruiters,
            edit_recruiter,
            recruiter_button,
            show_add_recruiter,
            driver_license_file,
            driver_license_file_back,
            truck_front_file,
            truck_back_file,
            truck_left_file,
            truck_right_file,
            viewer_event,
            search_in_progress,
            search_completed,
            search_closed,
        } = this.state;

        //#region View Application
        const view_application = !application_loading ? (
            <Card className="text-center mt-3">
                <Card.Header as="h4" className="p-3">
                    Application Viewer
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                    <Card.Title as="h5">{current_application.Full_Name} </Card.Title>
                    {current_application ? (
                        <PDFApplication
                            current_application={current_application}
                            signature={signature.File_Bit_Array}
                            general_info={general_info}
                            truck_info={truck_info}
                            personal_history={personal_history}
                            emergency_contact={emergency_contact}
                            education={education}
                            physical_history={physical_history}
                            employers={employers}
                            employment_gaps={employment_gaps}
                            license_info={license_info}
                            experience={experience}
                            accidents={accidents}
                            traffic_violations={traffic_violations}
                            application_agreement={application_agreement}
                            reference_check={reference_check}
                            fair_credit={fair_credit}
                            background_check={background_check}
                            driver_license_file={driver_license_file}
                            driver_license_file_back={driver_license_file_back}
                            truck_front_file={truck_front_file}
                            truck_back_file={truck_back_file}
                            truck_left_file={truck_left_file}
                            truck_right_file={truck_right_file}
                            is_applicant="N"
                        />
                    ) : null}
                </Card.Body>
                <Card.Footer className="text-muted p-3"></Card.Footer>
            </Card>
        ) : (
            <Card className="text-center mt-3">
                <Card.Header as="h4" className="p-3">
                    Loading...
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                    <Card.Title as="h5">Gather application fields... </Card.Title>
                </Card.Body>
                <Card.Footer className="text-muted p-3"></Card.Footer>
            </Card>
        );
        //#endregion View Application

        //#region Recruiters List

        const recruiters_list = loading ? (
            <Card className="text-center mt-2">
                <Card.Header>
                    <h6 className="w-100 text-center text-muted pt-2">Loading...</h6>
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                    <Card.Title as="h5">Gather recruiters... </Card.Title>
                </Card.Body>
                <Card.Footer className="text-muted p-3"></Card.Footer>
            </Card>
        ) : (
            <Card className="text-center mt-2">
                <Card.Header>
                    <h6 className="w-100 text-center text-secondary pt-2">Recruiters</h6>
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                    <Accordion defaultActiveKey="0" style={{ maxHeight: '30vh', overflow: 'auto' }}>
                        {recruiters.map((d, index) => (
                            <Accordion.Item eventKey={d.Id} key={d.Id}>
                                <Accordion.Header>
                                    <div
                                        className="d-flex flex-row pe-2"
                                        style={{ justifyContent: 'space-between', width: '100%' }}
                                    >
                                        <strong className="text-small">{d.First_Name + ' ' + d.Last_Name}</strong>
                                        <small
                                            className="text-small"
                                            style={d.Is_Active === 'Y' ? { color: '#28a745' } : { color: '#332D2D' }}
                                        >
                                            {d.Is_Active === 'Y' ? 'Active' : 'Inactive'}
                                        </small>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className="d-flex flex-column text-color" style={{ textAlign: 'left' }}>
                                    <label className="p-2 text-small">
                                        <strong>First Name:</strong>
                                        <bdi className="ms-2">{d.First_Name}</bdi>
                                    </label>
                                    <label className="p-2 text-small">
                                        <strong>Last Name:</strong>
                                        <bdi className="ms-2">{d.Last_Name}</bdi>
                                    </label>
                                    <label className="p-2 text-small">
                                        <strong>Country:</strong>
                                        <bdi className="ms-2">{d.Country === 'CA' ? 'Canada' : 'US'}</bdi>
                                    </label>
                                    <label className="p-2 text-small">
                                        <strong>Email:</strong>
                                        <bdi className="ms-2">{d.Email}</bdi>
                                    </label>
                                    <label className="p-2 text-small">
                                        <strong>Phone:</strong>
                                        <bdi className="ms-2">{d.Phone}</bdi>
                                    </label>
                                    <div className="d-flex flex-row mt-3" style={{ alignSelf: 'center' }}>
                                        <Button
                                            variant={recruiter_button === 'delete' ? 'light' : 'danger'}
                                            style={{ width: '130px' }}
                                            className="btn-op70 me-3"
                                            disabled={recruiter_button === 'delete'}
                                            onClick={async () => await this.deleteRecruiter(d)}
                                            size="sm"
                                        >
                                            Delete
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            className="btn-op70 ms-1"
                                            style={{ width: '130px' }}
                                            onClick={() =>
                                                this.setState({
                                                    edit_recruiter: d,
                                                    show_add_recruiter: false,
                                                    new_recruiter: {
                                                        Id: 0,
                                                        First_Name: '',
                                                        Last_Name: '',
                                                        Country: '',
                                                        Email: '',
                                                        Phone: '',
                                                        Is_Active: '',
                                                    },
                                                    app_id: 0,
                                                    application_loading: true,
                                                })
                                            }
                                            size="sm"
                                        >
                                            Edit
                                        </Button>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                    {show_add_recruiter ? (
                        <Button
                            variant="secondary"
                            onClick={() =>
                                this.setState({
                                    show_add_recruiter: false,
                                    edit_recruiter: {
                                        Id: 0,
                                        First_Name: '',
                                        Last_Name: '',
                                        Country: '',
                                        Email: '',
                                        Phone: '',
                                        Is_Active: '',
                                    },
                                    new_recruiter: {
                                        Id: 0,
                                        First_Name: '',
                                        Last_Name: '',
                                        Country: '',
                                        Email: '',
                                        Phone: '',
                                        Is_Active: '',
                                    },
                                })
                            }
                            className="btn-op70 my-3"
                            size="sm"
                        >
                            Hide Form
                        </Button>
                    ) : (
                        <Button
                            variant="success"
                            onClick={() =>
                                this.setState({
                                    show_add_recruiter: true,
                                    edit_recruiter: {
                                        Id: 0,
                                        First_Name: '',
                                        Last_Name: '',
                                        Country: '',
                                        Email: '',
                                        Phone: '',
                                        Is_Active: '',
                                    },
                                    new_recruiter: {
                                        Id: 0,
                                        First_Name: '',
                                        Last_Name: '',
                                        Country: '',
                                        Email: '',
                                        Phone: '',
                                        Is_Active: '',
                                    },
                                    app_id: 0,
                                    application_loading: true,
                                })
                            }
                            className="btn-op70 mt-3"
                            size="sm"
                        >
                            {show_add_recruiter ? 'Hide Form' : '+ Add New Recruiter'}
                        </Button>
                    )}
                </Card.Body>
            </Card>
        );
        //#endregion Recruiters List

        //#region Edit Recruiter Form
        const edit_recruiter_form = (
            <Card className="text-center mt-3">
                <Card.Header className="p-3">Edit Recruiter</Card.Header>
                <Card.Body className="d-flex flex-column">
                    <InputGroup className="mb-3 opacity70">
                        <Form.Check
                            type="radio"
                            className="btn-op70"
                            label="Active"
                            variant="outline-secondary"
                            checked={this.state.edit_recruiter.Is_Active === 'Y'}
                            name="Is_Active"
                            onClick={this.handleEditRecruiter}
                            isInvalid={this.state.edit_recruiter.Is_Active === ''}
                            controlId="recruiter-is-active"
                        ></Form.Check>
                        <Form.Check
                            type="radio"
                            label="Inactive"
                            className="btn-op70 ms-5"
                            variant="outline-secondary"
                            checked={this.state.edit_recruiter.Is_Active === 'N'}
                            name="Is_Not_Active"
                            onClick={this.handleEditRecruiter}
                            isInvalid={this.state.edit_recruiter.Is_Active === ''}
                            controlId="recruiter-is-not-active"
                        ></Form.Check>
                    </InputGroup>
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="recruiter-first-name" label="First Name">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                value={this.state.edit_recruiter.First_Name}
                                name="First_Name"
                                onChange={this.handleEditRecruiter}
                                isValid={this.state.edit_recruiter.First_Name !== ''}
                                isInvalid={this.state.edit_recruiter.First_Name === ''}
                                controlId="recruiter-first-name"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>

                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="recruiter-last-name" label="Last Name">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                value={this.state.edit_recruiter.Last_Name}
                                name="Last_Name"
                                onChange={this.handleEditRecruiter}
                                isValid={this.state.edit_recruiter.Last_Name !== ''}
                                isInvalid={this.state.edit_recruiter.Last_Name === ''}
                                controlId="recruiter-last-name"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>

                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="recruiter-country" label="Country">
                            <Form.Select
                                variant="outline-secondary"
                                value={this.state.edit_recruiter.Country}
                                name="Country"
                                onChange={this.handleEditRecruiter}
                                isValid={this.state.edit_recruiter.Country !== ''}
                                isInvalid={this.state.edit_recruiter.Country === ''}
                                controlId="recruiter-country"
                            >
                                <option value="">Select</option>
                                <option value="CA">Canada</option>
                                <option value="US">US</option>
                            </Form.Select>
                        </FloatingLabel>
                    </InputGroup>

                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="recruiter-email" label="Email">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                value={this.state.edit_recruiter.Email}
                                name="Email"
                                onChange={this.handleEditRecruiter}
                                isValid={this.state.edit_recruiter.Email !== ''}
                                isInvalid={this.state.edit_recruiter.Email === ''}
                                controlId="recruiter-email"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>

                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="recruiter-phone" label="Phone">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                value={this.state.edit_recruiter.Phone}
                                name="Phone"
                                onChange={this.handleEditRecruiter}
                                isValid={this.state.edit_recruiter.Phone !== ''}
                                isInvalid={this.state.edit_recruiter.Phone === ''}
                                controlId="recruiter-phone"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>

                    <InputGroup
                        className="my-3 opacity70 d-flex flex-row"
                        style={{ justifyContent: 'center', alignSelf: 'center' }}
                    >
                        <Button
                            style={{ width: '85px' }}
                            variant="danger"
                            onClick={() => this.setState({ edit_recruiter: initial_recruiter })}
                            className="btn-op70"
                            size="sm"
                        >
                            Cancel
                        </Button>
                        <Button
                            style={{ width: '85px' }}
                            variant={recruiter_button === 'edit' ? 'light' : 'success'}
                            onClick={async () => await this.updateRecruiter()}
                            className="btn-op70"
                            size="sm"
                        >
                            {recruiter_button === 'edit' ? 'Saving...' : 'Save'}
                        </Button>
                    </InputGroup>
                </Card.Body>
            </Card>
        );
        //#endregion Edit Recruiter Form

        //#region Add Recruiter Form
        const add_recruiter_form = (
            <Card className="text-center mt-3">
                <Card.Header className="p-3">Add Recruiter</Card.Header>
                <Card.Body className="d-flex flex-column">
                    <InputGroup className="mb-3 opacity70 ">
                        <Form.Check
                            type="radio"
                            className="btn-op70"
                            label="Active"
                            variant="outline-secondary"
                            checked={this.state.new_recruiter.Is_Active === 'Y'}
                            name="Is_Active"
                            onClick={this.handleNewRecruiter}
                            isInvalid={this.state.new_recruiter.Is_Active === ''}
                            controlId="recruiter-is-active"
                        ></Form.Check>
                        <Form.Check
                            type="radio"
                            label="Inactive"
                            className="btn-op70 ms-5"
                            variant="outline-secondary"
                            checked={this.state.new_recruiter.Is_Active === 'N'}
                            name="Is_Not_Active"
                            onClick={this.handleNewRecruiter}
                            isInvalid={this.state.new_recruiter.Is_Active === ''}
                            controlId="recruiter-is-not-active"
                        ></Form.Check>
                    </InputGroup>
                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="recruiter-first-name" label="First Name">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                value={this.state.new_recruiter.First_Name}
                                name="First_Name"
                                onChange={this.handleNewRecruiter}
                                isValid={this.state.new_recruiter.First_Name !== ''}
                                isInvalid={this.state.new_recruiter.First_Name === ''}
                                controlId="recruiter-first-name"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>

                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="recruiter-last-name" label="Last Name">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                value={this.state.new_recruiter.Last_Name}
                                name="Last_Name"
                                onChange={this.handleNewRecruiter}
                                isValid={this.state.new_recruiter.Last_Name !== ''}
                                isInvalid={this.state.new_recruiter.Last_Name === ''}
                                controlId="recruiter-last-name"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>

                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="recruiter-country" label="Country">
                            <Form.Select
                                variant="outline-secondary"
                                value={this.state.new_recruiter.Country}
                                name="Country"
                                onChange={this.handleNewRecruiter}
                                isValid={this.state.new_recruiter.Country !== ''}
                                isInvalid={this.state.new_recruiter.Country === ''}
                                controlId="recruiter-country"
                            >
                                <option value="">Select</option>
                                <option value="CA">Canada</option>
                                <option value="US">US</option>
                            </Form.Select>
                        </FloatingLabel>
                    </InputGroup>

                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="recruiter-email" label="Email">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                value={this.state.new_recruiter.Email}
                                name="Email"
                                onChange={this.handleNewRecruiter}
                                isValid={this.state.new_recruiter.Email !== ''}
                                isInvalid={this.state.new_recruiter.Email === ''}
                                controlId="recruiter-email"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>

                    <InputGroup className="mb-3 opacity70">
                        <FloatingLabel controlId="recruiter-phone" label="Phone">
                            <Form.Control
                                type="text"
                                variant="outline-secondary"
                                value={this.state.new_recruiter.Phone}
                                name="Phone"
                                onChange={this.handleNewRecruiter}
                                isValid={this.state.new_recruiter.Phone !== ''}
                                isInvalid={this.state.new_recruiter.Phone === ''}
                                controlId="recruiter-phone"
                            ></Form.Control>
                        </FloatingLabel>
                    </InputGroup>

                    <Button
                        variant={recruiter_button === 'add' ? 'light' : 'success'}
                        onClick={async () => await this.addRecruiter()}
                        className="btn-op70 my-3"
                        size="sm"
                    >
                        {recruiter_button === 'add' ? 'Adding...' : 'Add'}
                    </Button>
                </Card.Body>
            </Card>
        );
        //#endregion Add Recruiter Form

        //#region In-Progress Applications
        const in_progress_applications = applications
            ? applications.filter(
                  (s) =>
                      s.Application_Open === 'Y' &&
                      !s.Date_Submitted &&
                      s.Full_Name.toLowerCase().includes(search_in_progress.toLowerCase()),
              )
            : [];
        const in_progress_list = (
            <Card className="text-center mt-3">
                <Card.Header>
                    <h6 className="text-danger w-100 text-center pt-2">
                        In-Progress ({in_progress_applications.length})
                    </h6>
                </Card.Header>
                <Card.Body style={{ maxHeight: '35vh', overflow: 'auto' }}>
                    <Form.Control
                        type="text"
                        placeholder="Search By Name..."
                        className="mb-2"
                        value={search_in_progress}
                        onChange={(e) =>
                            this.setState({
                                search_in_progress: e.target.value,
                                search_closed: '',
                                search_completed: '',
                            })
                        }
                    />
                    <Accordion activeKey={viewer_event}>
                        {in_progress_applications.map((d, index) => (
                            <Accordion.Item eventKey={d.Id} key={d.Id}>
                                <Accordion.Header onClick={() => this.handleViewerEvent(d.Id)}>
                                    <div
                                        className="d-flex flex-row w-100 pe-3"
                                        style={{ justifyContent: 'space-between' }}
                                    >
                                        <strong className="me-auto text-small">{d.Full_Name}</strong>
                                        <small className="text-end font-italic text-danger text-xs">
                                            {differenceInDays(new Date(), new Date(d.Date_Started)) + ' Days'}
                                        </small>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Container fluid="xs" className="d-flex flex-column px-3">
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Full Name:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <bdi className="w-100 text-start text-small">{d.Full_Name}</bdi>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Cell Phone:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                {d.Cell_Phone ? (
                                                    <a
                                                        href={'tel:' + d.Cell_Phone.replaceAll('-', '')}
                                                        className="w-100 text-start text-small text-underlined text-primary"
                                                    >
                                                        {d.Cell_Phone}
                                                    </a>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Email:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <a
                                                    href={'mailto:' + d.Email}
                                                    className="w-100 text-start text-small text-underlined text-primary"
                                                >
                                                    {d.Email}
                                                </a>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Position:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <bdi className="w-100 text-start text-small">{d.Position}</bdi>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Recruiter:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <bdi className="w-100 text-start text-small">{d.Reviewed_By}</bdi>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row mt-4 justify-content-around">
                                            <Col className="d-flex flex-row" xs={6}>
                                                <Button
                                                    variant="warning"
                                                    title={'Move application to Completed list'}
                                                    onClick={() => this.markComplete(d)}
                                                    className="btn-op70 w-100 text-small"
                                                    size="sm"
                                                >
                                                    Mark as Completed
                                                </Button>
                                            </Col>
                                            <Col className="d-flex flex-row" xs={6}>
                                                {app_id < 1 ? (
                                                    <Button
                                                        variant="success"
                                                        className="btn-op70 w-100 text-small text-white"
                                                        onClick={async () => await this.populateApplication(d)}
                                                        size="sm"
                                                    >
                                                        View Application
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="outline-success"
                                                        className="btn-op70 w-100 text-small"
                                                        onClick={() => this.clearApplication()}
                                                        size="sm"
                                                    >
                                                        Close Viewer
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Card.Body>
            </Card>
        );
        //#endregion In-Progress Applications

        //#region Submitted Applications
        const new_completed_applications = applications
            ? applications.filter(
                  (s) =>
                      s.Application_Open === 'Y' &&
                      s.Date_Submitted &&
                      s.Full_Name.toLowerCase().includes(search_completed.toLowerCase()),
              )
            : [];
        const applicants_list = (
            <Card className="text-center mt-3">
                <Card.Header>
                    <h6 className="text-success w-100 text-center pt-2">
                        Submitted ({new_completed_applications.length})
                    </h6>
                </Card.Header>
                <Card.Body style={{ maxHeight: '35vh', overflow: 'auto' }}>
                    <Form.Control
                        type="text"
                        placeholder="Search By Name..."
                        className="mb-2"
                        value={search_completed}
                        onChange={(e) =>
                            this.setState({
                                search_in_progress: '',
                                search_closed: '',
                                search_completed: e.target.value,
                            })
                        }
                    />
                    <Accordion activeKey={viewer_event}>
                        {new_completed_applications.map((d, index) => (
                            <Accordion.Item eventKey={d.Id} key={d.Id}>
                                <Accordion.Header onClick={() => this.handleViewerEvent(d.Id)}>
                                    <div
                                        className="d-flex flex-row w-100 pe-3"
                                        style={{ justifyContent: 'space-between' }}
                                    >
                                        <strong className="me-auto text-small">{d.Full_Name}</strong>
                                        <small className="text-end font-italic text-muted text-xs">
                                            {d.Date_Submitted}
                                        </small>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Container fluid="xs" className="d-flex flex-column px-3">
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Full Name:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <bdi className="w-100 text-start text-small">{d.Full_Name}</bdi>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Cell Phone:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                {d.Cell_Phone ? (
                                                    <a
                                                        href={'tel:' + d.Cell_Phone.replaceAll('-', '')}
                                                        className="w-100 text-start text-small text-underlined text-primary"
                                                    >
                                                        {d.Cell_Phone}
                                                    </a>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Email:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <a
                                                    href={'mailto:' + d.Email}
                                                    className="w-100 text-start text-small text-underlined text-primary"
                                                >
                                                    {d.Email}
                                                </a>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Position:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <bdi className="w-100 text-start text-small">{d.Position}</bdi>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Recruiter:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <bdi className="w-100 text-start text-small">{d.Reviewed_By}</bdi>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row mt-4 justify-content-around">
                                            <Col className="d-flex flex-row " xs={4}>
                                                <Button
                                                    variant="warning"
                                                    disabled={d.Date_Submitted === ''}
                                                    title={
                                                        d.Date_Submitted === ''
                                                            ? 'Cannot mark In-progress applications as reviewed.'
                                                            : 'Move application to Reviewed list'
                                                    }
                                                    onClick={() => this.closeApplication(d)}
                                                    className="btn-op70 w-100 text-small"
                                                    size="sm"
                                                >
                                                    Move to Reviewed
                                                </Button>
                                            </Col>
                                            <Col className="d-flex flex-row " xs={4}>
                                                <Button
                                                    variant="warning"
                                                    title={'Move application to In-Progress list'}
                                                    onClick={() => this.markIncomplete(d)}
                                                    className="btn-op70 w-100 text-small"
                                                    size="sm"
                                                >
                                                    Move to In-Progress
                                                </Button>
                                            </Col>

                                            <Col className="d-flex flex-row" xs={4}>
                                                {app_id < 1 ? (
                                                    <Button
                                                        variant="success"
                                                        className="btn-op70 w-100 text-small text-white"
                                                        onClick={async () => await this.populateApplication(d)}
                                                        size="sm"
                                                    >
                                                        View Application
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="outline-success"
                                                        className="btn-op70 w-100 text-small"
                                                        onClick={() => this.clearApplication()}
                                                        size="sm"
                                                    >
                                                        Close Viewer
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Card.Body>
            </Card>
        );
        //#endregion Open Applications

        //#region Reviewed Applications
        const closed_applications = applications
            ? applications.filter(
                  (s) => s.Application_Open === 'N' && s.Full_Name.toLowerCase().includes(search_closed.toLowerCase()),
              )
            : [];
        const closed_applicants_list = (
            <Card className="text-center mt-3">
                <Card.Header>
                    <h6 className="text-muted w-100 text-center pt-2">Reviewed ({closed_applications.length})</h6>
                </Card.Header>
                <Card.Body style={{ maxHeight: '35vh', overflow: 'auto' }}>
                    <Form.Control
                        type="text"
                        placeholder="Search By Name..."
                        className="mb-2"
                        value={search_closed}
                        onChange={(e) =>
                            this.setState({
                                search_in_progress: '',
                                search_closed: e.target.value,
                                search_completed: '',
                            })
                        }
                    />
                    <Accordion activeKey={viewer_event}>
                        {closed_applications.map((d, index) => (
                            <Accordion.Item eventKey={d.Id} key={d.Id}>
                                <Accordion.Header onClick={() => this.handleViewerEvent(d.Id)}>
                                    <div
                                        className="d-flex flex-row w-100 pe-3"
                                        style={{ justifyContent: 'space-between' }}
                                    >
                                        <strong className="me-auto text-small">{d.Full_Name}</strong>
                                        <small className="text-end text-xs font-italic">
                                            {d.Date_Submitted === '' ? 'In-progress' : d.Date_Submitted}
                                        </small>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Container fluid="xs" className="d-flex flex-column px-3">
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Full Name:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <bdi className="w-100 text-start text-small">{d.Full_Name}</bdi>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Cell Phone:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                {d.Cell_Phone ? (
                                                    <a
                                                        href={'tel:' + d.Cell_Phone.replaceAll('-', '')}
                                                        className="w-100 text-start text-small text-underlined text-primary"
                                                    >
                                                        {d.Cell_Phone}
                                                    </a>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Email:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <a
                                                    href={'mailto:' + d.Email}
                                                    className="w-100 text-start text-small text-underlined text-primary"
                                                >
                                                    {d.Email}
                                                </a>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Position:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <bdi className="w-100 text-start text-small">{d.Position}</bdi>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row">
                                            <Col xs={4} className="d-flex flex-row">
                                                <bdi className="text-secondary align-self-start text-start w-100 small-strong">
                                                    Recruiter:
                                                </bdi>
                                            </Col>
                                            <Col xs={8} className="d-flex flex-row">
                                                <bdi className="w-100 text-start text-small">{d.Reviewed_By}</bdi>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex flex-row mt-4 justify-content-around">
                                            <Col className="d-flex flex-row " xs={6}>
                                                <Button
                                                    variant="warning"
                                                    onClick={() => this.reOpenApplication(d)}
                                                    className="btn-op70 w-100 text-small"
                                                    size="sm"
                                                >
                                                    Mark as New
                                                </Button>
                                            </Col>
                                            <Col className="d-flex flex-row" xs={6}>
                                                {app_id < 1 ? (
                                                    <Button
                                                        variant="success"
                                                        className="btn-op70 w-100 text-small text-white"
                                                        onClick={async () => await this.populateApplication(d)}
                                                        size="sm"
                                                    >
                                                        View Application
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="outline-success"
                                                        className="btn-op70 w-100 text-small"
                                                        onClick={() => this.clearApplication()}
                                                        size="sm"
                                                    >
                                                        Close Viewer
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Card.Body>
            </Card>
        );
        //#endregion Closed Applications

        //#region Loading Card
        const loading_card = (
            <Card className="text-center mt-3">
                <Card.Header className="p-3">Loading...</Card.Header>
                <Card.Body className="d-flex flex-column">
                    <Card.Title as="h5">Finding current applications... </Card.Title>
                </Card.Body>
                <Card.Footer className="text-muted p-3"></Card.Footer>
            </Card>
        );
        //#endregion Loading Card

        return (
            <div className="d-block mt-3">
                <ToastContainer position="top-center" limit={1} />
                <Container fluid="xs" className="mb-3 ms-3">
                    <Row>
                        <Col xs={12} lg={3} xl={3}>
                            {recruiters_list}
                        </Col>
                        <Col xs={12} lg={9} xl={9}>
                            {edit_recruiter.Id === 0 || app_id > 0 || show_add_recruiter ? null : edit_recruiter_form}
                            {!show_add_recruiter || edit_recruiter.Id > 0 || app_id > 0 ? null : add_recruiter_form}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={3} xl={3}>
                            {loading ? loading_card : in_progress_list}
                            {loading ? loading_card : applicants_list}
                            {loading ? loading_card : closed_applicants_list}
                        </Col>
                        <Col xs={12} lg={9} xl={9}>
                            {app_id === 0 || edit_recruiter.Id > 0 || show_add_recruiter ? null : view_application}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
