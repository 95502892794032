import React, { Component } from 'react';
import { Button, Card } from 'react-bootstrap';
import CustomButton from './buttons/CustomButton';
import { initial_mailgun_user } from './dtos/InitialValues';
import { ReportApplicantIssue } from './modals/ReportApplicantIssue';
import { sendPutFetch } from './utilities/FetchFunctions';
import hiring_banner from '../media/hiring.png'
export class Home extends Component {
    static displayName = Home.name;
    constructor(props) {
        super(props);
        this.state = {
            show_modal: false,
            send_error_clicked: false,
            mailgun_user: initial_mailgun_user,
            apply_now_clicked: false,
        };

    }
    componentDidMount() {
        localStorage.clear();
        this.setState({ apply_now_clicked: false });
    }



    render() {
        const { show_modal, send_error_clicked, mailgun_user } = this.state;
        return (
            <div className="d-flex flex-column p-4">
                <Card className="text-center m-auto" style={{ alignSelf: 'center' }}>
                    <img src={hiring_banner} alt="hiring_banner" style={{ maxWidth: '75vw' }} className="align-self-center rounded mt-4" />
                    <Card.Body className="d-flex flex-column my-3">
                       
                        <Card.Title>Interested in applying?</Card.Title>
                        <Card.Text className="mb-4 w-75" style={{ alignSelf: 'center' }}>
                            Click "Apply Now!" to start the application process. You will be able to save your progress
                            and return to the application if you do not have all the required information readily
                            available.
                        </Card.Text>
                        <CustomButton
                            variant="success"
                            alignment="center"
                            button_title="Apply Now!"
                            button_action={() => window.location.pathname='/apply'}
                            text_color="white"
                        />
                    </Card.Body>
                    <Card.Footer className="text-muted">
                        <Button onClick={() => this.setState({ show_modal: true })} variant="link" className="btn-op70">
                            Report Issue
                        </Button>
                    </Card.Footer>
                </Card>

                <ReportApplicantIssue
                    show={show_modal}
                    mailgun_user={mailgun_user}
                    updateUser={(prop) => this.setState({ mailgun_user: JSON.parse(prop) })}
                    ignore={() =>
                        this.setState({
                            send_error_clicked: false,
                            show_modal: false,
                            mailgun_user: initial_mailgun_user,
                        })
                    }
                    sendEmail={async () =>
                        await sendPutFetch(JSON.stringify(mailgun_user), '/mailgun').then((res) => {
                            if (res.StatusCode === 200) {
                                this.setState({
                                    send_error_clicked: false,
                                    show_modal: false,
                                    mailgun_user: initial_mailgun_user,
                                });
                            } else {
                                this.setState({
                                    send_error_clicked: false,
                                    show_modal: false,
                                    mailgun_user: initial_mailgun_user,
                                });
                            }
                        })
                    }
                    errorClicked={send_error_clicked}
                    click_error={() => this.setState({ send_error_clicked: !send_error_clicked })}
                />
            </div>
        );
    }
}
